<div class="content" *ngIf="enterprise">
  <div class="card-title-trader">
    <h1>Rastreabilidade</h1>
    <div class="tool-tip">
      <img src="../../../assets/images/tool-tip.svg" alt="Informação adicional">
      <div class="tool-tip-text">
        <h5> Por que a rastreabilidade é importante?</h5>
        <p>
          A rastreabilidade ajuda a ter mais segurança e acompanhamento da produção do grão e suas informações, da
          produção até a comercialização, dando visão das informações da sua soja com a Pegada Mensurada.
        </p>
      </div>
    </div>
  </div>

  <p class="sentence" id="sentence-1">
    Saiba de onde veio seu produto
  </p>

  <p class="sentence" id="sentence-2">
    Use os filtros abaixo para obter mais informações sobre a soja adquirida.
  </p>

  <form [formGroup]="formEnterprise">
    <div>
      <select formControlName="group" class="input-search form-input" (change)="onGroupChange()">
        <option checked></option>
        <option *ngFor="let g of groups"> {{g}}</option>
      </select>
      <label style="color:#585858" class="form-label">
        Grupo Econômico
      </label>
    </div>
    <div *ngIf="farmList.length > 0">
      <select formControlName="farm" (change)="getHarvest()" class="input-search form-input"
        [style]="formEnterprise.get('farm')?.touched && formEnterprise.get('farm')?.invalid ? 'borderColor:red' : ''">
        <option checked></option>
        <option *ngFor="let farm of farmList" [value]="farm.code"> {{ farm.nickname }}</option>
      </select>
      <label style="color:#585858" class="form-label">
        Fazenda
      </label>
    </div>
    <div *ngIf="culturas.length > 0">
      <select formControlName="culture" (change)="getTokens()" class="input-search form-input"
        [style]="formEnterprise.get('culture')?.touched && formEnterprise.get('culture')?.invalid ? 'borderColor:red' : ''">
        <option checked></option>
        <option *ngFor="let cultura of culturas" [value]="cultura"> {{ cultura }}</option>
      </select>
      <label style="color:#585858;" class="form-label">
        Cultura
      </label>
    </div>
    <div *ngIf="harvest.length > 0">
      <select formControlName="harvest" (change)="getTokens()" class="input-search form-input"
        [style]="formEnterprise.get('harvest')?.touched && formEnterprise.get('harvest')?.invalid ? 'borderColor:red' : ''">
        <option checked></option>
        <option *ngFor="let h of harvest"> {{h}}</option>
      </select>
      <label style="color:#585858;" class="form-label">
        Safra
      </label>
    </div>
    <div *ngIf="tokens.length > 0">
      <select formControlName="tokens" (change)="getTokenSelected()" style="width: 250px;"
        class="input-search form-input"
        [style]="formEnterprise.get('tokens')?.touched && formEnterprise.get('tokens')?.invalid ? 'borderColor:red' : ''">
        <option checked></option>
        <option *ngFor="let tk of tokens"> {{tk}}</option>
      </select>
      <label style="color:#585858" class="form-label">
        Identificador/Token
      </label>
    </div>
    <div style="display:flex;align-items: center;" *ngIf="tokens.length > 0">
      <button class="btn-consult" (click)="showInfos()">
        CONSULTAR
      </button>

      <button class="btn-consult" style="margin-left: 5px;" (click)="cleanFilter()">
        LIMPAR FILTROS
      </button>
    </div>

  </form>

  <div *ngIf="showedInfos">
    <p class="sentence" id="sentence-3">Soja: {{ trackableItemSelected.item.product.code }}</p>
    <p class="sentence" id="sentence-4">Data do registro: {{ dateRegister | date:'dd/MM/YYYY' }}</p>
    <p class="sentence" id="sentence-5"> Volume comprado: {{ getValue(trackableItemSelected.item.amount) }} Toneladas
    </p>
    <div class="titles-section">
      <p class="title-1 title">Dados do agricultor</p>
      <p class="title-2 title">Informações de emissão de carbono</p>
    </div>
    <div class="sections-1">
      <div class="section-1">
        <div class="topic">
          <p class="title-topic">GRUPO ECONÔMICO</p>
          <p>{{ groupShowed }}</p>
        </div>
        <div class="topic">
          <p class="title-topic">PRODUTOR</p>
          <p>{{ selectedProvider.provider.corporateName || '-' }}</p>
        </div>
        <div class="topic">
          <p class="title-topic">FAZENDA</p>
          <p> {{ selectedProvider.provider?.places[0]?.nickname || '-' }} </p>
        </div>
        <div class="topic">
          <p class="title-topic">TALHÃO</p>
          <p> {{ selectedProvider.destiny.item | feature: 'NOME_TALHAO' }}</p>
        </div>
        <div class="topic">
          <p class="title-topic">CPF/CNPJ</p>
          <p>{{ cpf }}</p>
        </div>
        <div class="topic">
          <p class="title-topic">REGIÃO</p>
          <p>
            {{ regionOrigin ? regionOrigin?.nome : farmAddres?.city }}, {{ regionOrigin ?
            regionOrigin?.microrregiao?.mesorregiao?.UF?.nome : farmAddres?.stateName}}
          </p>
        </div>
        <div class="topic">
          <p class="title-topic">BIOMA</p>
          <p>{{ biome }}</p>
        </div>
        <div class="topic">
          <p class="title-topic">Ano Safra</p>
          <p>{{ harvestShowed }} </p>
        </div>
        <div class="topic">
          <p class="title-topic">CAR'S</p>
          <div class="text-topic">
            <p>(1) {{ getFarmCode(trackableItemSelected.hash) }}</p>
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="buttons-select">
          <div class="button">
            <p>SAFRA {{ harvestShowed }}</p>
          </div>
        </div>
        <div class="sentences-section">
          <p class="sentence" id="sentence-6">
            Cálculo feito utilizando calculadora Pro Carbono Footprint
          </p>
          <div>
            <p class="sentence" id="sentence-7" *ngIf="registerDateGraph"><img style="margin-right:2px"
                src="../../../assets/images/update.png">
              <b>Última Atualização: {{ registerDateGraph }}</b>
            </p>
          </div>
        </div>
        <div class="graph-area">
          <app-graph *ngIf="flagGraph" [carbono]="carbono" [transporte]="transporte" [insumo]="insumo"
            [operacao]="operacao" [mut]="mut" [talhaoQtdMedia]="talhaoQtdMedia"
            [talhaoQtdTotal]="talhaoQtdTotal"></app-graph>
          <app-graph-escopo
            *ngIf="flagGraph && (escopoGraph.escopo1 > 0 || escopoGraph.escopo2 > 0 ||  escopoGraph.escopo3 )"
            [carbono]="carbono" [escopo1]="escopoGraph.escopo1" [escopo2]="escopoGraph.escopo2"
            [escopo3]="escopoGraph.escopo3" [talhaoQtdMedia]="talhaoQtdMedia"
            [talhaoQtdTotal]="talhaoQtdTotal"></app-graph-escopo>
        </div>
      </div>
    </div>
    <div class="titles-section">
      <p class="title-3 title">Mais informações</p>
      <p class="title-4 title">Selos</p>
    </div>
    <div class="sections-2">
      <div class="section-3">
        <p>
          Para mais informações sobre o produto e o programa escaneie o QR CODE ou <a [href]="urlLandingPage">clique
            aqui.</a>
        </p>
        <img [src]="urlQRCODE" alt="QR Code">
      </div>
      <div class="section-4">
        <div class="section-item" id="section-item-1">
          <p style="margin-bottom: 35px;">Auditado por:</p>
          <img style="height:80px" src="../../../assets/images/bureau-veritas.svg">
        </div>

        <div class="section-item" id="section-item-2" style="overflow-y:hidden;">
          <p>Método em co-desenvolvimento com EMBRAPA através de parceria técnico-científca</p>
          <div style="overflow-y:hidden;margin: auto;display: block;height:100%">
            <img src="../../../assets/images/Embrapa.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!enterprise && !isLoading">
  <app-user-not-found></app-user-not-found>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>