import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { DataMovement } from 'src/app/interfaces/datamovement';
import { Place } from 'src/app/interfaces/place';
import { MovementService } from 'src/app/services/movement.service';

@Component({
  selector: 'app-modal-enviar-recebimento',
  templateUrl: './modal-enviar-recebimento.component.html',
  styleUrls: ['./modal-enviar-recebimento.component.scss'],
})
export class ModalEnviarRecebimentoComponent implements OnInit {

  @Input() trackableList: any = [];
  @Input() producers: any;
  @Input() carSelected: any;
  @Input() cultureSelected: any;
  @Input() trader: any;

  @Output() close = new EventEmitter();
  page: number = 1;
  form!: FormGroup;
  hashOrigins: any[] = [];

  dateNow: Date = new Date();
  isLoading: boolean = false;

  producer: any;

  constructor(
    private fb: FormBuilder,
    private movementeService: MovementService,
  ) {
    this.form = this.fb.group({
      date: ['', Validators.required],
      contractNumber: ['', Validators.required],
      document: ['', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]],
      invoiceNumber: [''],

      trackables: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    const trackableItens = this.form.get('trackables') as UntypedFormArray;
    this.trackableList.map((trackable: any) => {
      const item = {
        baixa: 0,
        pegada: this.getFeatureByName(trackable.item, 'QTD_TOTAL_EMISSÃO_CARBONO'),
        saldo: trackable.item.amount,
        fazenda: trackable.item.place,
        talhao: this.getFeatureByName(trackable.item, 'NOME_TALHAO'),
        hash: trackable.hash,
        enterprise: trackable.item.enterprise,
      }
      trackableItens.push(this.createTrackableItem(item));
    });
    this.producer = this.producers.find((producer: any) => producer.places[0].code === this.carSelected);
  }

  getFormArray(type: string) {
    return (<UntypedFormArray>this.form.get(type)).controls;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  private createTrackableItem(trackable: any = {
    baixa: 0,
    pegada: '',
    saldo: '',
    fazenda: '',
    talhao: '',
    hash: '',
    enterprise: '',
  }) {
    return this.fb.group({
      baixa: [trackable.baixa, Validators.required],
      pegada: [trackable.pegada],
      saldo: [trackable.saldo],
      fazenda: [trackable.fazenda],
      talhao: [trackable.talhao],
      hash: [trackable.hash ? trackable.hash : null ],
      enterprise: [trackable.enterprise],
    });
  }

  getFarmName(): string {
    const farm = this.producer.places.find((item: Place) => item.type === 'FARM' && item.code === this.carSelected );
    return farm ? farm.nickname : '';
  }

  getEnterpriseName(enterpriseCode: string): string {
    const enterprise = this.producers.find((enterprise: any) => enterprise.enterpriseCode === enterpriseCode);
    return enterprise?.corporateName ?? '-';
  }

  getEnterpriseDocument(enterpriseCode: string): string {
    const enterprise = this.producers.find((enterprise: any) => enterprise.enterpriseCode === enterpriseCode);
    return enterprise?.document ?? '-';
  }

  getTotalBaixa() {
    const trackableItens = this.form.get('trackables') as UntypedFormArray;
    let total = 0;
    const trackableItensArray = trackableItens.value;
    trackableItensArray.map((item: any) => total += item.baixa);
    return total;
  }

  closeModal() {
    if (this.hashOrigins.length > 0) {
      this.close.emit(this.hashOrigins);
    } else {
      this.close.emit('close');
    }
  }

  valitadeValue(index: number) {
    const trackableItens = this.form.get('trackables') as UntypedFormArray;
    if (trackableItens.at(index).get('baixa')?.value > trackableItens.at(index).get('saldo')?.value) {
      trackableItens.at(index).get('baixa')?.setValue(trackableItens.at(index).get('saldo')?.value);
    }
    if (trackableItens.at(index).get('baixa')?.value < 0) {
      trackableItens.at(index).get('baixa')?.setValue(0);
    }
    if (trackableItens.at(index).get('baixa')?.value === 0) {
      trackableItens.at(index).get('baixa')?.setErrors({ 'zero': true });
    }
  }

  changePage(pageNumber: number) {
    this.form.get('trackables')?.markAllAsTouched();
    if (this.form.get('trackables')?.valid) {
      this.page = pageNumber;
    }
  }

  submitForm() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const trackableItens = this.form.get('trackables') as UntypedFormArray;

      const documentProducer = this.form.get('document')?.value;
      const contractNumber = this.form.get('contractNumber')?.value;
      const invoiceNumber = this.form.get('invoiceNumber')?.value;
      const trackables = trackableItens.value;
      trackables.map((item: any) => {
        let features = [];
        if (documentProducer) {
          features.push({
            name: "PRODUCER_DOCUMENT",
            value: documentProducer,
            type: "String",
            usage: "documento do produto"
          });
        }
        if (contractNumber) {
          features.push({
            name: "CONTRACT_NUMBER",
            value: contractNumber,
            type: "String",
            usage: "Número do contrato"
          });
        }
        if (invoiceNumber) {
          features.push({
            name: "INVOICE_NUMBER",
            value: invoiceNumber,
            type: "String",
            usage: "Número da nota fiscal"
          });
        }

        const movement: DataMovement = {
          movementDate: this.form.get('date')?.value,
          origin: {
            hash: item.hash
          },
          amount: item.baixa,
          meansurementType: 'ton',
          provider: this.producer,
          customer: {
            document: this.trader.document,
            places: this.trader.places,
          },
          type: 'RECEIVEMENT',
          features: features
        };
        this.hashOrigins.push(movement.origin.hash);
        if (item.baixa > 0) {
          this.isLoading = true;
          this.movementeService.postMovement(movement).subscribe(res => {
            this.isLoading = false;
            this.closeModal();
          }, error => {
            this.isLoading = false;
            this.closeModal();
          });
        }
      });
    }
  }
}
