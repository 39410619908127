<ul class="menu">
  <li class="item-talhao">
    <div class="title-accordion" (click)="toggleOpenMovements()">
      <button type="button" [ngClass]="openMovements ? 'button-arrow open' : 'button-arrow'">
        <svg width="18" height="18" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2287 0.589844L6.63867 5.16984L2.04867 0.589844L0.638672 1.99984L6.63867 7.99984L12.6387 1.99984L11.2287 0.589844Z" fill="black" fill-opacity="0.38"/>
        </svg>
      </button>
      <div class="text">
        <h3 class="text-1">Identificador/Token Talhão: {{ trackable.itemBlockId || '-'}}</h3>
        <h3 class="infos-accordion">
          Volume total colhido do talhão: {{ trackable.amount }} ton | Talhão: {{ trackable.talhao }}
        </h3>
      </div>
    </div>
    <div class="text-open" *ngIf="openMovements">
      <app-accordion-baixa
        *ngFor="let baixa of baixas"
        [baixa]="baixa"
      ></app-accordion-baixa>
    </div>
  </li>
</ul>
