import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

  trader: boolean = false;
  productor: boolean = false;
  userId: string | null = "";
  registerPage: boolean = false;
  traderPage: boolean = false;
  productorPage: boolean = false;
  productorInitialPage: boolean = false;
  adm: boolean = false;
  rastreabilityPage: boolean  = false;
  currentUrl: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.userId = localStorage.getItem("userId");

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
      }

      if (this.currentUrl.includes("trader") || this.currentUrl.includes("registro-compras") || this.currentUrl.includes("lancar-recebimento")) {
        // sessionStorage.setItem("typeUser", "TRADER");
        this.trader = true;
        this.productor = false;
      }

      else if (this.currentUrl.includes("produtor-inicio") || this.currentUrl.includes("produtor")) {
        // sessionStorage.setItem("typeUser", "PRODUTOR");
        this.trader = false;
        this.productor = true;
      }

      if (this.currentUrl.includes('/trader')) {
        this.traderPage = true;
        this.productorInitialPage = false;
        this.rastreabilityPage = false;
        this.productorPage = false;
        this.registerPage = false;
        //const accordionItem = document.getElementsByClassName("trader");
        //accordionItem.item(0)?.classList.add("active");
      }


      if (this.currentUrl.includes('/laudo-rastreabilidade')) {
        this.traderPage = false;
        this.productorInitialPage = false;
        this.rastreabilityPage = true;
        this.productorPage = false;
        this.registerPage = false;
      }
      else if (this.currentUrl.includes('/produtor-inicio')) {
        this.productorInitialPage = true;
        this.traderPage = false;
        this.rastreabilityPage = false;
        this.productorPage = false;
        this.registerPage = false;
      }
      else if (this.currentUrl.includes('/produtor')) {
        this.productorPage = true;
        this.traderPage = false;
        this.rastreabilityPage = false;
        this.productorInitialPage = false;
        this.registerPage = false;
      }
      else if (this.currentUrl.includes('/registro-compras')) {
        this.registerPage = true;
        this.rastreabilityPage = false;
        this.traderPage = false;
        this.productorInitialPage = false;
        this.productorPage = false;
      }
    });
  }

  ngOnInit(): void {
    const hamburger = document.querySelector('.hamburger');
    const siteNav = document.querySelector('.site-navigation');

    hamburger?.addEventListener('click', event => {
      event.preventDefault();
      hamburger.classList.toggle('is-active');
      siteNav?.classList.toggle('is-open');
    });

    let user = sessionStorage.getItem("user");
    if (user == "userRoot") {
      this.adm = true;
    }
    else {
      this.adm = false;
    }
  }

  redirectRegister() {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
    })
    if (!this.userId) {
      this.userId = localStorage.getItem('traceId');
    }
    this.router.navigate(['/registro-compras'],{ queryParams: { customerId: this.userId } });
  }

  redirectTrader() {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
    });

    if (!this.userId) {
      this.userId = localStorage.getItem('traceId');
    }

    this.router.navigate(
      ['/trader'],
      { queryParams: { customerId: this.userId } }
    );
  }

  redirectProductorInitial() {
    this.userId = localStorage.getItem('userId');

    this.router.navigate(
      ['/produtor-inicio'],
      { queryParams: { customerId: this.userId } }
    );
  }

}
