<div class="modal-user modal-open">
    <div class="modal-user-header">
      <button type="button" (click)="enviarDadosCancel()" >
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 2.01547L12.59 0.605469L7 6.19547L1.41 0.605469L0 2.01547L5.59 7.60547L0 13.1955L1.41 14.6055L7 9.01547L12.59 14.6055L14 13.1955L8.41 7.60547L14 2.01547Z" fill="black" fill-opacity="0.38"/>
        </svg>
      </button>
      <span>Escolher usuário tela admin</span>
    </div>
    <div class="modal-user-content">
      <div class="stepper">
        <div class="step-one">
          <div class="step-circle selected">
            {{ 1 }}
          </div>
          <span class="span-selected">Selecionar o tipo de visualização</span>
        </div>
        <div>
          <svg width="140" height="4" viewBox="0 0 140 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="2.10547" x2="139.5" y2="2.10546" stroke="black" stroke-opacity="0.12" stroke-width="3"/>
          </svg>
        </div>
        <div class="step-two">
          <div class="step-circle">
            {{ 2 }}
          </div>
          <span class="span-unselected">Selecionar produtor/trader</span>
        </div>
      </div>
      <p>Deseja entrar como qual usuário? </p>
      <form [formGroup]="formUser">
          <div class="input-container">
            <select class="input-search form-input" formControlName="userType">
              <option value="" [selected]="true" disabled></option>
              <option *ngFor="let opcoes of arrayOptions" [ngValue]="opcoes.op">{{opcoes.op}}</option>
            </select>

            <label style="color:#585858" class="form-label">
              Tipo de usuário:
            </label>
          </div>
      </form>
    </div>


    <div class="buttons">
        <button type="button" (click)="enviarDadosCancel()">Cancelar</button>
        <button type="button" class="submit-button" (click)="enviarDados()">Continuar</button>
    </div>
</div>
