import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MovementService } from 'src/app/services/movement.service';
import { ActivatedRoute } from '@angular/router';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { Movement } from 'src/app/interfaces/movement';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccordionsRegistroVolumeComponent } from 'src/app/components/accordions-registro-volume/accordions-registro-volume.component';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { Place } from 'src/app/interfaces/place';

interface PurchaseRecordData {
  'Grupo produtor': string;
  'Nome da Fazenda': string;
  'CAR': string;
  'Produtor': string;
  'CPF/CNPJ': string;
  'Data recebimento': string;
  'CNPJ da venda do produtor': string;
  'Número do contrato de venda do produto': string;
  'Nota Fiscal': string;
  'Token origem': string;
  'Token baixa': string;
  'Pegada': string;
  'Volume recebido': string;
  'Unidade de medida': string;
}

@Component({
  selector: 'app-registro-compras',
  templateUrl: './registro-compras.component.html',
  styleUrls: ['./registro-compras.component.scss']
})

export class RegistroComprasComponent implements OnInit {

  @ViewChild(AccordionsRegistroVolumeComponent) accordionsRegistroVolumeComponent!: AccordionsRegistroVolumeComponent;

  formFilter: FormGroup;

  userId?: string | null;
  trace: any;
  totalAmount: number = 0;
  movements: Movement[] = [];
  enterprise: Enterprise | undefined;

  arrayPurchaseRecordData: PurchaseRecordData[] = [];
  copyArray: PurchaseRecordData[] = [];

  groupsFilterList: string[] = [];
  filteredMovements: TrackableItem[] = [];
  filteredTrackables: TrackableItem[] | undefined;
  providerFilterList: Array<{ name: string; value: string | number; }> = [];
  movementsList: TrackableItem[] = [];
  trackebleItemList: TrackableItem[] | undefined;
  cultureList: string[] = [];
  harvestList: string[] = [];
  isLoading: boolean = false;
  search: boolean = false;
  adm: boolean = false;
  receivedAmount: number = 0;
  farmList: Place[] = [];

  culture: string = '';

  constructor(
    private router: Router,
    private movementService: MovementService,
    private route: ActivatedRoute,
    private enterpriseService: EnterpriseService,
    private tracebilityService: TraceabilityService,
    private http: HttpClient,

  ) {
    this.formFilter = new FormGroup({
      group: new FormControl('', Validators.required),
      farm: new FormControl('', Validators.required),
      harvest: new FormControl('', Validators.required),
      culture: new FormControl('', Validators.required),
    });
  }


  async ngOnInit(): Promise<void> {
    const user = sessionStorage.getItem("user");
    if (user == "userRoot") {
      this.adm = true;
    }
    this.getParams();
  }

  cleanFilter() {
    this.formFilter.get('group')?.setValue('');
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.setSearchFalse();
  }

  getParams() {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
      if (this.userId) {
        sessionStorage.setItem('customerId', this.userId);
      }
    });
    if (!this.userId) {
      this.userId = sessionStorage.getItem("customerId") as string;
    }
    localStorage.setItem('traceId', this.userId);
    this.getEnterprise();
  }

  submitForm() {
    this.filteredTrackables = [];
    this.filteredMovements = [];
    this.formFilter.markAllAsTouched();
    this.filteredTrackables = [];
    this.search = false;
    if (this.formFilter.valid) {
      this.culture = this.formFilter.get('culture')?.value;
      if (this.trackebleItemList && this.trackebleItemList?.length > 0) {
        this.receivedAmount = 0;
        const culture = this.formFilter.get('culture')?.value;
        const harvest = this.formFilter.get('harvest')?.value;
        const group = this.formFilter.get('group')?.value;
        const farm = this.formFilter.get('farm')?.value;

        this.filteredTrackables = this.trackebleItemList?.filter(movement => {
          if ((this.getFeatureByName(movement.item, 'CULTURA') === culture) && (this.getFeatureByName(movement.item, 'ANO_SAFRA')) === harvest) {
            return true;
          }
          return false;
        });

        this.filteredMovements = this.movementsList.filter(item => this.getFeatureByName(item.item, 'CULTURA') === culture && this.getFeatureByName(item.item, 'ANO_SAFRA') == harvest && this.checkProvider(item));
        this.filteredMovements?.forEach(item => this.receivedAmount += item.item.amount);
        this.totalAmount = 0;
        let movementsArray: any[] = [];
        this.filteredMovements.map(item => {
          item.movements.forEach((movement: any) => {
            if (!movementsArray.some(movementArrayItem => movementArrayItem.hash === movement.origin.hash)) {
              movementsArray.push({ hash: movement.origin.hash, amount: movement.origin.item.invoicedQuantity });
            }
          });
        });
        this.copyArray = [];
        this.filteredMovements.map(trackable => {
          trackable.movements.map(movement => {
            if (movement != undefined && movement.provider.corporateName != undefined) {
              const myObject: PurchaseRecordData = {
                'Grupo produtor': movement.provider.economicGroup as string,
                'Nome da Fazenda': "",
                'CAR': movement.provider.places[0].code,
                'Produtor': movement.provider.corporateName,
                'CPF/CNPJ': movement.provider.document,
                'Data recebimento': movement.movementDate,
                'CNPJ da venda do produtor': this.getFeatureByName(movement, 'PRODUCER_DOCUMENT') || '-',
                'Número do contrato de venda do produto': this.getFeatureByName(movement, 'CONTRACT_NUMBER') || '-',
                'Nota Fiscal': this.getFeatureByName(movement, 'INVOICE_NUMBER') || '-',
                'Token origem': trackable.itemBlockId,
                'Token baixa': trackable.hash,
                'Pegada': parseFloat(this.getFeatureByName(movement, 'QTD_TOTAL_EMISSÃO_CARBONO') || '0').toFixed(2).toString(),
                'Volume recebido': movement.amount.toString(),
                'Unidade de medida': 'TON'
              };

              movement.provider.places.forEach(place => {
                if (place.ruralEnvironmentalRegistry == myObject.CAR && place.type == "FARM") {
                  myObject['Nome da Fazenda'] = place.code;
                }
              })

              this.copyArray.push(myObject);
            }
          });
        })
        movementsArray.map(item => this.totalAmount += item.amount);
        this.search = true;
      }
    }
  }

  setSearchFalse() {
    this.search = false;
  }

  changeHarvest() {
    this.setSearchFalse();
    this.formFilter.get('culture')?.setValue('');
    const farm = this.formFilter.get('farm')?.value;
    const group = this.formFilter.get('group')?.value;
    const harvest = this.formFilter.get('harvest')?.value;
    const producerMovements = this.movementsList.filter(item => this.checkProvider(item));
    this.cultureList = [];
    producerMovements.map(item => {
      if (this.getFeatureByName(item.item, 'ANO_SAFRA') === harvest) {
        const culture = item.item ? this.getFeatureByName(item.item, 'CULTURA') || this.getCultureName(item.item.product.code) : '';
        if (culture && !this.cultureList.includes(culture)) {
          this.cultureList.push(culture);
        }
      }
    });
  }

  checkProvider(movement: any): boolean {
    const group = this.formFilter.get('group')?.value;
    return movement.movements.some((item: any) => this.checkPlace(item.provider) && item.provider.economicGroup === group)
  }

  checkPlace(provider: any): boolean {
    const farm = this.formFilter.get('farm')?.value;
    return provider.places.some((place: any) => place.code === farm);
  }

  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }
  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  getEnterprise() {
    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.userId).subscribe(res => {
      if (res != undefined) {
        this.enterprise = res[0];
        this.getTrackableItemList();
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  getTrackableItemList() {
    if (this.enterprise != undefined) {
      this.isLoading = true;
      this.tracebilityService.getTraceByDocument(this.enterprise.document).subscribe(res => {
        this.trackebleItemList = res;
        this.getMovements();
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      });
    }
  }

  getMovements() {
    if (this.trackebleItemList && this.trackebleItemList?.length > 0) {
      this.trackebleItemList.forEach(trackable => {
        this.isLoading = true;
        this.movementService.getTraceByHash(trackable.hash).subscribe(res => {
          if (res) {
            if (!this.movementsList.some(item => item.hash === res.hash)) {
              this.movementsList.push(res);
              res.movements.forEach(movement => {
                this.movements.push(movement);
                if (res != undefined && movement.provider.corporateName != undefined) {
                  if (movement.provider.economicGroup && !this.groupsFilterList.includes(movement.provider.economicGroup)) {
                    this.groupsFilterList.push(movement.provider.economicGroup);
                  }
                  if (movement.provider.corporateName && !this.providerFilterList.some(item => item.value === movement.provider.document)) {
                    this.providerFilterList.push({ name: movement.provider.corporateName, value: movement.provider.document });
                  }

                  const myObject: PurchaseRecordData = {
                    'Grupo produtor': movement.provider.economicGroup as string,
                    'Nome da Fazenda': "",
                    'CAR': movement.provider.places[0].code,
                    'Produtor': movement.provider.corporateName,
                    'CPF/CNPJ': movement.provider.document,
                    'Data recebimento': movement.movementDate,
                    'CNPJ da venda do produtor': this.getFeatureByName(movement, 'PRODUCER_DOCUMENT') || '-',
                    'Número do contrato de venda do produto': this.getFeatureByName(movement, 'CONTRACT_NUMBER') || '-',
                    'Nota Fiscal': this.getFeatureByName(movement, 'INVOICE_NUMBER') || '-',
                    'Token origem': trackable.itemBlockId,
                    'Token baixa': trackable.hash,
                    'Pegada': parseFloat(this.getFeatureByName(movement, 'QTD_TOTAL_EMISSÃO_CARBONO') || '0').toFixed(2).toString(),
                    'Volume recebido': movement.amount.toString(),
                    'Unidade de medida': 'TON'
                  };

                  movement.provider.places.forEach(place => {
                    if (place.ruralEnvironmentalRegistry == myObject.CAR && place.type == "FARM") {
                      myObject['Nome da Fazenda'] = place.code;
                    }
                  })

                  this.arrayPurchaseRecordData.push(myObject);
                }
              });
            }
          }
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
        });
      });
    }
    this.copyArray = this.arrayPurchaseRecordData;
  }

  changePage() {
    this.router.navigate(['/lancar-recebimento']);
  }

  convertToCSV(): void {
    const formatedArray = this.copyArray.map(item => {
      const newElement = {
        'Grupo produtor': item['Grupo produtor'],
        'Nome da Fazenda': item['Nome da Fazenda'],
        'CAR': item['CAR'],
        'Produtor': item['Produtor'],
        'CPF/CNPJ': item['CPF/CNPJ'],
        'Data recebimento': item['Data recebimento'],
        'CNPJ da venda do produtor': item['CNPJ da venda do produtor'],
        'Número do contrato de venda da soja': item['Número do contrato de venda do produto'],
        'Nota Fiscal': item['Nota Fiscal'],
        'Token origem': item['Token origem'],
        'Token baixa': item['Token baixa'],
        'Pegada': item['Pegada'].replace('.', ','),
        'Volume recebido': item['Volume recebido'].replace('.', ','),
        'Unidade de medida': item['Unidade de medida'],
      }
      return newElement;
    });

    this.http.post(`${environment.apiURL}/convert`, formatedArray, { responseType: 'text' })
      .subscribe(
        (response: string) => {
          const worksheet: XLSX.WorkSheet = this.convertCSVToSheet(response, ';');
          const workbook: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de volumes baixados');
          const xlsxFile: Uint8Array = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveFile(xlsxFile, 'Relatório de Volumes Baixados.xlsx');
        },
        (error: any) => {
          console.error('Ocorreu um erro ao converter o JSON para CSV.', error);
        }
      );
  }

  changeFarm() {
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    if (this.trackebleItemList) {
      const farm = this.formFilter.get('farm')?.value;
      const group = this.formFilter.get('group')?.value;
      const producerMovements = this.movementsList.filter(item => this.checkProvider(item));
      this.harvestList = [];
      this.cultureList = [];
      producerMovements.map(item => {
        const harvest = item.item ? this.getFeatureByName(item.item, 'ANO_SAFRA') || this.getFeatureByName(item.item.product, 'Ano Safra') : '';
        if (harvest && !this.harvestList.includes(harvest)) {
          this.harvestList.push(harvest);
        }
        const culture = item.item ? this.getFeatureByName(item.item, 'CULTURA') || this.getCultureName(item.item.product.code) : '';
        if (culture && !this.cultureList.includes(culture)) {
          this.cultureList.push(culture);
        }
      });
    }

    this.search = false;
  }

  filterProducers() {
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.search = false;
    this.farmList = [];
    this.providerFilterList = [];
    const group = this.formFilter.get('group')?.value;
    this.movementsList.forEach(item => {
      item.movements.forEach(movement => {
        if (movement.provider.corporateName && movement.provider.economicGroup === group && !this.providerFilterList.some(item => item.value === movement.provider.document)) {
          this.providerFilterList.push({ name: movement.provider.corporateName, value: movement.provider.document });
          movement.provider.places.map(place => {
            if (!this.farmList.some(farm => farm.code === place.code)) {
              this.farmList.push(place);
            }
          })
        }
      })
    });
  }

  convertCSVToSheet(csv: string, delimiter: string): XLSX.WorkSheet {
    const lines: string[] = csv.split('\n');
    const data: any[][] = [];

    for (const line of lines) {
      const row: string[] = line.split(delimiter).map(cell => cell.replace(/"/g, ''));
      data.push(row);
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    return worksheet;
  }

  saveFile(data: Uint8Array, filename: string): void {
    const blob: Blob = new Blob([data], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, filename);
  }

}
