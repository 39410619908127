<div class="content-graph">
  <div class="card-title-graph">
      <p>Total de Emissão de carbono</p>
      <div class="tool-tip" *ngIf="tooltip">
          <img src="../../../assets/images/tool-tip.svg" alt="Informação adicional">
          <span class="tool-tip-text">Valores referentes a média da pegada de <u> {{ talhaoQtdMedia }} talhões de um total de {{ talhaoQtdTotal }} talhões</u>.</span>
      </div>
  </div>

  <div style="display:flex;justify-content: center; overflow-y: hidden;">
      <div id="chart-escopo">
      </div>
  </div>

  <div id="label-overlay" style="position: relative;">
      <div style="position: absolute;">
          <p class="text-1">Total de Emissão<br>(kg CO2 eq/t soja)</p>
          <p class="text-2" style="text-align:center">{{ getValue(carbono) }}</p>
      </div>
  </div>

</div>
