import { Component, OnInit, Input, ElementRef, AfterViewChecked } from '@angular/core';
import { urlSite } from 'src/environments/environment';
import { environment } from 'src/environments/environment';
import { Place } from 'src/app/interfaces/place';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { MovementService } from 'src/app/services/movement.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { QrCodeService } from 'src/app/services/qr-code.service';

@Component({
  selector: 'app-accordion-baixa',
  templateUrl: './accordion-baixa.component.html',
  styleUrls: ['./accordion-baixa.component.scss']
})
export class AccordionBaixaComponent implements OnInit {
  @Input() baixa: any;
  @Input() blockchainToken: string = '';

  openInfo: boolean = false;
  enterprise: Enterprise | undefined = undefined;

  constructor(
    private enterpriseService: EnterpriseService,
    private movementService: MovementService,
    private traceabilityService: TraceabilityService,
    private qrCodeService: QrCodeService,
  ) { }

  ngOnInit(): void {
    const document = this.baixa.movements[0].provider.document;
    if (document && !this.baixa.movements[0].provider.economicGroup) {
      this.enterpriseService.getEnterpriseByDocument(document).subscribe(res => {
        this.enterprise = res[0];
      });
    } else {
      this.enterprise = this.baixa.movements[0].provider;
    }

    this.getLandingPageData();
  }

  getUrlApi() {
    return environment.apiURL;
  }

  toggleOpenInfo() {
    this.openInfo = !this.openInfo;
  }

  getUrl() {
    return urlSite;
  }

  trackableByHash: any;
  verificationDate = "";
  cultura: string = '';
  nomeTalhao: string = '';
  safra: any;
  providerDocument: string = '';
  volumeTotal: any;
  pegada: any;
  totalValue: number = 0;
  address: string = "";
  corporateName: string = "";
  farmList: Array<any> = [];
  biomes: any[] = [];
  preservedArea: number = 0;
  soybeanArea: number = 0;
  nationalAverage: number = 0;
  averageEstimate = 0;
  namesBiomes: string[] = [];
  biomesDiferents: string[] = [];
  urlQrCodeTrader: string = '';
  urlLandingPage: string = "";
  urlQRCODE: string = "";

  async getLandingPageData() {

    const hash = this.baixa.hash;
    let trackableByHash;

    await this.movementService.getTraceByHash(hash).toPromise().then((trackableByHash: any) => {
      this.trackableByHash = trackableByHash;
      this.trackableByHash.item.features.map((feat: any) => {
        if (feat.name === 'DATA_REGISTRO') {
          this.verificationDate = feat.value;
        }
        if (feat.name === 'CULTURA') {
          this.cultura = feat.value;
        }
        if (feat.name === 'NOME_TALHAO') {
          this.nomeTalhao = feat.value;
        }
        if (feat.name === 'ANO_SAFRA') {
          this.safra = feat.value;
        }
      })

      this.providerDocument = this.trackableByHash.movements[0].provider.document;

      this.volumeTotal = this.trackableByHash.item.receivedQuantity;
    })

    this.trackableByHash.item.features.forEach((feature: any) => {
      if (feature.name == 'QTD_TOTAL_EMISSÃO_CARBONO') {
        this.pegada = feature.value;
      }
    })

    this.pegada = Math.round(this.pegada);

    let enterprise: any;

    this.enterpriseService.getEnterpriseByDocument(this.providerDocument).subscribe(item => {
      enterprise = item[0];
      let divider = 0;
      let dividend = 0;

      this.totalValue = 0;
      this.address = enterprise.address.city;
      this.corporateName = enterprise.corporateName;
      enterprise.places.map((place: any) => {
        if (place.type === 'FARM') {
          this.farmList.push({
            nickname: place.nickname,
            code: place.code,
            ruralEnvironmentalRegistry: place.ruralEnvironmentalRegistry,
          });
        }
      });

      this.traceabilityService.getTraceByDocument(enterprise.document).subscribe(trackeableItens => {
        trackeableItens.forEach(trackeableItem => {

          this.biomes.push(trackeableItem.item.features.filter(f => f.name === "BIOMA"));

          let featurePreservedArea = trackeableItem.item.features.filter(f => f.name === "AREA_PRESERVADA");
          this.preservedArea += parseFloat(featurePreservedArea[0].value);

          let soybean = trackeableItem.item.features.filter(f => f.name === "AREA_SOJA");
          this.soybeanArea += parseFloat(soybean[0].value);

          if (trackeableItem.item.features.some(f => f.name === "ANO_SAFRA" && f.value === this.safra)) {
            this.totalValue += trackeableItem.item.receivedQuantity;
          }

          let emissaoCarbono = 0;
          //let areaSoja = 0;
          trackeableItem.item.features.forEach(feature => {
            if (feature.name == "QTD_TOTAL_EMISSÃO_CARBONO") {
              emissaoCarbono = parseFloat(feature.value);
            }
          });
          divider += trackeableItem.item.receivedQuantity;
          dividend += (emissaoCarbono * trackeableItem.item.receivedQuantity);

        });

        this.nationalAverage = dividend / divider;

        //this.averageEstimate = this.totalEstimate / trackeableItens.length;
        let feature = trackeableItens[0].item.features.filter(f => f.name === 'PEGADA_MEDIA_SOJA_COMUM')
        this.averageEstimate = parseFloat(feature[0].value);
        //this.averageEstimate = parseFloat(trackable.values);
        this.biomes.forEach(biome => {
          this.namesBiomes.push(biome[0].value);
        });

        this.biomesDiferents = [];
        this.namesBiomes.forEach(nameBiome => {
          if (!this.biomesDiferents?.includes(nameBiome)) {
            this.biomesDiferents.push(nameBiome);
          }
        });

        const pageData = {
          customerId : enterprise.customerId,
          cultura: this.cultura,
          harvest: this.safra,
          volumeTotal: this.volumeTotal,
          pegadaMediaGrupo: this.nationalAverage,
          farmList: this.farmList,
          biomes: this.biomesDiferents,
          verificationDate: this.verificationDate,
          nomeTalhao: this.nomeTalhao,
          pegada: this.pegada,
        }

        let token = '';

        this.qrCodeService.postQRData({ data: pageData }).subscribe((res : any) => {
          token = res.id;
          this.urlLandingPage = `landing-page-baixa?data=${token}`;
          this.urlQRCODE = `${environment.apiURL}/qrcode?url=${this.urlQrCodeTrader}/landing-page-baixa?data=${token}`;
        });
      });
    });
  }

  getFarmCode(places: Place[], type: string) {
    if (type === 'code') {
      let place: any;
      places.map(item => {
        if (item.type && item.type === 'FARM') {
          place = item;
        }
      });
      if (place && place.code) {
        return place.code;
      }
    }

    if (type === 'car') {
      let place: any;
      places.map(item => {
        if (item.type && item.type === 'FARM') {
          place = item;
        }
      });
      if (place && place.ruralEnvironmentalRegistry) {
        return place.ruralEnvironmentalRegistry;
      }
    }

    if (type === 'nickname') {
      let place: any;
      places.map(item => {
        if (item.type && item.type === 'FARM') {
          place =item;
        }
      });
      if (place && place.nickname) {
        return place.nickname;
      }
    }
    return '-';
  }

  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item && item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }
}
