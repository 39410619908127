<div class="container-landing-page">
    <div class="header-desktop">
        <app-header-landing-page></app-header-landing-page>
    </div>

    <app-first-section-landing></app-first-section-landing>

    <app-second-section-landing></app-second-section-landing>

    <app-third-section-landing
      [averageEstimate]="0"
      [totalValue]="totalValue"
      [harvest]="harvest"
      [nationalAverage]="nationalAverage"
      [biomesDiferents]="biomesDiferents"
      [verificationDate]="verificationDate"
      [farmList]="farms"
      [cultura]="cultura"
    ></app-third-section-landing>

    <app-fouth-section-landing ></app-fouth-section-landing>

    <app-footer-landing-page></app-footer-landing-page>
</div>
