import { Injectable, Inject } from '@angular/core';
//import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    //@Inject(SESSION_STORAGE) private storage: StorageService,
    private http: HttpClient
  ) {

  }
  /*
  setSession(name: string, val: any) {
    this.storage.set(name, val);
  }

  getSession(name: string) {
    return this.storage.get(name);
  }

  deleteSession(name: string) {
    this.storage.remove(name);
  }
  */
}
