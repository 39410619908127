<div class="registro-compras-content" *ngIf="enterprise">
  <div class="welcome-card">
    <h2>Olá, {{ enterprise.corporateName }}!</h2>
    <p>Acompanhe as informações de Registro de Volume.</p>
    <button type="button" *ngIf="!adm" (click)="changePage()" class="button-next-page">
      LANÇAR RECEBIMENTO DO AGRICULTOR
    </button>
  </div>

  <h1>Registro de Volume</h1>
  <form [formGroup]="formFilter">
    <div class="container-search" style="z-index:1">
      <select formControlName="group" class="input-search form-input"
        [style]="formFilter.get('group')?.touched && formFilter.get('group')?.invalid ? 'borderColor:red' : ''"
        (change)="filterProducers()">
        <option checked></option>
        <option style="z-index:1" *ngFor="let g of groupsFilterList"> {{g}}</option>
      </select>
      <label style="z-index:1" style="color:#585858" class="form-label">
        Grupo
      </label>
    </div>
    <div class="container-search" style="z-index:0">
      <select formControlName="farm" class="input-search form-input"
        [style]="formFilter.get('farm')?.touched && formFilter.get('farm')?.invalid ? 'borderColor:red' : ''"
        (change)="changeFarm()">
        <option checked></option>
        <option [value]="farm.code" *ngFor="let farm of farmList"> {{ farm.nickname }}</option>
      </select>
      <label style="z-index:1" style="color:#585858" class="form-label">
        Fazenda
      </label>
    </div>
    <div class="container-search" style="z-index:0">
      <select formControlName="harvest" class="input-search form-input"
        [style]="formFilter.get('harvest')?.touched && formFilter.get('harvest')?.invalid ? 'borderColor:red' : ''"
        (change)="changeHarvest()">
        <option checked></option>
        <option [value]="harvest" *ngFor="let harvest of harvestList"> {{ harvest }} </option>
      </select>
      <label style="z-index:1" style="color:#585858" class="form-label">
        Safra
      </label>
    </div>
    <div class="container-search" style="z-index:0">
      <select style="z-index:1" formControlName="culture" class="input-search form-input"
        [style]="formFilter.get('culture')?.touched && formFilter.get('culture')?.invalid ? 'borderColor:red' : ''"
        (change)="setSearchFalse()">
        <option checked></option>
        <option [value]="culture" *ngFor="let culture of cultureList"> {{ culture }} </option>
      </select>
      <label style="z-index:1" style="color:#585858" class="form-label">
        Cultura
      </label>
    </div>
    <button (click)="submitForm()" type="button" class="btn">
      CONSULTAR
    </button>
    <button (click)="cleanFilter()" type="button" class="btn">
      LIMPAR FILTRO
    </button>
  </form>
  <div *ngIf="search">
    <p class="sentence"> Informações gerais de volume </p>
    <div class="box">
      <div class="card">
        <h5>Total de volume recebido</h5>
        <span class="card-value">{{ receivedAmount | number: '1.0-2': 'pt-BR' }}</span>
        <span class="bottom-text">Toneladas de {{ culture }}</span>
      </div>
    </div>
    <p class="sentence">Consultar volumes baixados</p>
    <div class="buttons-select">
      <!-- <div class="safra-area">
        <div class="button" *ngFor="let h of harvestList; index as i;">
          <p>SAFRA {{ h }}</p>
        </div>
      </div> -->
      <div class="button-download" (click)="convertToCSV()">
        <img src="../../../assets/images/download.png">
        <p>BAIXAR RELATÓRIO</p>
      </div>
    </div>
    <div>
      <app-accordions-registro-volume #accordionsRegistroVolumeComponent *ngIf="search"
        [movements]="filteredMovements"></app-accordions-registro-volume>
    </div>
  </div>
</div>

<div *ngIf="!enterprise && !isLoading">
  <app-user-not-found></app-user-not-found>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>