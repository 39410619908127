<div class="footer">
    <div class="row-footer">
        <div class="column-1">
            <p class="text-1">Quer saber mais informações ou reportar alguma inconsistência*?</p>
            <p class="text-2">
              Entre em contato:
            </p>
            <p class="text-2">
                <strong>Email: </strong> <a href="mailto:projetocarbono@bayer.com">projetocarbono@bayer.com</a><br>
                <strong>Telefone: </strong> <a href="tel:08007075580">0800 707 5580</a>
            </p>
        </div>
        <div class="column-2">
            <img class="img-title" src="../../../assets/images/Logo_PRO_Carbono.png">
            <p class="text-3">
              *As informações foram avaliadas considerando os critérios pré-definidos e limitados dos Programas PRO Carbono, as informações trazidas pelos Agricultores e alguns dados públicos existentes. No caso de qualquer não conformidade com relação aos dados disponibilizados ou autodeclarados, as informações aqui apresentadas poderão ser revisitadas.
            </p>
        </div>
    </div>
</div>
