import { QrCodeService } from './../../services/qr-code.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { MovementService } from 'src/app/services/movement.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  totalValue: number = 0;
  harvest: string = "";
  nationalAverage: number = 0;
  biomesDiferents?: string[];
  verificationDate = "";
  farmList: Array<any> = [];
  cultura: string = '';

  farms: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private qrCodeService: QrCodeService,
  ) { }

  ngOnInit(): void {
    let data: any = null;
    this.route.queryParams.subscribe((params: any) => {
      data = params['data'];
      if (data) {
        this.qrCodeService.getQRData(data).subscribe(res => {
          const newData = res.data;
          this.totalValue = newData.volumeTotal;
          this.nationalAverage = newData.pegadaMediaGrupo;
          this.harvest = newData.harvest;
          this.cultura = newData.cultura;
          this.farmList = newData.farmList;
          this.biomesDiferents = newData.biomes;
          this.farmList.map(farm => {
            if (!this.farms.some(item => item.code === farm.code)){
              this.farms.push(farm);
            }
          });
          this.verificationDate = newData.verificationDate;
        }, error => {
        })
      }
    });
  }
}
