import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { LancarRecebimentoComponent } from './lancar-recebimento.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrMaskerModule } from 'br-mask';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    LancarRecebimentoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrMaskerModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class LancarRecebimentoModule { }
