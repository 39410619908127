import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnterpriseService } from 'src/app/services/enterprise.service';

@Component({
  selector: 'app-modal-trader',
  templateUrl: './modal-trader.component.html',
  styleUrls: ['./modal-trader.component.scss']
})
export class ModalTraderComponent implements OnInit {

  @Input() traders: any;
  @Output() closeTrader = new EventEmitter<string>();


  formTrader: FormGroup;

  constructor(public fb: FormBuilder,
    private enterpriseService: EnterpriseService,
    private router: Router) {
    this.formTrader = fb.group({
      trader: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {

  }

  toPageTrader() {
    const document = this.formTrader.get('trader')?.value;
    const traderSelected = this.traders.filter((trader: any) => trader.document === document);
    sessionStorage.setItem("user", "userRoot");
    sessionStorage.removeItem('customerId');
    setTimeout(() => {
      this.router.navigate(
        ['/registro-compras'],
        { queryParams: { customerId: traderSelected[0].customerId }}
      );
      this.enviarDados('fechar');
    }, 500);
    this.router.navigate(['/trader']);
  }

  enviarDados(event: string) {
    this.closeTrader.emit(event);
  }

}
