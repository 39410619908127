<div class="enviar-recebimento-content">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="button" (click)="closeModal()">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <rect width="19" height="19" fill="url(#pattern0)"/>
          <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0_5038_20132" transform="scale(0.0104167)"/>
          </pattern>
          <image id="image0_5038_20132" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5UlEQVR4nO2dPXLCMBQG35n8FeZm0JEyl03GN1Aae4YhQLADfj/arSnQrjQ2sgabAQAAAAAAAAAAQDpOZjZafcZ5rKH4MLNmZpOZHawuMrPveayfFkx+Kx5BF/JblAjX8qtG0A357hHuya8WQQ/ku0U4/fGFLiNkvjCP8xieGeuuF+bBzL5WRDgUnfnNc6JVjqDo8itHUBb5FSMom/xKEZRVfoUIyi4/cwRVkZ8xgqrJzxRBVeVniKDq8iNHUC/yI0ZQb/IjRVCv8iNEUO/yPSMI+X4RhHy/CEK+XwQh3y+CkO8XQcj3iyDk+0UQ8v0iCPl+Z3GmlZ8t+wvXcyU05MeOMDHz/SJMyPdbCRPy/SJMyH8tWnGruWbbAt4gnwgB5DdWgr/8RgR/+Y0I6xnZivBDGzbWIhx5KYH+satJBEf5C0TYiF64n08ER/kLRHgSvfFJFhEc5S8Q4Q57PsMduEX1k79AhBnP0wtD7yshwtGRodcIEeR3GyGS/O4iRJTfTYTI8stHyCC/bIRM8stFyCi/TITM8tNHqCA/bYRK8tNFqCg/TYTK8lNE4K+L7VeE3d8pcCw687eshLM5cSwu/5kIbvLvRagm/1EEd/nXEarKvxUhjPwFXuIDAAAAAAAAAAAAFpYfaDRgQW8B0IsAAAAASUVORK5CYII="/>
          </defs>
        </svg>
      </button>
      <span>Dados de Baixa do Talhão</span>
    </div>
    <div class="input-baixa">
      <div class="info-baixa">
        <table>
          <thead>
            <tr>
              <th>Data Venda</th>
              <th>Trader</th>
              <th>Volume</th>
              <th>Token de baixa</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let movement of movementsList; let i = index">
              <td>{{ (movement.movementDate | date : 'dd/MM/yyy') || '-' }}</td>
              <td>{{ movement.customer.document || '-' }}</td>
              <td>{{ (movement.amount || 0) | number: '1.0-2' }}</td>
              <td>{{ movement.hash || '-' }}</td>
            </tr>
            <tr *ngIf="movementsList.length === 0">
              <td colspan="4" style="text-align: center; width: 100vw; max-width: inherit;">
                Nenhum movimento cadastrado
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr>
      <div class="buttons-area">
        <button type="button" class="cancel" (click)="closeModal()">VOLTAR</button>
      </div>
    </div>
  </div>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>
