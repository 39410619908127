import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fouth-section-landing',
  templateUrl: './fouth-section-landing.component.html',
  styleUrls: ['./fouth-section-landing.component.scss']
})
export class FouthSectionLandingComponent implements OnInit {

  @Input() preservedArea: number = 0;
  @Input() soybeanArea: number = 0;
  @Input() qtdBiomes: number = 0;

  preservedAreaString: string = "";
  soybeanAreaString: String = "";
  qtdBiomesString: String = "";

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.preservedAreaString = parseFloat(this.preservedArea.toFixed(2)).toLocaleString('pt-BR');
    this.soybeanAreaString = parseFloat(this.soybeanArea.toFixed(2)).toLocaleString('pt-BR');
    this.qtdBiomesString = parseFloat(this.qtdBiomes.toFixed(2)).toLocaleString('pt-BR');
  }

}
