import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-agriculturist',
  templateUrl: './carousel-agriculturist.component.html',
  styleUrls: ['./carousel-agriculturist.component.scss']
})
export class CarouselAgriculturistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
