import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IbgeDataServiceService {
  constructor(private http: HttpClient) { }
  api = 'https://servicodados.ibge.gov.br/api/v1';

  getAreaById(id: string | number) {
    return this.http.get<any>(`${this.api}/localidades/municipios/${id}`);
  }
}
