<div class="section-1">
  <div class="text-area">
    <p class="paragraph-1">
      Conhecer a origem do produto permite a verificação de seus atributos de sustentabilidade e seus impactos positivos no meio ambiente
    </p>
    <div class="paragraph-2">
      <p>
        <strong> O que é o Programa PRO Carbono? -</strong> é uma iniciativa que visa a transformação de uma agricultura cada vez mais sustentável com práticas de agricultura regenerativa e menores emissões de carbono.
      </p>
      <p>
        Por meio da Footprint PRO Carbono que foi codesenvolvida com Embrapa, e lastreada em uma metodologia com reconhecimento internacional, a Avaliação do Ciclo de Vida (ACV), conseguimos mensurar em larga escala a pegada de carbono de produtores de commodities agrícolas utilizando dados específicos da safra.
      </p>
      <p>
        Os agricultores participantes que visam a mensuração da pegada de carbono e as suas áreas participantes passaram por uma restrita análise a fim de garantir o compliance socioambiental, conformidade com o Código Florestal e áreas livres de desmatamento.
      </p>
      <p>
        Detalhes da iniciativa, da ferramenta e de como os cálculos são feitos podem em encontrados <a href="https://www.agro.bayer.com.br/pro-carbono-commodities">aqui.</a>
      </p>
    </div>
  </div>
  <div class="image-area">
    <img src="../../../assets/images/image-1-landing.png">
  </div>
</div>
