<div class="container">
    <div class="carousel-agriculturist">
        <input type="radio" id="carousel-agriculturist-1" name="carousel-agriculturist[]" checked>
        <input type="radio" id="carousel-agriculturist-2" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-3" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-4" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-5" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-6" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-7" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-8" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-9" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-10" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-11" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-12" name="carousel-agriculturist[]">
        <input type="radio" id="carousel-agriculturist-13" name="carousel-agriculturist[]">

        <ul class="carousel-agriculturist__items">
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF7995.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF8275.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF1035.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF1247.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF1672.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF1718.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DJI_0137_Moment 2.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF4453.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF5990.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF7392 (1).jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF7631.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF4963.jpg">
          </li>
          <li class="carousel-agriculturist__item">
            <img src="../../assets/images/full-hd/DSCF8398.jpg">
          </li>
        </ul>

        <div class="carousel-agriculturist__prev">
          <label for="carousel-agriculturist-1"></label>
          <label for="carousel-agriculturist-2"></label>
          <label for="carousel-agriculturist-3"></label>
          <label for="carousel-agriculturist-4"></label>
          <label for="carousel-agriculturist-5"></label>
          <label for="carousel-agriculturist-6"></label>
          <label for="carousel-agriculturist-7"></label>
          <label for="carousel-agriculturist-8"></label>
          <label for="carousel-agriculturist-9"></label>
          <label for="carousel-agriculturist-10"></label>
          <label for="carousel-agriculturist-11"></label>
          <label for="carousel-agriculturist-12"></label>
          <label for="carousel-agriculturist-13"></label>
        </div>

        <div class="carousel-agriculturist__next">
          <label for="carousel-agriculturist-1"></label>
          <label for="carousel-agriculturist-2"></label>
          <label for="carousel-agriculturist-3"></label>
          <label for="carousel-agriculturist-4"></label>
          <label for="carousel-agriculturist-5"></label>
          <label for="carousel-agriculturist-6"></label>
          <label for="carousel-agriculturist-7"></label>
          <label for="carousel-agriculturist-8"></label>
          <label for="carousel-agriculturist-9"></label>
          <label for="carousel-agriculturist-10"></label>
          <label for="carousel-agriculturist-11"></label>
          <label for="carousel-agriculturist-12"></label>
          <label for="carousel-agriculturist-13"></label>
        </div>

        <div class="carousel-agriculturist__nav">
          <label for="carousel-agriculturist-1"></label>
          <label for="carousel-agriculturist-2"></label>
          <label for="carousel-agriculturist-3"></label>
          <label for="carousel-agriculturist-4"></label>
          <label for="carousel-agriculturist-5"></label>
          <label for="carousel-agriculturist-6"></label>
          <label for="carousel-agriculturist-7"></label>
          <label for="carousel-agriculturist-8"></label>
          <label for="carousel-agriculturist-9"></label>
          <label for="carousel-agriculturist-10"></label>
          <label for="carousel-agriculturist-11"></label>
          <label for="carousel-agriculturist-12"></label>
          <label for="carousel-agriculturist-13"></label>
        </div>
    </div>
</div>
