import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginAdmComponent } from 'src/app/pages/login-adm/login-adm.component';

@Component({
  selector: 'app-modal-type-user',
  templateUrl: './modal-type-user.component.html',
  styleUrls: ['./modal-type-user.component.scss']
})
export class ModalTypeUserComponent implements OnInit {

  @Output() dadosEnviados = new EventEmitter<string>();

  formUser: FormGroup;

  arrayOptions = [
    { op: 'Trader' },
    { op: 'Produtor' }
  ]

  constructor(public fb: FormBuilder) {

    this.formUser = fb.group({
      userType: ['', [Validators.required]],
    });


  }

  ngOnInit(): void {

  }

  enviarDados() {
    const dados = this.formUser.get('userType')?.value;
    this.dadosEnviados.emit(dados);
  }

  enviarDadosCancel() {
    this.dadosEnviados.emit('close');
  }

  chamarMetodoPai() {

  }


  ngAfterViewInit(): void {
  }

  closeModal() {
    // Fechar o modal
  }

  sendValueChosen() {
    // Emitir valor escolhido

  }


}
