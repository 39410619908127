import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { DataMovement } from 'src/app/interfaces/datamovement';
import { Place } from 'src/app/interfaces/place';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { MovementService } from 'src/app/services/movement.service';

@Component({
  selector: 'app-modal-dados-baixa',
  templateUrl: './modal-dados-baixa.component.html',
  styleUrls: ['./modal-dados-baixa.component.scss']
})
export class ModalDadosBaixaComponent implements OnInit {

  @Input() trackable!: any;
  @Input() producer: any;
  @Input() movements: any;
  @Output() close = new EventEmitter();

  isLoading: boolean = false;
  trackableItem: any;
  movementsList: any;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.movementsList = this.movements.filter((item: any) => item.origin.hash === this.trackable.hash);
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  closeModal() {
    this.close.emit('close');
  }
}
