import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  typeUser?: string | null;
  trader: boolean = false;
  productor: boolean = false;
  currentUrl: string = '';
  userId: string | null = "";
  registerVolume: string = "";
  traderPage: boolean = false;
  registerPage: boolean = false;
  productorPage: boolean = false;
  productorInitialPage: boolean = false;
  rastreabilityPage: boolean  = false;
  routeProductorInitial?: string;
  urlRegister: string = "";
  adm: boolean = false;
  modalOpen: boolean = false;
  modalTrader: boolean = false;
  modalProducer: boolean = false;
  groups: any;
  producers: any[] = [];
  traders: any;
  loading: boolean = false;
  customerId: string | null = null;
  token: string | null = null;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private enterpriseService: EnterpriseService,
    private sessionService: SessionService
  ) {
    this.userId = localStorage.getItem("userId");
    this.token = sessionStorage.getItem('token')

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
      }

      if (this.currentUrl.includes("trader") || this.currentUrl.includes("registro-compras") || this.currentUrl.includes("lancar-recebimento")) {
        this.trader = true;
        this.productor = false;
      }

      else if (this.currentUrl.includes("produtor-inicio") || this.currentUrl.includes("produtor")) {
        this.trader = false;
        this.productor = true;
      }

      if (this.currentUrl.includes('/trader')) {
        this.traderPage = true;
        this.productorInitialPage = false;
        this.rastreabilityPage = false;
        this.productorPage = false;
        this.registerPage = false;
        //const accordionItem = document.getElementsByClassName("trader");
        //accordionItem.item(0)?.classList.add("active");
      }


      if (this.currentUrl.includes('/laudo-rastreabilidade')) {
        this.traderPage = false;
        this.productorInitialPage = false;
        this.rastreabilityPage = true;
        this.productorPage = false;
        this.registerPage = false;
      }
      else if (this.currentUrl.includes('/produtor-inicio')) {
        this.productorInitialPage = true;
        this.traderPage = false;
        this.rastreabilityPage = false;
        this.productorPage = false;
        this.registerPage = false;
      }
      else if (this.currentUrl.includes('/produtor')) {
        this.productorPage = true;
        this.traderPage = false;
        this.rastreabilityPage = false;
        this.productorInitialPage = false;
        this.registerPage = false;
      }
      else if (this.currentUrl.includes('/registro-compras')) {
        this.registerPage = true;
        this.rastreabilityPage = false;
        this.traderPage = false;
        this.productorInitialPage = false;
        this.productorPage = false;
      }
    });
  }

  async ngOnInit() {
    let url = window.location.href;

    if (url.includes("trader") || url.includes("registro-compras") || url.includes("lancar-recebimento")) {
      this.trader = true;
      this.productor = false;
    }

    else if (url.includes("produtor-inicio") || url.includes("produtor")) {
      this.trader = false;
      this.productor = true;
    }

    let user = sessionStorage.getItem("user");

    if (user == "userRoot") {

      this.adm = true;
    }
    else {
      this.adm = false;
    }

    // setInterval(this.verifyAdm, 2000);

    this.routeProductorInitial = `/produtor-inicio?id=33432423`;
    const accordionItem2 = document.getElementsByClassName("trader");

    this.registerVolume = "/registro-compra";

    const accordionItem = document.querySelectorAll(".accordion-item");

    accordionItem.forEach((el) =>
      el.addEventListener("click", () => {
        if (el.classList.contains("active")) {
          el.classList.remove("active");
        } else {
          accordionItem.forEach((el2) => el2.classList.remove("active"));
          el.classList.add("active");
        }
      })
    );
  }

  verifyAdm() {
    let user = sessionStorage.getItem("user");

    if (user == "userRoot") {

      this.adm = true;
    }
    else {
      this.adm = false;
    }
  }

  ngOnChange() {
    this.userId = localStorage.getItem("userId");
  }

  close(event: any) {
    if(event === 'voltar') {
      this.modalOpen = true;
      this.modalProducer = false;
      this.modalTrader = false;
    } else {
      this.modalOpen = false;
      this.modalProducer = false;
      this.modalTrader = false;
    }
  }


  redirectProductorInitial() {
    this.userId = localStorage.getItem('customerId');

    this.router.navigate(
      ['/produtor-inicio'],
      { queryParams: { customerId: this.userId } }
    );
  }

  redirectRegister() {

    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
    })

    if (!this.userId) {
      this.userId = localStorage.getItem('customerId');
    }

    this.router.navigate(
      ['/registro-compras'],
      { queryParams: { customerId: this.userId } }
    );


  }

  redirectTrader() {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
    });

    if (!this.userId) {
      this.userId = localStorage.getItem('customerId');
    }

    this.router.navigate(
      ['/trader'],
      { queryParams: { customerId: this.userId } }
    );
  }

  changeModal(dados: string) {
    if (dados != 'close') {
      let type = dados;

      if (type == 'Produtor') {
        this.modalOpen = false;
        this.modalProducer = true;
        this.getAllGroups();
      }
      else if (type == 'Trader') {
        this.modalOpen = false;
        this.modalTrader = true;
        this.getAllTraders();
      }
    }
    else {
      this.modalOpen = false;
    }

  }

  getAllGroups() {
    this.loading = true;
    this.enterpriseService.getAllGroups().subscribe(groupsReturned => {
      this.groups = groupsReturned;
      this.getProducers();
      this.loading = false;
    }, error => {
      this.loading = false;
    })

  }

  async getProducers() {
    if (this.producers.length == 0) {
      await this.groups.forEach((group: any) => {
        this.enterpriseService.getDatasGroupByEnterpriseCode(group).subscribe(producers => {
          this.producers.push(producers);
          this.producers = this.producers.flat(1);
        })
      })
    }
  }

  getAllTraders() {
    if (!this.traders) {
      this.loading = true;
      this.enterpriseService.getEnterpriseByOperationalType('TRADER').subscribe(traders => {
        this.traders = traders;
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    }
  }

  openModalTypeUser() {
    this.modalOpen = true;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

}
