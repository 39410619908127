import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  readonly urlAPI = environment.apiURL;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {}


  getQRData(token: string): Observable<any> {
    return this.http.get(`${this.urlAPI}/qrcode/get-data/${token}`);
  }

  postQRData(body: { data: any }){
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.post(`${this.urlAPI}/qrcode/save-data`, body, { headers: this.headers }).pipe(
      res => res,
      error => error
    );
  }
}
