<div class="enviar-recebimento-content">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" (click)="closeModal()">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <rect width="19" height="19" fill="url(#pattern0)"/>
          <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0_5038_20132" transform="scale(0.0104167)"/>
          </pattern>
          <image id="image0_5038_20132" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5UlEQVR4nO2dPXLCMBQG35n8FeZm0JEyl03GN1Aae4YhQLADfj/arSnQrjQ2sgabAQAAAAAAAAAAQDpOZjZafcZ5rKH4MLNmZpOZHawuMrPveayfFkx+Kx5BF/JblAjX8qtG0A357hHuya8WQQ/ku0U4/fGFLiNkvjCP8xieGeuuF+bBzL5WRDgUnfnNc6JVjqDo8itHUBb5FSMom/xKEZRVfoUIyi4/cwRVkZ8xgqrJzxRBVeVniKDq8iNHUC/yI0ZQb/IjRVCv8iNEUO/yPSMI+X4RhHy/CEK+XwQh3y+CkO8XQcj3iyDk+0UQ8v0iCPl+Z3GmlZ8t+wvXcyU05MeOMDHz/SJMyPdbCRPy/SJMyH8tWnGruWbbAt4gnwgB5DdWgr/8RgR/+Y0I6xnZivBDGzbWIhx5KYH+satJBEf5C0TYiF64n08ER/kLRHgSvfFJFhEc5S8Q4Q57PsMduEX1k79AhBnP0wtD7yshwtGRodcIEeR3GyGS/O4iRJTfTYTI8stHyCC/bIRM8stFyCi/TITM8tNHqCA/bYRK8tNFqCg/TYTK8lNE4K+L7VeE3d8pcCw687eshLM5cSwu/5kIbvLvRagm/1EEd/nXEarKvxUhjPwFXuIDAAAAAAAAAAAAFpYfaDRgQW8B0IsAAAAASUVORK5CYII="/>
          </defs>
        </svg>
      </button>
      <span>Informe os dados da baixa</span>
    </div>
    <div class="stepper">
      <div class="step step-one" (click)="changePage(1)">
        <div [class]="page === 1 ? 'step-circle selected' : 'step-circle'">
          {{ 1 }}
        </div>
        <span [class]="page === 1 ? 'span-selected' : 'span-unselected'"> Informações da baixa </span>
      </div>
      <div>
        <svg width="140" height="4" viewBox="0 0 140 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="2.10547" x2="139.5" y2="2.10546" stroke="black" stroke-opacity="0.12" stroke-width="3"/>
        </svg>
      </div>
      <div class="step step-two" (click)="changePage(2)">
        <div [class]="page === 2 ? 'step-circle selected' : 'step-circle'">
          {{ 2 }}
        </div>
        <span [class]="page === 2 ? 'span-selected' : 'span-unselected'">Informações do Recebimento </span>
      </div>
    </div>
    <form [formGroup]="form" class="form-area">
      <div class="input-baixa" [hidden]="page !== 1">
        <div class="info-produtor">
          <p>Dados do produto selecionado: </p>
          <table>
            <tr>
              <th>CPF/CNPJ</th>
              <th>PRODUTOR</th>
              <th>GRUPO ECONÔMICO</th>
              <th>CULTURA</th>
            </tr>
            <tr>
              <td>{{ producer.document | document }}</td>
              <td>{{ producer.corporateName || '-' }}</td>
              <td>{{ producer.economicGroup || '-'}}</td>
              <td>{{ cultureSelected }}</td>
            </tr>
          </table>
        </div>
        <hr>
        <div class="info-baixa">
          <p>Dados da baixa </p>
          <table formArrayName="trackables">
            <thead>
              <tr>
                <th>FAZENDA</th>
                <th>PEGADA MÉDIA</th>
                <th>TALHÃO</th>
                <th>SALDO</th>
                <th>BAIXA</th>
              </tr>
            </thead>
            <tbody>
              <tr [formGroupName]="i" *ngFor="let trackable of getFormArray('trackables'); let i = index">
                <td>{{ getFarmName() }}</td>
                <td>{{ trackable.get('pegada')?.value | number }}</td>
                <td>{{ trackable.get('talhao')?.value }}</td>
                <td>{{ (trackable.get('saldo')?.value | number) + ' ton' }}</td>
                <td>
                  <div class="input-baixa-area-td">
                    <div class="input-baixa-area" [style]="trackable.get('baixa')?.touched && trackable.get('baixa')?.value <= 0 ? 'borderColor:red' : ''">
                      <input
                        type="number"
                        [value]="0"
                        min="0.0000000000000001"
                        [max]="trackable.get('saldo')?.value"
                        formControlName="baixa"
                        (change)="valitadeValue(i)"
                      >
                      <span>ton</span>
                    </div>
                    <!-- <button type="button">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.59 12L16 14.59L13.41 12L12 13.41L14.59 16L12 18.59L13.41 20L16 17.41L18.59 20L20 18.59L17.41 16L20 13.41L18.59 12ZM16 6C10.47 6 6 10.47 6 16C6 21.53 10.47 26 16 26C21.53 26 26 21.53 26 16C26 10.47 21.53 6 16 6ZM16 24C11.59 24 8 20.41 8 16C8 11.59 11.59 8 16 8C20.41 8 24 11.59 24 16C24 20.41 20.41 24 16 24Z" fill="black" fill-opacity="0.38"/>
                      </svg>
                    </button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <div class="total-baixa">
          <span>TOTAL BAIXA:</span>
          <div class="total-value-baixa">
            <div class="total-value">{{ getTotalBaixa() }}</div>
            <span>ton</span>
          </div>
        </div>
        <div class="buttons-area">
          <button type="button" class="cancel" (click)="closeModal()">CANCELAR</button>
          <button type="button" class="send" (click)="changePage(2)">CONTINUAR</button>
        </div>
      </div>
      <div class="receivement-info" [hidden]="page !== 2">
        <div class="form-area">
          <div class="input-area">
            <span>Data do recebimento do grão</span>
            <input type="date" [max]="dateNow | date:'yyyy-MM-dd'" formControlName="date" placeholder="Data de recebimento" style="padding-right: 12px;" [style]="form.get('date')?.touched && form.get('date')?.invalid ? 'borderColor:red' : ''">
          </div>
          <div class="input-area">
            <span>Contrato de venda produtor</span>
            <input type="text" formControlName="document" mask="000.000.000-00||00.000.000/0000-00" placeholder="CNPJ da venda do produtor" [style]="form.get('document')?.touched && form.get('document')?.invalid ? 'borderColor:red' : ''">
          </div>
          <div class="input-area">
            <input type="text" formControlName="contractNumber" placeholder="Número do contrato de venda" [style]="form.get('contractNumber')?.touched && form.get('contractNumber')?.invalid ? 'borderColor:red' : ''">
          </div>
          <div class="input-area">
            <span>Nota Fiscal (Opcional) </span>
            <input type="text" formControlName="invoiceNumber" placeholder="Número da Nota Fiscal (Opcional)" style="height: 90px;" [style]="form.get('invoiceNumber')?.touched && form.get('invoiceNumber')?.invalid ? 'borderColor:red' : ''">
          </div>
        </div>
        <div class="buttons-area">
          <button type="button" class="cancel" (click)="changePage(1)">VOLTAR</button>
            <button type="button" class="send" (click)="submitForm()">FINALIZAR</button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>
