import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { MovementService } from 'src/app/services/movement.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Place } from 'src/app/interfaces/place';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dados-extrato',
  templateUrl: './dados-extrato.component.html',
  styleUrls: ['./dados-extrato.component.scss']
})
export class DadosExtratoComponent implements OnInit {

  formFilter !: FormGroup;

  userId?: string | null = null;

  farmList: any[] = [];
  trackableItems: TrackableItem[] = [];

  talhaoList: any = [];
  harvestList: any[] = [];
  hashOrigins: any[] = [];
  tableData: any[] = [];
  farmSwicthed: any;
  trackableItem?: TrackableItem;
  culturesList: any[] = [];
  producer?: Enterprise;
  farms: any = [];
  producers: any;
  movementsList: any[] = [];
  producersList: any;
  isLoading: boolean = false;
  isShowingModalDadosBaixa: boolean = false;
  selectedMovements: any;
  firstEnterprise: any;
  loadingCount = 0;
  tableActive = false;

  constructor(
    private location: Location,
    private router: Router,
    private enterpriseService: EnterpriseService,
    private traceabilityService: TraceabilityService,
    private movementeService: MovementService,
  ) {
    this.formFilter = new FormGroup({
      producer: new FormControl(''),
      farm: new FormControl(''),
      harvest: new FormControl(''),
      culture: new FormControl(''),
      talhao: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.userId = sessionStorage.getItem('customerId');
    this.getProducer();


    if (!this.userId) {
      this.userId = sessionStorage.getItem('customerId');
    }
    localStorage.setItem('traceId', this.userId as string);
    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.userId).subscribe(item => {
      this.isLoading = false;
      this.firstEnterprise = item[0];
      if (this.firstEnterprise.economicGroup) {
        this.isLoading = true;
        this.enterpriseService.getDatasGroupByEnterpriseCode(this.firstEnterprise.economicGroup).subscribe(res => {
          this.isLoading = false;
          this.producersList = res;
        }, error => {
          this.isLoading = false;
        })
      }
      sessionStorage.setItem('customerId', this.firstEnterprise.customerId);
    }, error => {
      this.isLoading = false;
    });
  }

  getFarms() {
    this.farmList = [];
    this.producer?.places.forEach(item => {
      if (item.type === 'FARM' && !this.farmList.some((farm: Place) => farm.code === item.code)) {
        this.farmList.push(item);
      }
    });
  }

  onChangeProducer() {
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');

    const producer = this.producersList.find((item: any) => item.id === this.formFilter.get('producer')?.value);
    this.farmList = [];

    if (producer) {
      producer.places.forEach((element: any) => {
        if (element.type === 'FARM') {
          if (!this.farmList.some((farm: Place) => farm.code === element.code)) {
            this.farmList.push(element);
          }
        }
      });
    }
  }

  getProducer() {
    const customerId = sessionStorage.getItem('customerId');
    this.enterpriseService.getEnterpriseByCustomerId(customerId).subscribe(res => {
      if (res && res.length > 0) {
        this.producer = res[0];
        sessionStorage.setItem('economicGroup', this.producer.economicGroup);
        sessionStorage.setItem('document', this.producer.document);


      }
    })
  }

  handleBackButton() {
    this.location.back();
  }

  cleanFilter() {
    this.formFilter.get('producer')?.setValue('');
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('harvest')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.formFilter.get('talhao')?.setValue('');
    this.tableActive = false;
    this.farmSwicthed = "";
  }

  submitForm() {
    this.trackableItems = [];
    this.formFilter.markAllAsTouched();
    if (this.formFilter.valid) {
      this.tableActive = false;
      const farm = this.formFilter.get('farm')?.value;
      const harvest = this.formFilter.get('harvest')?.value;
      const culture = this.formFilter.get('culture')?.value;
      const talhao = this.formFilter.get('talhao')?.value;

      this.trackableItems = this.tableData;

      this.isLoading = true;
      setTimeout(() => {
        if (farm) {
          const fazendas = this.producer?.places.filter(place => place.code === farm);
          this.trackableItems = this.trackableItems.filter(item => fazendas?.find(fazenda => fazenda.subPlaces.some(subPlace => subPlace.code === item.item.place)));
        }

        if (harvest) {
          this.trackableItems = this.trackableItems.filter((item: any) => this.getFeatureByName(item.item, 'ANO_SAFRA') === harvest);
        }

        if (culture) {
          this.trackableItems = this.trackableItems.filter((item: any) => this.getFeatureByName(item.item, 'CULTURA') === culture);
        }

        if (talhao) {
          this.trackableItems = this.trackableItems.filter((item: any) => this.getFeatureByName(item.item, 'NOME_TALHAO') === talhao);
        }

        this.tableActive = true;
        this.isLoading = false;
      }, 500)
    }
  }

  getFarmSelected() {
    this.farmList.forEach(farm => {
      if (farm.code == this.formFilter.get('farm')?.value) {
        this.farmSwicthed = farm;
        this.getDataTableInfo();
      }
    })


  }

  getDataTableInfo() {
    const document = sessionStorage.getItem('document') as string;
    this.isLoading = true;
    this.traceabilityService.getTraceByDocument(document).subscribe(res => {
      this.isLoading = false;
      res.forEach(trackable => {
        const talhao = this.getFeatureByName(trackable.item, 'NOME_TALHAO');
        if (talhao && !this.talhaoList.some((item: string) => item === talhao)) {
          this.talhaoList.push(talhao);
        }
        this.farmSwicthed.subPlaces.forEach((subPlace: any) => {
          if (subPlace.code == trackable?.item?.place) {

            const harvest = this.getFeatureByName(trackable.item, 'ANO_SAFRA');
            if (harvest && !this.harvestList.some((item: string) => item === harvest)) {
              this.harvestList.push(harvest);
            }
            const culture = this.getFeatureByName(trackable.item, 'CULTURA');
            if (culture && !this.culturesList.some((item: string) => item === culture)) {
              this.culturesList.push(culture);
            }
          }
        })

      })
      this.tableData = res;
      this.trackableItems = this.tableData;
      this.getMovements();
    }, error => {
      this.isLoading = false;
    });
  }

  getMovements() {
    this.tableActive = false;
    this.tableData.forEach(item => {
      this.loadingCount += 1;
      this.isLoading = true;
      this.movementeService.getMovementByToken(item.hash).subscribe(res => {
        if (res && res.movements.length > 0) {
          res.movements.forEach(movement => {
            this.movementsList.push(movement);
          });
        }
        this.loadingCount -= 1;
        if (this.loadingCount === 0) {
          this.tableActive = true;
          this.isLoading = false;
        }
      }, error => {
        this.loadingCount -= 1;
        if (this.loadingCount === 0) {
          this.tableActive = true;
          this.isLoading = false;
        }
      })
    })
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };

    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }

    return returnFeature.value;
  }

  closeModal(event: any) {
    if (event === 'close') {
      this.isShowingModalDadosBaixa = false;
    } else {
      this.hashOrigins = event;
      this.isShowingModalDadosBaixa = false;
    }
  }

  exportToExcel(): void {
    const trackableItems = this.tableData.map(item => {
      return {
        'Safra': this.getFeatureByName(item.item, 'ANO_SAFRA'),
        'Cultura': this.getFeatureByName(item.item, 'CULTURA'),
        'Fazenda': this.getFarmName(item) || '-',
        'Talhão': this.getFeatureByName(item.item, 'NOME_TALHAO'),
        'Saldo Colhido': item.item.receivedQuantity,
        'Saldo Vendido': item.item.requestedQuantity,
        'Saldo Disponível': item.item.amount,
        'Token': item.hash,
        'Data Token': item.item.productionDate,
      }
    });

    const movements = this.movementsList.map(movement => {
      return {
        'Token Origem': movement.origin.hash,
        'Data Venda': movement.movementDate,
        'Trader': movement.customer.document,
        'Volume': movement.amount,
        'Token baixa': movement.hash
      }
    })

    const worksheet1 = XLSX.utils.json_to_sheet(trackableItems);
    const worksheet2 = XLSX.utils.json_to_sheet(movements);

    const workbook: XLSX.WorkBook = {
      Sheets: {
        'Produção': worksheet1,
        'Baixas': worksheet2
      },
      SheetNames: ['Produção', 'Baixas']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Dados de Extrato');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, `${fileName}.xlsx`);
  }

  getFarmName(element: any) {
    const placeHash = element.item.place;
    const place = this.producer?.places.find(place => place.subPlaces.some(subPlace => subPlace.code === placeHash));
    return place?.nickname || '-';
  }

  handleClickOpenModal(element: any) {
    this.trackableItem = element;
    const movements = this.movementsList;
    this.selectedMovements = movements;
    this.isShowingModalDadosBaixa = true;
  }

  getSaldoVendido(element: any) {
    const movements = this.movementsList.filter(item => item.origin.hash === element.hash);
    let sum = 0;
    if (movements.length > 0) {
      movements.forEach(movement => sum += movement.amount)
    }
    return sum;
  }
}
