<ul class="menu">
  <li class="item-volume" *ngIf="movements.length > 0">
    <div class="title-accordion" (click)="toggleMovementsInfo()">
      <div style="display:flex;justify-content: flex-start;gap: 12px;">
        <button type="button" [ngClass]="open ? 'button-arrow open' : 'button-arrow'">
          <svg width="18" height="18" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2287 0.589844L6.63867 5.16984L2.04867 0.589844L0.638672 1.99984L6.63867 7.99984L12.6387 1.99984L11.2287 0.589844Z" fill="black" fill-opacity="0.38"/>
          </svg>
        </button>
        <div class="text" *ngIf="enterprise">
          <h3 class="text-1"> {{ enterprise.economicGroup || 'Erro' }}</h3>
          <h3 class="infos-accordion">
            Volume total colhido: {{ totalHarvested }} ton |
            Safra: {{ (getFeatureByName(movements[0].movements[0].destiny.item, 'ANO_SAFRA')) || (movements[0].movements[0].destiny.item ? getFeatureByName(movements[0].movements[0].destiny.item.product, 'Ano Safra') : '-') }} |
            Cultura: {{ getFeatureByName(movements[0].movements[0].destiny.item, 'CULTURA') || (movements[0].movements[0].destiny.item ? getCultureName(movements[0].movements[0].destiny.item.product.code) : '-') }}|
            Produtor: {{ enterprise.corporateName }}

          </h3>
        </div>
        <div *ngIf="enterprise">
          <div style="display: flex;justify-content: center; gap: 12px; align-items: center;">
            <div style="width: fit-content;">
              <p style="color: rgba(0, 0, 0, 0.6);font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 0.633vw;">
                Para saber mais sobre o Grupo/<br>
                Produtor acesse o QR CODE ao<br>
                lado ou <a [href]="urlLandingPage">clique aqui.</a>
              </p>
            </div>
            <div>
              <img style="width: 100px; height: 100px;" [src]="urlQRCODE" alt="QR Code">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="open && enterprise">
      <div *ngFor="let data of dataList, index as in">
        <div class="text-open">
          <app-accordion-talhao
            [trackable]="data.trackable"
            [baixas]="data.movements"
          ></app-accordion-talhao>
        </div>
      </div>
    </div>
  </li>
</ul>

<app-spinner *ngIf="isLoading"></app-spinner>
