import { Component, OnInit, Input } from '@angular/core';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { Movement } from 'src/app/interfaces/movement';
import { Place } from 'src/app/interfaces/place';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
@Component({
  selector: 'app-accordion-recebimento',
  templateUrl: './accordion-recebimento.component.html',
  styleUrls: ['./accordion-recebimento.component.scss']
})
export class AccordionRecebimentoComponent implements OnInit {

  @Input() movements: Movement[] = [];

  @Input() customers: Enterprise[] = [];

  @Input() trackable?: TrackableItem;

  constructor() { }

  ngOnInit(): void {
    this.movements = this.movements.sort(function compararPorData(a, b) {
      const dataA = Date.parse(a.movementDate);
      const dataB = Date.parse(b.movementDate);

      if (dataA < dataB) {
        return -1;
      } else if (dataA > dataB) {
        return 1;
      } else {
        return 0;
      }
    });
    this.movements = this.movements.reverse();
  }

  getValue(value: any) {
    let newValue = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);

    return newValue;
  }

  addClass(i: any) {

    const item = document.getElementById(`${i}`);
    if (item?.classList.contains('open')) {
      item?.classList.remove('open');
    }
    else {
      item?.classList.add('open');
    }
  }

  getFarmCode(places: Place[], type: string) {
    if (type === 'code') {
      let place: any;
      places.map(item => {
        if (item.type && item.type === 'FARM') {
          place = item;
        }
      });
      if (place && place.code) {
        return place.code;
      }
    }
    if (type === 'car') {
      let place: any;
      places.map(item => {
        if (item.type && item.type === 'FARM') {
          place = item;
        }
      });
      if (place && place.ruralEnvironmentalRegistry) {
        return place.ruralEnvironmentalRegistry;
      }
    }

    if (type === 'nickname') {
      let place: any;
      places.map(item => {
        if (item.type && item.type === 'FARM') {
          place =item;
        }
      });
      if (place && place.nickname) {
        return place.nickname;
      }
    }
    return '-';
  }

  formatCNPJorCPF(cpforcnpj: string): string {
    if (cpforcnpj.length == 14) {
      // Remove caracteres não numéricos
      cpforcnpj = cpforcnpj.replace(/[^\d]/g, '');

      // Adiciona zeros à esquerda, se necessário
      while (cpforcnpj.length < 14) {
        cpforcnpj = '0' + cpforcnpj;
      }

      // Adiciona pontos e traços
      cpforcnpj = cpforcnpj.replace(/(\d{2})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1/$2');
      cpforcnpj = cpforcnpj.replace(/(\d{4})(\d)/, '$1-$2');

      return cpforcnpj;
    } else {
      // Remove caracteres não numéricos
      cpforcnpj = cpforcnpj.replace(/[^\d]/g, '');

      // Adiciona zeros à esquerda, se necessário
      while (cpforcnpj.length < 11) {
        cpforcnpj = '0' + cpforcnpj;
      }

      // Adiciona pontos e traços
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

      return cpforcnpj;
    }
  }

  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item && item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }
}
