import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { DataMovement } from 'src/app/interfaces/datamovement';
import { MovementService } from 'src/app/services/movement.service';
import { Router } from '@angular/router';
import { Movement } from 'src/app/interfaces/movement';

@Component({
  selector: 'app-lancar-recebimento',
  templateUrl: './lancar-recebimento.component.html',
  styleUrls: ['./lancar-recebimento.component.scss'],
})
export class LancarRecebimentoComponent implements OnInit {

  modal: boolean = false;
  isShowingModalRecebimento: boolean = false;

  trackableItems: TrackableItem[] = [];

  trackableItem?: TrackableItem;

  trader?: Enterprise; //Usar para pegar do trader

  formFilter !: FormGroup;

  formMovement !: FormGroup;

  selectFarms: string[] = [];

  farmList: any[] = [];

  userId?: string | null;
  hashMovement: string = "";

  groups: any;

  flagFarms: boolean = false;

  trackebleItem?: TrackableItem[];

  producers: any;

  trackebleItemByHash: TrackableItem[] = [];

  trackableList: any = [];

  movements: Movement[] = [];
  corporatesNames: any[] = [];

  isLoading: boolean = false;
  hashOrigins: any[] = [];

  farms: any[] = [];

  constructor(
    private router: Router,
    private traceabilityService: TraceabilityService,
    private enterpriseService: EnterpriseService,
    private movementeService: MovementService
  ) {

    this.formFilter = new FormGroup({
      farm: new FormControl('', [Validators.required]),
      groups: new FormControl('', [Validators.required]),
      // producer: new FormControl('', [Validators.required]),
      culture: new FormControl('', [Validators.required]),
    });

    this.formMovement = new FormGroup({
      volume: new FormControl(null, [
        Validators.pattern("(?:\.|,|[0-9])*"),
        Validators.required,
        Validators.min(0),
      ]),
      producerDocument: new FormControl(''),
      contractNumber: new FormControl(''),
      invoice: new FormControl(''),
      dateMovement: new FormControl(null, [Validators.required])
    });

  }

  async ngOnInit() {
    this.userId = sessionStorage.getItem('customerId');
    this.getTrader();
  }


  ngOnChange() {
  }

  intervaloDataValidator =
    (formGroup: AbstractControl): ValidationErrors | null => {
      // Etapa 1
      const valorDataInicial = this.formMovement.get('dateMovement')?.value;

      // Etapa 2
      const dataInicial = new Date(valorDataInicial);
      const dataFinal = new Date();

      return dataInicial > dataFinal ? { dateValid: false } : null;
    };

  async getTrader() {
    this.isLoading = true;
    this.enterpriseService.getEnterpriseByCustomerId(this.userId).subscribe(async (enterprises): Promise<void> => {
      this.isLoading = false;
      this.trader = enterprises[0];
      this.getAllGroups();
    }, error => {
      this.isLoading = false;
      this.getAllGroups();
    });
  }

  getAllGroups() {
    this.isLoading = true;
    this.enterpriseService.getAllGroups().subscribe(res => {
      this.isLoading = false;
      this.groups = res;
    }, error => {
      this.isLoading = false;
    });
  }

  getProducers() {
    this.formFilter.get('producer')?.setValue('');
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.farmList = [];
    this.farms = [];
    this.isLoading = true;
    this.enterpriseService.getDatasGroupByEnterpriseCode(this.formFilter.get('groups')?.value).subscribe(res => {
      this.isLoading = false;
      this.producers = res;
      this.getNamesFarms();
    }, error => {
      this.isLoading = false;
    })
  }

  getValue(value: any) {
    let newValue = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);
    return newValue;
  }

  async getNamesFarms() {
    this.formFilter.get('farm')?.setValue('');
    this.formFilter.get('culture')?.setValue('');
    this.farmList = [];
    this.farms = [];
    await this.producers.forEach((producer: any) => {
      producer.places.map((item: any) => {
        if (item.type === 'FARM') {
          this.farmList.push(item);
          if (!this.farms.some(i => i.code == item.code)) {
            this.farms.push(item);
          }
        }
      });
    });
  }

  redirectRegister() {
    let userId = localStorage.getItem('traceId');
    this.router.navigate(
      ['/registro-compras'],
      { queryParams: { customerId: userId } }
    );
  }

  showModalRecebimento() {
    this.isShowingModalRecebimento = true;
  }

  changeNameFarmToCar() {
    this.formFilter.get('culture')?.setValue('');
    const place = this.farmList.find((item: any) => this.formFilter.get('farm')?.value === item.code);
  }

  selectItemTrackable(event: any, trackable: TrackableItem) {
    this.trackableItem = trackable;
    if (event.target.checked) {
      this.trackableList.push(trackable);
    } else {
      this.trackableList = this.trackableList.filter((item: TrackableItem) => item.hash !== trackable.hash);
    }
  }

  getSum() {
    let sum = 0;
    this.trackableList.map((trackable: TrackableItem) => sum += trackable.item.amount);
    return sum.toFixed(2);
  }

  showModal() {
    this.modal = true;
  }

  submitForm() {
    this.trackableList = [];
    this.formFilter.markAllAsTouched();
    const economicGroup = this.formFilter.get('groups')?.value;
    if (this.formFilter.valid) {
      this.isLoading = true;
      this.traceabilityService.getTraceByPlaceCode(this.formFilter.get('farm')?.value, economicGroup).subscribe(res => {
        this.isLoading = false;
        const culture = this.formFilter.get('culture')?.value.toLowerCase();
        this.trackableItems = res.filter(item => this.getFeatureByName(item.item, 'CULTURA').toLowerCase().includes(culture) || (culture === 'soja' && this.getFeatureByName(item.item, 'CULTURA') === '-'));
      }, error => {
        this.isLoading = false;
      });
    }
  }

  getEnterpriseName(enterpriseCode: string): string {
    const enterprise = this.producers.find((enterprise: any) => enterprise.enterpriseCode === enterpriseCode);
    return enterprise?.corporateName ?? '-';
  }

  getEnterpriseDocument(enterpriseCode: string): string {
    const enterprise = this.producers.find((enterprise: any) => enterprise.enterpriseCode === enterpriseCode);
    return enterprise?.document ?? '-';
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  closeModal(event: any) {
    if (event === 'close') {
      this.isShowingModalRecebimento = false;
    } else {
      this.hashOrigins = event;
      this.isShowingModalRecebimento = false;
      this.modal = true;
    }
  }

  getFarmName(): string {
    const farm = this.farmList.find((item: any) => item.code === this.formFilter.get('farm')?.value);
    return farm.nickname;
  }
}
