<div class="container">
    <div class="carousel-videos">
        <input type="radio" id="carousel-videos-1" name="carousel-videos[]" checked>
        <input type="radio" id="carousel-videos-2" name="carousel-videos[]">
        <input type="radio" id="carousel-videos-3" name="carousel-videos[]">

        <ul class="carousel-videos__items">
            <li class="carousel-videos__item">
                <img class="video" src="../../../assets/images/video.png">
            </li>
            <li class="carousel-videos__item">
                <img class="video" src="../../../assets/images/video.png">
            </li>
            <li class="carousel-videos__item">
                <img class="video" src="../../../assets/images/video.png">
            </li>
        </ul>

        <div class="carousel-videos__prev">
            <label for="carousel-videos-1"></label>
            <label for="carousel-videos-2"></label>
            <label for="carousel-videos-3"></label>
        </div>

        <div class="carousel-videos__next">
            <label for="carousel-videos-1"></label>
            <label for="carousel-videos-2"></label>
            <label for="carousel-videos-3"></label>
        </div>

        <div class="carousel-videos__nav">
            <label for="carousel-videos-1"></label>
            <label for="carousel-videos-2"></label>
            <label for="carousel-videos-3"></label>
        </div>
    </div>
</div>