import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-landing-mobile',
  templateUrl: './header-landing-mobile.component.html',
  styleUrls: ['./header-landing-mobile.component.scss']
})
export class HeaderLandingMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const hamburger = document.querySelector('.hamburger');
    const siteNav = document.querySelector('.site-navigation');

    hamburger?.addEventListener('click', event => {
      event.preventDefault();
      hamburger.classList.toggle('is-active');
      siteNav?.classList.toggle('is-open');
    });
  }

}
