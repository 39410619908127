<nav id="navbarMobile" style="width:100%;" class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <button style="margin-left:10px" class="navbar-toggler" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>

  <a class="navbar-brand" href="#">
      <img id="logo" src="../../../assets/images/Bayer.png">
  </a>


  <div class="collapse navbar-collapse ml-5 mr-3 bg-light" id="navbarSupportedContent">
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item" (click)="redirectRegister()" [ngStyle]="{'background-color': registerPage ? '#87CEEB' : ''}" *ngIf="trader">
          Registro de Volume
        </li>
        <li (click)="redirectTrader()" [ngStyle]="{'background-color': traderPage ? '#87CEEB' : ''}" *ngIf="trader" class="trader nav-item">
          Rastreabilidade
        </li>
        <li routerLink="/produtor" *ngIf="productor" [ngStyle]="{'background-color': productorPage ? '#87CEEB' : ''}" class="nav-item">
          Extrato de Saída de Volume
        </li>
        <li (click)="redirectProductorInitial()" [ngStyle]="{'background-color': productorInitialPage ? '#87CEEB' : ''}" *ngIf="productor" class="nav-item">
          Rastreabilidade
        </li>
        <p routerLink="/laudo-rastreabilidade"  [ngStyle]="{'background-color': rastreabilityPage ? '#87CEEB' : ''}" *ngIf="adm" class="nav-item">
          Laudo de Rastreabilidade
        </p>

        <!-- <li class="nav-item">
            <a href="" class="list_nav">Extrato de Vendas</a>
        </li> -->
      </ul>
  </div>
</nav>
