import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class AuthService {
  readonly urlAPI = environment.apiURL;
  constructor(
    private http: HttpClient,
  ) { }

  public login(username: string, password: string) {
    return this.http.post<string>(`${this.urlAPI}/login`, { username, password}, { responseType: 'text' as 'json' });
  }

}
