import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  @Input() hashOrigins: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    location.reload();
  }
}
