import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Enterprise } from '../interfaces/enterprise';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {
  readonly urlAPI = environment.apiURL;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {
    const token = sessionStorage.getItem('token');
    if (token) {
      this.headers.set('Authorization', token);
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
  }

  getEnterpriseByCustomerId(customerId: any): Observable<Enterprise[]> {
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.get<Enterprise[]>(
      `${this.urlAPI}/enterprise-service/list?customerId=${customerId}`, { headers: this.headers });
  }

  getEnterpriseByDocument(document: string): Observable<Enterprise[]> {
    return this.http.get<Enterprise[]>(
      `${this.urlAPI}/enterprise-service/list?document=${document}`, { headers: this.headers });
  }

  getQrcodeByCostumerId(customerId: any) {
    return this.http.get(
      `${this.urlAPI}/qrcode?url=http://www.google.com/`, { headers: this.headers, responseType: 'text' }
    )

  }

  getEnterpriseByOperationalType(operationalType: string): Observable<Enterprise[]> {
    return this.http.get<Enterprise[]>(
      `${this.urlAPI}/enterprise-service/list?operationalType=${operationalType}`, { headers: this.headers });
  }

  getListEnterprises() {
    return this.http.get(`${this.urlAPI}/enterprise-service/list?`, { headers: this.headers });
  }

  getDatasGroupByEnterpriseCode(enterpriseCode: any) {
    const token = sessionStorage.getItem('token');
    if (token) {
      this.headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });
    }
    return this.http.get(
      `${this.urlAPI}/enterprise-service/list?economicGroup=${enterpriseCode}`, { headers: this.headers })
  }

  getAllGroups() {
    const token = sessionStorage.getItem('token');
    if (token) {
      this.headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      });
    }
    return this.http.get(
      `${this.urlAPI}/enterprise-service/list-groups`, { headers: this.headers })
  }
}
