import { QrCodeService } from './../../services/qr-code.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { IbgeDataServiceService } from 'src/app/services/ibge-data-service.service';


@Component({
  selector: 'app-landing-page-baixa',
  templateUrl: './landing-page-baixa.component.html',
  styleUrls: ['./landing-page-baixa.component.scss']
})
export class LandingPageBaixaComponent implements OnInit {

  programString: string = "";
  totalValueString: string = "";
  averageString: string = "";
  userId: string = "";
  enterprise?: Enterprise;
  returnedTrace: TrackableItem[] = [];
  somaPegadaMedia = 0;
  totalEstimate = 0;
  totalValue: number = 0;
  nationalAverage: number = 0;
  biomes: any[] = [];
  namesBiomes: string[] = [];
  preservedArea: number = 0;
  soybeanArea: number = 0;
  biomesDiferents: string[] = [];
  address: string = "";
  corporateName: string = "";
  trackableByHash: any;
  pegada: any;
  volumeTotal: number = 0;
  safra: string = '-';
  verificationDate = "";
  farmList: Array<any> = [];
  providerDocument: string = '';
  regionOrigin: any[] = [];
  cultura: string = '-';
  nomeTalhao: string = '';

  farms: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private qrCodeService: QrCodeService,
    private ibgeDataService: IbgeDataServiceService
  ) { }

  async ngOnInit() {
    let data: any = null;
    this.route.queryParams.subscribe((params: any) => {
      data = params['data'];
      if (data) {
        this.qrCodeService.getQRData(data).subscribe(res => {
          const newData = res.data;
          this.volumeTotal = newData.volumeTotal;
          this.nationalAverage = newData.pegadaMediaGrupo;
          this.pegada = newData.pegada;
          this.safra = newData.harvest;
          this.cultura = newData.cultura;
          this.farmList = newData.farmList;
          this.biomesDiferents = newData.biomes;
          this.verificationDate = newData.verificationDate;
          this.nomeTalhao = newData.nomeTalhao;
          this.programString = parseFloat(this.nationalAverage.toFixed(2)).toLocaleString('pt-BR');
          this.totalValueString = parseFloat(this.totalValue.toFixed(2)).toLocaleString('pt-BR');
          this.farmList.map(farm => {
            if (!this.farms.some(item => item.code === farm.code)){
              this.farms.push(farm);
            }
          });
          this.getAddressByRegistry();
        }, error => {
        })
      }
    });
  }

  getAddressByRegistry() {
    this.farms.map(farm => {
      const car = farm.ruralEnvironmentalRegistry.split('-');
      const id = car[1];
      this.ibgeDataService.getAreaById(id).subscribe(item => {
        if (!this.regionOrigin.some(element => element.id === item.id)) {
          this.regionOrigin.push(item);
        }
      })
    })
  }
}
