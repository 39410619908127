<div class="section-4">
  <div class="text-area">
    <p id="sentence-1">Conheça alguns impactos que programas PRO Carbono tiveram em safras anteriores:</p>
    <img class="img-2" src="../../../assets/images/PRO_commodities_branca.png">

    <span class="harvest-title">
      Safra 22/23
    </span>

    <div class="row-infos">
      <div class="card-green">
        <span>{{10}}</span>
        <span class="card-title">Produtores</span>
      </div>
      <div class="card-green">
          <span>{{2}}</span>
          <span class="card-title">Biomas</span>
      </div>
      <div class="card-green">
        <span>{{"64 mil"}}</span>
        <span class="card-title">Hectares de soja</span>
      </div>
      <div class="card-green">
          <span>{{"4 M"}}</span>
          <span class="card-title">De sacos</span>
      </div>
      <div class="card-green">
        <span>{{"925"}}</span>
        <span class="legend"> Kg CO2eq/ ton soja </span>
        <span class="card-title">Pegada Média do Programa</span>
      </div>
    </div>
  </div>

  <div class="image-area">
    <img class="img-1" src="../../../assets/images/plant.png">
  </div>
</div>
