<div class="content" *ngIf="idValid == true">
  <h1>Rastreabilidade</h1>

  <div class="first-section">
    <span>O que é?</span>
    <p class="paragraph-1">
      Rastreabilidade é habilidade de rastrear um objeto, um conjunto de
      procedimentos que permite checar a origem do produto e acompanhar sua
      movimentação ao longo da cadeia produtiva.
    </p>
    <span> Qual a sua importância? </span>
    <p class="paragraph-2" style="color: rgba(0, 0, 0, 0.6)">
      A rastreabilidade via blockchain promove a transparência e a confiança na
      cadeia de suprimentos agrícolas, e também impulsiona a adoção de práticas
      sustentáveis e responsáveis, beneficiando produtores, consumidores e o
      meio ambiente.
    </p>
  </div>

  <p class="sentence">
    Saiba o que está sendo rastreado no programa PRO Carbono Commodities:
  </p>

  <div class="second-section">
    <div id="section-texts-1" class="section-item">
      <div class="section-img">
        <img src="../../../assets/images/validacao.png" />
        <div class="line"></div>
      </div>
      <br />

      <p class="title-box">
        <span style="color: #00bcff">///</span> Agricultor
      </p>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Dados Agricultor</p>
          <p class="description">
            Nome, documentos, endereço, estado, cidade, país, identificação no
            programa.
          </p>
        </div>
      </div>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Dados da Fazenda</p>
          <p class="description">
            Nome, endereço, talhões, geolocalização, CAR, hectares.
          </p>
        </div>
      </div>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Atendimento aos critérios estabelecidos pelo programa</p>
        </div>
        <div class="tool-tip">
          <img
            src="../../../assets/images/tool-tip.svg"
            alt="Informação adicional"
          />
          <span class="tool-tip-text">
            - Ausência de restrição socioambiental em nome da participante e
            suas áreas;
            <br /><br />
            - Possuir plano de entrada Climate FieldView;
            <br /><br />
            - Está localizado em determinados municípios definidos pelo
            programa;
            <br /><br />
            - Possuir Reserva Legal (Código Florestal de acordo com as regras do
            -Bioma - 80% em Bioma amazônico - 35% cerrado);
            <br /><br />
            - Não ter desmatamento nos últimos 10 anos nos CAR’s
            selecionados.</span
          >
        </div>
      </div>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Proteção de Floresta há 10 anos</p>
        </div>
      </div>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Área plantada (inscrita no programa)</p>
          <p class="description">Estado, cidade, país e geometria do talhão.</p>
        </div>
      </div>
    </div>

    <div id="section-texts-2" class="section-item">
      <div class="section-img">
        <img src="../../../assets/images/analise.png" />
        <div class="line"></div>
      </div>
      <br />

      <p class="title-box">
        <span style="color: #00bcff">///</span> Pegada de Carbono
      </p>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">
            Pegada média do commoditie produzido nas áreas do programa
          </p>
          <p class="description">
            Emissões de campo, Emissões de Mudança de Uso da Terra, Pegada dos
            insumos, Pegada da secagem, Pegada de transporte. Adicionalmente
            também é possível visualizar as pegadas de carbono por escopos 1, 2
            e 3.
          </p>
        </div>
      </div>
    </div>

    <div id="section-texts-3" class="section-item">
      <div class="section-img">
        <img src="../../../assets/images/trator.png" />
        <div class="line"></div>
      </div>
      <br />

      <p class="title-box">
        <span style="color: #00bcff">///</span> Volume colhido e entregue
      </p>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Produtividade</p>
          <p class="description">Volume total colhido</p>
        </div>
      </div>

      <div class="item-topic">
        <img class="img-item-topic" src="../../../assets/images/check.png" />
        <div class="text-topic">
          <p class="topic">Balanço de massa com dados específicos</p>
          <p class="description">
            Volume produzido VS volume entregue na Trader e Saldo disponível.
          </p>
        </div>
      </div>
    </div>
  </div>

  <img id="figure-side" src="../assets/images/figure-side.png" />
</div>

<div *ngIf="isLoading">
  <app-user-not-found [isLoading]="isLoading"></app-user-not-found>
</div>
<div *ngIf="idValid == false && !isLoading">
  <app-user-not-found></app-user-not-found>
</div>
