<div class="section-3">
  <div class="image-area">
    <div class="image-item">
      <img
        src="../../assets/images/full-hd/DSCF7631.jpg"
        class="img-1"
      />
    </div>
    <div class="small-images">
      <div class="image-small">
        <img
          src="../../assets/images/full-hd/DSCF4453.jpg"
          class="img-2"
        />
      </div>
      <div class="image-small">
        <img
          src="../../assets/images/full-hd/DSCF1718.jpg"
          class="img-3"
        />
      </div>
      <div class="image-small">
        <img
          src="../../assets/images/full-hd/DSCF8275.jpg"
          class="img-4"
        />
      </div>
    </div>
  </div>

  <div class="text-area">
    <p id="sentence-1">
      Seguem os dados do produto e como ele está colaborando para a
      sustentabilidade coletiva:
    </p>

    <div class="last-update">
      <svg
        width="10"
        height="9"
        viewBox="0 0 10 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.50037 3.56H6.11037L7.48037 2.15C6.11537 0.8 3.90537 0.75 2.54037 2.1C1.17537 3.455 1.17537 5.64 2.54037 6.995C3.90537 8.35 6.11537 8.35 7.48037 6.995C8.16037 6.325 8.50037 5.54 8.50037 4.55H9.50037C9.50037 5.54 9.06037 6.825 8.18037 7.695C6.42537 9.435 3.57537 9.435 1.82037 7.695C0.0703683 5.96 0.0553683 3.14 1.81037 1.405C3.56537 -0.33 6.38037 -0.33 8.13537 1.405L9.50037 0V3.56ZM5.25037 2.5V4.625L7.00037 5.665L6.64037 6.27L4.50037 5V2.5H5.25037Z"
          fill="#0B3F73"
        />
      </svg>
      <span>
        Última Atualização: {{ verificationDate | date : "dd/MM/yyy" }}</span
      >
    </div>

    <div class="boxes-desktop">
      <div class="box box-1">
        <strong>{{ cultura }}</strong>
        <span>Cultura</span>
      </div>
      <div class="box box-1">
        <strong>{{ harvest }}</strong>
        <span>Ano Safra</span>
      </div>

      <div class="box box-2">
        <strong>{{ totalValue | number : "1.2-2" : "pt-BR" }} t </strong>
        <span>Volume Total</span>
      </div>

      <div class="box box-3">
        <strong>{{ programString }}</strong>
        <span class="medida">kg CO2 eq / t </span>
        <span>Pegada Média do Grupo</span>
      </div>
    </div>

    <p class="obs-text">
      *Todas as informações dessa sessão são referente às áreas do agricultor
      inscritas no programa
    </p>

    <div class="col-texts">
      <div class="white-card">
        <span class="text-title">Região de Origem</span>
        <p *ngFor="let location of regionOrigin">
          {{ location.nome }}<br />{{
            location.microrregiao.mesorregiao.UF.nome
          }}
        </p>
      </div>
      <div class="white-card">
        <span class="text-title">Biomas da Fazenda</span>
        <p *ngFor="let bd of biomesDiferents">{{ bd }}</p>
      </div>
      <div class="white-card">
        <span class="text-title">Nome das Fazendas</span>
        <p *ngFor="let farm of farmList">{{ farm.nickname }}</p>
      </div>
    </div>

    <p id="sentence-2">
      O Agricultor que plantou esse produto e a área onde foi produzido passou
      por uma análise restrita de compliance socioambiental nos seguintes
      critérios:
    </p>
    <p id="sentence-3">
      Condição de trabalho análogo ao escravo, LDI para Doc Oline, Embargos
      SEMA, Embargos ICMBio, Embargos IBAMA Desmatamento, Comunidades
      Quilombolas, Unidade de Conservação, Desmatamento Prodes, Terras
      indígenas, conformidade com o Código Florestal.
    </p>

    <div class="infos-columns">
      <!--<div class="col-lg-5">
                <p id="text-1">Última Verificação Realizada em:<br><span>{{verificationDate | date : 'dd/MM/yyy' }}</span></p>
            </div>-->
      <div>
        <p id="text-2">
          Para verificar os critérios e como eles foram avaliados, acesse os
          detalhes
          <a href="https://www.agro.bayer.com.br/pro-carbono-commodities"
            >aqui.</a
          >
        </p>
      </div>
    </div>

    <div class="logos-area">
      <div class="logo">
        <p class="description-logo">
          Ferramenta codesenvolvida com EMBRAPA através de parceria
          técnico-científica
        </p>
        <img src="../../../assets/images/Embrapa.png" style="width: 90px" />
      </div>
      <div class="logo">
        <p class="description-logo">Auditorias parceiras dos programas:</p>
        <img
          src="../../../assets/images/Bureau Veritas.png"
          style="height: 100px; width: auto"
        />
      </div>
      <!-- <div class="col">
              <p class="description-logo">Plataforma desenvolvida em parceria com:</p>
              <img src="../../../assets/images/logo_horizontal_gradiente_slogan_pt.png" class="logo-3" />
          </div>
          <div class="col">
              <p class="description-logo">Registro das informações em Blockchain:</p>

                  <img src="../../../assets/images/logoSafe 1.png" class="logo-4" />
          </div> -->
    </div>
  </div>

  <div class="carousel-agriculturist-mobile">
    <app-carousel-agriculturist></app-carousel-agriculturist>
  </div>
</div>
