import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrackableItem } from '../interfaces/trackable-item';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityService {

  readonly urlAPI = environment.apiURL;
  private headers = new HttpHeaders();

  constructor(private http: HttpClient) {
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
  }

  getTraceByDocument(document: string): Observable<TrackableItem[]> {
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.get<TrackableItem[]>(`${this.urlAPI}/traceability-service/list/enterprise?document=${document}`, { headers: this.headers });
  }

  getTraceByToken(token: string): Observable<TrackableItem> {
    const tokenAuthorization = sessionStorage.getItem('token');
     if (tokenAuthorization) {
      this.headers = new HttpHeaders({
        Authorization: tokenAuthorization,
      });
    }
    return this.http.get<TrackableItem>(`${this.urlAPI}/movement-service/trace?token=${token}`, { headers: this.headers });
  }

  getTraceByFarm(car: string, document?: string): Observable<TrackableItem[]> {
    let params = new HttpParams()
      .set('car', car)

    if (document) {
      params = params.set('document', document);
    }

    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.get<TrackableItem[]>(`${this.urlAPI}/traceability-service/list/enterprise`, { headers: this.headers, params });
  }


  getTraceByPlaceCode(placeCode: string, economicGroup: string) {
    const params = new HttpParams()
      .set('code', placeCode)
      .set('economicGroup', economicGroup)

    // /traceability-service/list/economic-group?economicGroup=<Grupo>&code=<code>
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.get<TrackableItem[]>(`${this.urlAPI}/traceability-service/list/economic-group`, { headers: this.headers, params });
  }
}


