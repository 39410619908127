<div class="section-5">
    <p id="sentence-1">Conheça alguns dos participantes do programa PRO Carbono Commodities</p>
    <p id="sentence-2">Os responsáveis pela produção dessa soja que busca atributos mais sustentáveis:</p>

    <div class="row-videos">
      <div class="video">
        <img class="video-1" src="../../../assets/images/video.png">
        <p>“O (agricultor} mora na [cidade]. Atualmente fornece X empregos: preserva X hectares de floresta, onde temos também x nascentes e rios.”</p>
      </div>

      <div class="video">
        <img class="video-2" src="../../../assets/images/video.png">
        <p>“O (agricultor} mora na [cidade]. Atualmente fornece X empregos: preserva X hectares de floresta, onde temos também x nascentes e rios.”</p>
      </div>

      <div class="video">
        <img class="video-3" src="../../../assets/images/video.png">
        <p>“O (agricultor} mora na [cidade]. Atualmente fornece X empregos: preserva X hectares de floresta, onde temos também x nascentes e rios.”</p>
      </div>
    </div>

    <div class="carousel-videos-mobile">
        <app-carousel-videos></app-carousel-videos>
    </div>
</div>
