import { EnterpriseService } from './../../services/enterprise.service';
import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { Movement } from 'src/app/interfaces/movement';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { QrCodeService } from 'src/app/services/qr-code.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { urlSite } from 'src/environments/environment';
import { environment } from 'src/environments/environment';

interface farmForGroup {
  customerId: string,
  nameGroup: string;
  producers: Enterprise[]
}

interface itensForPart {
  itemBlockId: string;
  itens: TrackableItem[]
}

interface movementsForDocument {
  document: string;
  movements: Movement[]
}

interface placesSubGroup {
  nameGroup: string;
  placesSub: any[];
}

interface documentsForEnterprise {
  nameGroup: string;
  documents: [];
}

interface totalValueForGroup {
  totalValue: number;
  group: string;
}

@Component({
  selector: 'app-accordions-registro-volume',
  templateUrl: './accordions-registro-volume.component.html',
  styleUrls: ['./accordions-registro-volume.component.scss']
})

export class AccordionsRegistroVolumeComponent implements OnInit, OnChanges {

  @Input() movements: TrackableItem[] = [];
  open: boolean = false;
  urlQrCodeTrader: string = '';
  urlLandingPage: string = "";
  urlQRCODE: string = "";

  totalHarvested: number = 0;

  dataList: Array<{ itemBlockId: string; movements: any[]; trackable: any }> = [];
  isLoading: boolean = false;
  enterprise: any;
  constructor(
    private enterpriseService: EnterpriseService,
    private qrCodeService: QrCodeService,
    private traceabilityService: TraceabilityService,
  ) { }


  ngOnChanges(): void {
    this.getInitialData();
  }
  ngOnInit(): void {
    this.totalHarvested = 0;
    this.movements.forEach(trackable => {
      trackable.movements.forEach(movement => {
        this.totalHarvested = this.totalHarvested + movement.amount;
      })
    });
    this.getInitialData();
  }

  getInitialData() {
    this.urlQrCodeTrader = urlSite;
    this.dataList = [];
    this.movements.forEach((item) => {
      if (this.dataList.some(data => data.itemBlockId === item.itemBlockId)) {
        this.dataList.map(dataItem => {
          if (dataItem.itemBlockId === item.itemBlockId) {
            dataItem.movements.push(item);
          }
        })
      } else {
        this.dataList.push({
          itemBlockId: item.itemBlockId,
          movements: [item],
          trackable: null,
        });
      }
    });

    this.dataList = this.dataList.map(item => {
      let amount = 0;
      item.movements.map(movementItem => {
        movementItem.movements.forEach((movement: any) => {
          amount += movement.amount;
        })
      });


      const trackable = {
        amount: amount,
        itemBlockId: item.itemBlockId,
        talhao: this.getFeatureByName(item.movements[0].item, 'NOME_TALHAO') || this.getFeatureByName(item.movements[0].item, 'FIELD_NAME'),
      };

      return {
        itemBlockId: item.itemBlockId,
        movements: item.movements,
        trackable: trackable,
      }
    });

    const document = this.movements[0].movements[0].provider.document;
    if (document) {
      this.isLoading = true;
      this.enterpriseService.getEnterpriseByDocument(document).subscribe(res => {
        this.enterprise = res[0];
        this.isLoading = false;
        this.getLandingPageData();
      }, error => {
        this.isLoading = false;
      })
    }
  }

  getLandingPageData() {
    let totalValue = 0;
    let farmList: any[] = [];
    let verificationDate = '';
    let cultura = '';
    let harvest = '';
    let biomes: any[] = [];
    let preservedArea = '';
    let soybeanArea = '';
    let nationalAverage = 0;
    let averageEstimate = 0;
    let namesBiomes: any[] = [];
    let qtdBiomes = 0;

    let divider = 0;
    let dividend = 0;

    this.isLoading = false;
    divider = 0;
    dividend = 0;

    this.enterprise.places.map((place: any) => {
      if (place.type === 'FARM') {
        farmList.push({
          nickname: place.nickname,
          code: place.code,
          ruralEnvironmentalRegistry: place.ruralEnvironmentalRegistry,
        });
      }
    })
    totalValue = 0;
    this.isLoading = true;

    this.movements.forEach(movement => {
      movement.item.features.map(item => {
        if (item.name === 'DATA_REGISTRO') {
          verificationDate = item.value;
        }
        if (item.name === 'CULTURA') {
          cultura = item.value;
        }
        if (item.name === 'ANO_SAFRA') {
          harvest = item.value;
        }
        if (item.name === 'BIOMA') {
          if (!biomes.includes(item.value))
            biomes.push(item.value);
        }
        if (item.name === 'AREA_PRESERVADA') {
          preservedArea += parseFloat(item.value);
        }
        if (item.name === 'AREA_SOJA') {
          soybeanArea += parseFloat(item.value);
        }
        if (item.name === 'ANO_SAFRA' && item.value === harvest) {
          totalValue += movement.item.amount;
        }
        if (item.name === 'PEGADA_MEDIA_SOJA_COMUM') {
          averageEstimate = parseFloat(item.value);
        }
      });

      let emissaoCarbono = 0;
      movement.item.features.forEach(feature => {
        if (feature.name === "QTD_TOTAL_EMISSÃO_CARBONO" || feature.name ==='QTD_TOTAL_EMISSÃO_CARBONO') {
          emissaoCarbono = parseFloat(feature.value);
        }
      });
      divider += movement.item.receivedQuantity;
      dividend += (emissaoCarbono * movement.item.receivedQuantity);
      nationalAverage = dividend / divider;
      const pageData = {
        customerId : this.enterprise.customerId,
        cultura: cultura,
        harvest: harvest,
        volumeTotal: totalValue,
        pegadaMediaGrupo: nationalAverage,
        farmList: farmList,
        biomes: biomes,
        verificationDate: verificationDate,
      }

      let token = '';
      this.isLoading = true;
      this.qrCodeService.postQRData({ data: pageData }).subscribe((res : any) => {
        token = res.id;
        this.urlLandingPage = `landing-page?data=${token}`;
        this.urlQRCODE = `${environment.apiURL}/qrcode?url=${this.urlQrCodeTrader}/landing-page?data=${token}`;
        this.isLoading = false;
      }, error => this.isLoading = false);

    });
  }

  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item && item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  getUrlApi() {
    return environment.apiURL;
  }

  getUrl() {
    return urlSite;
  }

  toggleMovementsInfo() {
    this.open = !this.open;
  }

}
