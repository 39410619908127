<ul class="menu" *ngFor="let move of movements; index as i;">
    <li style="border-bottom: solid 1px rgba(0, 0, 0, 0.12);" class="item-recebimento firstItem"
        [id]="move.origin.hash+'-'+i">
        <div class="title-accordion-receipt" (click)="addClass(move.origin.hash+'-'+i)">
            <div class="text" style="padding-left: 1.354vw;">
                <h3 class="text-1-receipt">Identificador/Token Recebimento: {{move?.destiny?.hash}}</h3>
                <h3 class="infos-accordion-receipt" *ngIf="customers[i]?.corporateName">Data do Recebimento:
                    {{move.movementDate | date:'dd/MM/YYYY' }} | Trader: {{customers[i].corporateName}} |
                    Volume recebido no POD:
                    {{getValue(move?.amount)}} ton
                </h3>
            </div>
        </div>

        <div class="text-open-receipt" style="padding-top: 14px;">
            <div style="display: flex;">
                <div class="column-1">
                  <div class="topic">
                    <p class="title-topic">Safra</p>
                    <p class="text-topic">{{ (getFeatureByName(move.destiny.item, 'ANO_SAFRA')) || (move.destiny.item ? getFeatureByName(move.destiny.item.product, 'Ano Safra') : '-') }}</p>
                </div>

                    <div class="topic">
                        <p class="title-topic">Identificador/Token</p>
                        <p class="text-topic">{{ move?.destiny?.hash }}</p>
                    </div>

                    <div class="topic">
                        <p class="title-topic">Nome da Trader</p>
                        <p class="text-topic" *ngIf="customers[i]?.corporateName">{{customers[i].corporateName}}</p>
                    </div>

                    <div class="topic" *ngIf="move.destiny.item">
                        <p class="title-topic">Pegada média</p>
                        <p class="text-topic">{{ ((move.destiny.item | feature: 'QTD_TOTAL_EMISSÃO_CARBONO') | number: '1.0-2': 'pt-BR') || '0' }} kg
                            CO2 eq/t
                        </p>
                    </div>

                    <div class="topic">
                        <p class="title-topic">CAR:</p>
                        <p class="text-topic">{{ getFarmCode(move.provider &&  move.provider ? move.provider.places : [], 'car') }}</p>
                    </div>

                    <div class="topic" id="invoice-number">
                        <div *ngFor="let feature of move.features">
                            <div *ngIf="feature.name == 'INVOICE_NUMBER'">
                                <p class="title-topic">Nota fiscal:</p>
                                <p class="text-topic">{{feature.value}}</p>
                            </div>
                        </div>
                    </div>


                    <div class="topic">
                        <div *ngFor="let feature of move.features">
                            <div *ngIf="feature.name == 'PRODUCER_DOCUMENT'">
                                <p class="title-topic">CNPJ da venda do produtor:</p>
                                <p class="text-topic">{{ feature.value | document}}</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="column-2">
                  <div class="topic">
                    <p class="title-topic"> Cultura </p>
                    <p class="text-topic"> {{ getFeatureByName(move.destiny.item, 'CULTURA') || (move.destiny.item ? getCultureName(move.destiny.item.product.code) : '-') }} </p>
                </div>

                    <div class="topic">
                        <p class="title-topic">Data do recebimento</p>
                        <p class="text-topic">{{move.movementDate | date:'dd/MM/YYYY'}}</p>
                    </div>

                    <div class="topic">
                        <p class="title-topic">CPF/CNPJ da Trader</p>
                        <p class="text-topic">{{formatCNPJorCPF(move.customer.document)}}</p>
                    </div>

                    <div class="topic">
                        <p class="title-topic">Volume recebido no POD</p>
                        <p class="text-topic">{{getValue(move.amount)}} ton</p>
                    </div>

                    <div class="topic" *ngIf="trackable">
                        <p class="title-topic">Talhão</p>
                        <p class="text-topic"> {{ getFeatureByName(trackable.item, 'NOME_TALHAO') || getFeatureByName(trackable.item, 'FIELD_NAME') }} </p>
                    </div>


                    <div class="topic" id="contract-number">
                        <div *ngFor="let feature of move.features">
                            <div *ngIf="feature.name == 'CONTRACT_NUMBER'">
                                <p class="title-topic">Número do contrato de venda do produto</p>
                                <p class="text-topic">{{feature.value}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </li>

    <!--
    <div>
        {{receivedData.item.amount}}
    </div>
    -->
</ul>
