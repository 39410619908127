<div class="content" *ngIf="firstEnterprise">
  <div class="welcome-card">
    <h2>Olá, {{ firstEnterprise.corporateName }}!</h2>
    <p>
      Consulte abaixo as informações da <strong>saída de volume de seu produto</strong> para as Traders.
    </p>
    <button type="button" class="button green" (click)="handleClickExtractData()">
      Acessar dados de extrato
    </button>
  </div>

  <h1>Consulta de Extrato</h1>

  <form [formGroup]="formFilter" class="form-area">
    <div class="input-container">
      <select class="input-search form-input" formControlName="producer"
        [style]="formFilter.get('producer')?.touched &&formFilter.get('producer')?.invalid ? 'borderColor:red' : ''"
        (change)="onChangeProducer()" required>
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let producer of producers" [value]="producer.id">
          {{ producer.corporateName }}
        </option>
      </select>

      <label style="color: #585858" class="form-label"> Produtor </label>
    </div>
    <div class="input-container">
      <select class="input-search form-input" formControlName="farm" [style]="
          formFilter.get('farm')?.touched && formFilter.get('farm')?.invalid ? 'borderColor:red' : ''"
        (change)="onChangeSelect()" required>
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let farm of farms" [value]="farm.code">
          {{ farm.nickname }}
        </option>
      </select>

      <label style="color: #585858" class="form-label"> Fazenda </label>
    </div>
    <div class="input-container">
      <select class="input-search form-input" formControlName="harvest" [style]="
          formFilter.get('harvest')?.touched && formFilter.get('harvest')?.invalid ? 'borderColor:red' : ''"
        (change)="onChangeCropYear()" required>
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let harvest of harvestList" [value]="harvest">
          {{ harvest }}
        </option>
      </select>

      <label style="color: #585858" class="form-label"> Safra </label>
    </div>
    <div class="input-container">
      <select class="input-search form-input" formControlName="culture" [style]="
          formFilter.get('culture')?.touched && formFilter.get('culture')?.invalid ? 'borderColor:red' : ''"
        (change)="onChangeCulture()" required>
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let culture of culturesList" [value]="culture">
          {{ culture }}
        </option>
      </select>

      <label style="color: #585858" class="form-label"> Cultura </label>
    </div>
    <div class="input-container">
      <select class="input-search form-input" formControlName="talhao" [style]="
          formFilter.get('talhao')?.touched && formFilter.get('talhao')?.invalid ? 'borderColor:red' : ''" required>
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let talhao of talhaoList" [value]="talhao">
          {{ talhao }}
        </option>
      </select>

      <label style="color: #585858" class="form-label"> Talhão </label>
    </div>

    <button (click)="cleanFilter()" type="button" class="btn">LIMPAR FILTROS</button>
    <button (click)="submitForm()" style="margin-left: 5px;" type="button" class="btn">CONSULTAR</button>

  </form>

  <div *ngIf="selectedOption && returnedTrace.length > 0 && search">
    <p id="sentence-2">{{ selectedOption.name }}</p>
    <p id="sentence-3">Informações gerais de volume</p>

    <div class="boxes">
      <div class="box" *ngIf="returnedTrace.length > 0">
        <div class="card">
          <h5>Volume total colhido</h5>

          <span class="card-value">{{
            volumeTotal | number : "1.0-2" : "pt-BR"
            }}</span>
          <span class="bottom-text">Toneladas de {{ formFilter.get('culture')?.value }}</span>
        </div>
        <div class="card">
          <h5>Volume total vendido</h5>

          <span class="card-value">{{
            volumeSold | number : "1.0-2" : "pt-BR"
            }}</span>
          <span class="bottom-text">Toneladas de {{ formFilter.get('culture')?.value }}</span>
        </div>

        <div class="card">
          <div class="card-title">
            <h5>Saldo atual de volume</h5>
            <div class="tool-tip">
              <img src="../../../assets/images/tool-tip.svg" alt="Informação adicional" />
              <span class="tool-tip-text" id="text-tool-tip-1">Saldo atual = Volume Colhido - Saída de Volume</span>
            </div>
          </div>
          <span class="card-value">{{
            volumeTotal - volumeSold | number : "1.0-2" : "pt-BR"
            }}</span>
          <span class="bottom-text">Toneladas de {{ formFilter.get('culture')?.value }}</span>
        </div>

        <div class="vertical-line"></div>
        <div class="card">
          <div class="card-title">
            <h5>Estimativa Pegada média</h5>
            <div class="tool-tip">
              <img src="../../../assets/images/tool-tip.svg" alt="Informação adicional" />
              <span class="tool-tip-text" id="text-tool-tip-2">
                Média registrada na fazenda, esse valor varia à medida que novos
                cálculos são gerados para os talhões.
              </span>
            </div>
          </div>
          <span style="color: #64991e" class="card-value">{{
            estimativaPegaMedia | number : "1.0-2" : "pt-BR"
            }}</span>
          <span style="color: #64991e" class="bottom-text">Kg CO2 eq/t {{ formFilter.get('culture')?.value }}</span>
        </div>
      </div>

      <div class="box-qrcode">
        <div class="column-2-box">
          <p>
            PARA VER AS INFORMAÇÕES DA SUA FAZENDA ACESSE O QRCODE AO LADO OU
            <a [href]="urlLandingPage">CLIQUE AQUI</a>
            <br />
            <br />
            <span>*Essas são as informações públicas disponibilizadas para a
              Trader.</span>
          </p>

          <div class="image-qrcode">
            <img [src]="urlQRCODE" alt="QR Code" />
          </div>
        </div>
      </div>
    </div>

    <p id="sentence-4">Consultar saídas de volume</p>

    <div class="buttons-select">
      <div style="display: flex">
        <div class="button" *ngFor="let h of harvest; index as i">
          <p>SAFRA {{ h.value }}</p>
        </div>
      </div>
    </div>

    <app-accordions-saidas-volumes *ngIf="trackableMovementsData.length > 0 && selectedOption"
      [receivedData]="trackableMovementsData"></app-accordions-saidas-volumes>
  </div>

  <!-- Se nao tiver encontrado nenhum item na fazenda -->

  <div *ngIf="!findMovementFlag">
    <p>Nenhum item encontrado para essa fazenda.</p>
  </div>
</div>

<div *ngIf="!firstEnterprise && !isLoading">
  <app-user-not-found></app-user-not-found>
</div>

<app-spinner *ngIf="isLoading"></app-spinner>