<div class="talhoes-title" *ngIf="movements.length > 0">
  <ul>
    <li>
      Talhão: {{ getTalhaoName(talhao) }} | Token do Talhão: {{ talhao.hash }}
    </li>
  </ul>
</div>
<div class="talhao-item" *ngFor="let movement of movements">
  <h5 style="font-size: 14px;">Data do recebimento: {{ movement.movementDate | date : 'dd/MM/yyy'  }}</h5>
  <table>
    <tr>
      <th>Token da Baixa</th>
      <td>{{ movement.destiny ? movement.destiny.hash : '-' }}</td>
    </tr>
    <tr>
      <th>Volume recebido</th>
      <td>{{ movement.amount ? movement.amount.toFixed(2).replace('.', ',') :  '0' }} ton</td>
    </tr>
    <tr>
      <th>CAR</th>
      <td>{{ movement.provider && movement.provider.places ? movement.provider.places[0].code : '-' }}</td>
    </tr>
    <tr>
      <th>CNPJ da venda do produtor</th>
      <td>{{ movement.provider && movement.provider.document ? movement.provider.document : '-' }}</td>
    </tr>
    <tr>
      <th>Número do contrato de venda do produto</th>
      <td>{{ getContractNumber(movement) }}</td>
    </tr>
    <tr>
      <th>Notas Fiscais</th>
      <td>{{ getInvoices(movement) }}</td>
    </tr>
  </table>
</div>
