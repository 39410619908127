import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { RegistroComprasComponent } from './registro-compras.component';
import { BrMaskerModule } from 'br-mask';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RegistroComprasComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrMaskerModule,
    CommonModule,
    ComponentsModule,
  ]
})
export class RegistroComprasModule { }
