import { Component, OnInit, Input } from '@angular/core';
import { IbgeDataServiceService } from 'src/app/services/ibge-data-service.service';

@Component({
  selector: 'app-third-section-landing',
  templateUrl: './third-section-landing.component.html',
  styleUrls: ['./third-section-landing.component.scss']
})
export class ThirdSectionLandingComponent implements OnInit {

  @Input() averageEstimate: any;
  @Input() totalValue: number = 0;
  @Input() harvest: string = "";
  @Input() nationalAverage: number = 0;
  @Input() biomesDiferents?: string[];
  @Input() verificationDate: string = "";
  @Input() farmList: Array<any> = [];
  @Input() cultura: string = '-';

  programString: string = "";
  totalValueString: string = "";
  averageString: string = "";
  regionOrigin: Array<any> = [];

  constructor (
    private ibgeDataService: IbgeDataServiceService
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    this.getAddressByRegistry();
    this.programString = parseFloat(this.nationalAverage.toFixed(2)).toLocaleString('pt-BR');
    this.totalValueString = parseFloat(this.totalValue.toFixed(2)).toLocaleString('pt-BR');
    this.averageString = parseFloat(this.averageEstimate.toFixed(2)).toLocaleString('pt-BR');
  }

  getAddressByRegistry() {
    this.farmList.map(farm => {
      const car = farm.ruralEnvironmentalRegistry.split('-');
      const id = car[1];
      this.ibgeDataService.getAreaById(id).subscribe(item => {
        this.regionOrigin.push(item);
      })
    })
  }

}
