<div *ngIf="!landingPage && !login">
  <div class="header" style="z-index: 5;">
    <app-header></app-header>
  </div>

  <div class="header-mobile" style="z-index: 5;">
    <app-header-mobile></app-header-mobile>
  </div>

  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>

  <div class="body-page">
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="landingPage" class="landing-page">
  <div class="header-mobile">
    <app-header-landing-mobile></app-header-landing-mobile>
  </div>

  <router-outlet></router-outlet>
</div>

<div *ngIf="login">
  <router-outlet></router-outlet>
</div>