import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { MovementService } from 'src/app/services/movement.service';

@Component({
  selector: 'app-talhao',
  templateUrl: './talhao.component.html',
  styleUrls: ['./talhao.component.scss']
})
export class TalhaoComponent implements OnInit {
  @Input() talhao!: TrackableItem;
  sellingNumber: string = '';
  movements: any[] = [];

  @Output() somaTotalBaixado = new EventEmitter<number>();
  constructor(
    private movementService: MovementService,
  ) { }

  ngOnInit(): void {
    this.movementService.getTraceByHash(this.talhao.hash).subscribe(res => {
      this.sellingNumber = res.item.hash;
      if (res.movements) {
        this.movements = res.movements;
        this.movements.forEach(movement => {
          this.somaTotalBaixado.emit(movement.amount);
        });

        this.movements = this.movements.sort(function compararPorData(a, b) {
          const dataA = Date.parse(a.movementDate);
          const dataB = Date.parse(b.movementDate);

          if (dataA < dataB) {
            return -1;
          } else if (dataA > dataB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    });
  }


  getTalhaoName(talhao: any): string {
    let feature = '';
    talhao.item.features.find((item: any) => {
      if (item.name === 'FIELD_NAME') {
        feature = item.value;
      }
    });
    return feature;
  }

  getInvoices(movement: any) {
    let invoice = '-'
    if (movement.features) {
      movement.features.map((feature: any) => {
        if (feature.name === 'INVOICE_NUMBER') {
          invoice = feature.value;
        }
      });
    }
    return invoice;
  }

  getContractNumber(movement: any) {
    let contractNumber = '-'
    if (movement.features) {
      movement.features.map((feature: any) => {
        if (feature.name === 'CONTRACT_NUMBER') {
          contractNumber = feature.value;
        }
      });
    }
    return contractNumber;
  }

}
