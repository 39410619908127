<ul class="menu" *ngFor="let rec of receivedData;  index as i;">
    <li class="item firstItem" [id]="i" *ngIf="rec.movements.length > 0">
        <div class="title-accordion" (click)="addClassOpen(i)">
            <div class="text">
                <h3 class="text-1">Identificador/Token Talhão: {{ rec.trackable.itemBlockId }} </h3>
                <div class="infos-accordion" style="display:flex">
                    <p>Volume total colhido: {{ getValue(rec.trackable.item.receivedQuantity) }} ton |
                      Safra: {{ (getFeatureByName(rec.trackable.item, 'ANO_SAFRA')) || (getFeatureByName(rec.trackable.item.product, 'Ano Safra')) }} |
                      Cultura: {{ getFeatureByName(rec.trackable.item, 'CULTURA') || getCultureName(rec.trackable.item.product.code) }} |
                      Talhão: {{ getFeatureByName(rec.trackable.item, 'NOME_TALHAO') ||  getFeatureByName(rec.trackable.item, 'FIELD_NAME') }}
                    </p>
                    <!-- <div *ngFor="let feature of rec.trackable.item.features">
                        <p *ngIf="feature.name == 'FIELD_NAME'">&nbsp;| Talhão: {{feature.value}}</p>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="text-open">
            <app-accordion-recebimento [movements]="rec.movements" [customers]="rec.customers"
                [trackable]="rec.trackable"></app-accordion-recebimento>
        </div>
    </li>
</ul>
