import { Component, OnInit, Input } from '@angular/core';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { Movement } from 'src/app/interfaces/movement';
import { TrackableMovements } from 'src/app/interfaces/trackablemovements';

@Component({
  selector: 'app-accordions-saidas-volumes',
  templateUrl: './accordions-saidas-volumes.component.html',
  styleUrls: ['./accordions-saidas-volumes.component.scss']
})
export class AccordionsSaidasVolumesComponent implements OnInit {

  @Input() receivedData: TrackableMovements[] = [];


  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }


  addClassOpen(i: number) {
    const item = document.getElementById(`${i}`);
    if (item?.classList.contains('open')) {
      item?.classList.remove('open');
    }
    else {
      item?.classList.add('open');
    }
  }

  getValue(value: any) {
    let newValue = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);

    return newValue;
  }

  getCultureName(productCode: string): string {
    let culture = '-'
    if (productCode) {
      const splittedCode = productCode.split('-');
      culture = splittedCode[0];
    }
    return culture;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  formatCNPJorCPF(cpforcnpj: string): string {
    if (cpforcnpj.length == 14) {
      // Remove caracteres não numéricos
      cpforcnpj = cpforcnpj.replace(/[^\d]/g, '');

      // Adiciona zeros à esquerda, se necessário
      while (cpforcnpj.length < 14) {
        cpforcnpj = '0' + cpforcnpj;
      }

      // Adiciona pontos e traços
      cpforcnpj = cpforcnpj.replace(/(\d{2})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1/$2');
      cpforcnpj = cpforcnpj.replace(/(\d{4})(\d)/, '$1-$2');

      return cpforcnpj;
    } else {
      // Remove caracteres não numéricos
      cpforcnpj = cpforcnpj.replace(/[^\d]/g, '');

      // Adiciona zeros à esquerda, se necessário
      while (cpforcnpj.length < 11) {
        cpforcnpj = '0' + cpforcnpj;
      }

      // Adiciona pontos e traços
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d)/, '$1.$2');
      cpforcnpj = cpforcnpj.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

      return cpforcnpj;
    }
  }

}
