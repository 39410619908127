<div class="section-2">
    <p class="sentence-1-second-section">
      Abaixo, as etapas que possibilitam avaliar a origem do produto e o seu caráter sustentável
    </p>

    <ul class="sequence">
        <li class="sequence-item">
            <img class="img-1" src="../../../assets/images/landing-page/step-1-pre-cadastro.svg">
            <span>
              Pré-cadastro
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
        </li>
        <li>
            <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-2" src="../../../assets/images/landing-page/step-2-compliance.svg">
            <span>
              Compliance Socioambiental
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
        </li>
        <li>
            <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-3" src="../../../assets/images/landing-page/step-3-inscricao.svg">
            <span>
              Inscrição
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
        </li>
        <li>
            <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-5" src="../../../assets/images/landing-page/step-4-inventariamento.svg">
            <span>
              Inventariamento das operações agrícolas
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
            <span class="span-min"> Plantio, manejo, colheita e transporte</span>
        </li>
        <li>
            <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-6" src="../../../assets/images/landing-page/step-5-qualidade-dados.svg">
            <span>
              Qualidade de dados
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
        </li>
        <li>
            <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-8" src="../../../assets/images/landing-page/step-6-mediacao.svg">
            <span>
              Medição da pegada de carbono do grão
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
            <span class="span-min"> Pré plantio até a trader</span>
        </li>
        <li>
          <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-9" src="../../../assets/images/landing-page/step-7-registro.svg">
            <span>
              Registro do volume total colhido
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
        </li>
        <li>
          <img class="line" src="../../../assets/images/landing-page/line.svg">
        </li>
        <li class="sequence-item">
            <img class="img-9" src="../../../assets/images/landing-page/step-8-rastreabilidade.svg">
            <span>
              Rastreabilidade
              <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
            </span>
        </li>
    </ul>

    <div class="carousel-mobile">
        <app-carousel-icons-landing></app-carousel-icons-landing>
    </div>

    <p class="sentence-2-second-section">
      Esse método é vinculado a um código identificador (Blockchain): <strong>que busca garantir um processo rastreado.</strong><br/>
      Além disso, o volume do produto entregue é controlado por balanço de massa com dados específicos do agricultor.
    </p>

</div>
