<div>
  <img class="image-background" src="./../../../assets/images/Image-complete.png">
  <img class="image-background-filter" src="./../../../assets/images/Image-complete.png">
  <img class="image-green" src="./../../../assets/images/lateral-verde.png">
  <div id="div-diagonal"></div>
</div>

<div class="text-welcome">
  <p class="text-1 text">Bem-vindo ao</p>
  <div class="itens-title">
      <div id="title">
          <img src="./../../../assets/images/pro-carbono-conecta.png">
      </div>
      <div style="display: flex;align-items: center;padding-left: 10px;">
          <img class="img-logo" src="./../../../assets/images/Logo.png">
      </div>
  </div>
  <p class="text-3 text">Acompanhe a<br><span>RASTREABILIDADE</span><br>do produto.</p>
</div>

<div class="box-login">
  <img src="../../../assets/images/ICON_WHITE_17 1.png">
  <h1>Login no PRO Carbono Conecta</h1>
  <!--<p style="font-size: 8px;font-family: Roboto;line-height: 150%;letter-spacing: 0.25px;color:red">E-mail e/ou senha
      incorretos.</p>-->
  <form [formGroup]="formLogin">
      <input formControlName="email" class="input-email form-control"
          [ngClass]="{'is-valid': formLogin.valid, 'is-invalid':!formLogin.valid}" type="text" placeholder="E-mail">
      <input formControlName="password" class="input-password form-control"
          [ngClass]="{'is-valid': formLogin.valid, 'is-invalid':!formLogin.valid}" type="password"
          placeholder="Senha">

      <div style="display:flex;justify-content: right;overflow-y: hidden; margin-top: 16px;">
          <button class="btn-login" (click)="submitForm()">Entrar</button>
      </div>
  </form>
</div>


<div *ngIf="modalOpen" class="modal-user modal-open">
  <app-modal-type-user (dadosEnviados)="changeModal($event)"></app-modal-type-user>
</div>


<div *ngIf="modalProducer" class="modal-producer">
  <app-modal-producer [producers]="producers" (close)="close($event)"></app-modal-producer>
</div>

<div *ngIf="modalTrader">
  <app-modal-trader [traders]="traders" (closeTrader)="close($event)"></app-modal-trader>
</div>

<app-spinner *ngIf="loading"></app-spinner>
