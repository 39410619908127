import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageBaixaComponent } from './landing-page-baixa.component';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
    declarations: [
      LandingPageBaixaComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule
    ]
})

export class LandingPageBaixaModule { }

















