import { Component, OnInit } from '@angular/core';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-trader-copy',
  templateUrl: './trader-copy.component.html',
  styleUrls: ['./trader-copy.component.scss']
})
export class TraderCopyComponent implements OnInit {

  width: number = 0;

  trackableItems!: TrackableItem[];

  trackableItem?: TrackableItem;

  formTokenTrackableItem !: FormGroup;

  enterprise?: Enterprise;

  formEnterprise !: FormGroup;

  selectAnoSafra: string[] = [];

  showSelectAnoSafra = false;

  selectIdentificadorToken: string[] = [];

  showSelectIdentificadorToken = false;

  showContentFlag: any;

  findTraceabilityFlag: any;

  traceabilityfind = false;

  customerId!: string;

  constructor(private traceabilityService: TraceabilityService, private enterpriseService: EnterpriseService, private route: ActivatedRoute) {
      this.formEnterprise = new FormGroup({
        document: new FormControl('', [Validators.required]),
        token: new FormControl('', [Validators.required])
      });
  }




  async ngOnInit() {
    this.showContentFlag = false;
  }

  findTraceability(){
    this.traceabilityService.getTraceByDocument(this.formEnterprise.get('document')?.value).subscribe(item => {
      this.trackableItems = item;
      this.showSelectAnoSafra = true;
      this.selectAnoSafra = []; //reset
      this.selectIdentificadorToken = []; //reset

      this.trackableItems.forEach(item => {
        if (typeof item.item.product.features[1].value === 'string') {
          const value = item.item.product.features[1].value;
          if (!this.selectAnoSafra.includes(value)) {
            this.selectAnoSafra.push(value);
          }
        }

        if (typeof item.hash === 'string') {
          const value = item.hash;
          if (!this.selectIdentificadorToken.includes(value)) {
            this.selectIdentificadorToken.push(value);
          }
        }
      });
      this.findTraceabilityFlag = true;
    }, error => {
      this.showContentFlag = false;
      this.showSelectAnoSafra = false;
      this.showSelectIdentificadorToken = false;
      this.selectAnoSafra = [];
      this.selectIdentificadorToken = [];
      this.trackableItem = undefined;
      this.enterprise = undefined;
      this.findTraceabilityFlag = false;
    });
  }

  onAnoSafraChange(){
    this.showSelectIdentificadorToken = true;
  }

  // 80183050991
findByTokenAndAnoSafra(){
    this.traceabilityService.getTraceByToken(this.formEnterprise.get('token')?.value).subscribe(item => {
      this.trackableItem = item;
          this.showContentFlag = true;

      this.enterpriseService.getEnterpriseByDocument(this.trackableItem.item.enterprise).subscribe(enterprise => {
        this.enterprise = enterprise[0];

        let width = window.screen.width;

        if (width > 960) {
          google.charts.load("current", { packages: ["corechart"] })
          google.charts.setOnLoadCallback(this.drawChart);
        }
        else {
          google.charts.load("current", { packages: ["corechart"] });
          google.charts.setOnLoadCallback(this.drawChartMobile);
        }
      });
    }, error => {
    });
  }

  async drawChart() {
    var data = google.visualization.arrayToDataTable([
      ['Task', 'Hours per Day'],
      ['3724.65Kg C02 eq/t Insumo', 3724.6518436864094],
      ['333.36Kg C02 eq/t Operação', 333.3678152863018],
      ['7.37Kg C02 eq/t Transporte', 7.3772160054338511],
      ['1160.90Kg C02 eq/t MUT', 1160.8985779576442],
    ]);

    var options = {
      title: 'Total de Emissão de carbono',
      pieSliceText: 'none',
      pieHole: 0.7,
      width: 500,
      height: 240,
      colors: ['#ADE9FF', '#33C9FF', '#0087B8', '#004B66'],
      responsive: true
    };
    var chart = new google.visualization.PieChart(document.getElementById('chart'));
    chart.draw(data, options);
  }

  async drawChartMobile() {
    var data = google.visualization.arrayToDataTable([
      ['Task', 'Hours per Day'],
      ['10Kg C02 eq/t Insumo', 10],
      ['35Kg C02 eq/t Operação', 35],
      ['30Kg C02 eq/t Transporte', 30],
      ['25Kg C02 eq/t MUT', 25],
    ]);

    var options = {
      title: 'Total de Emissão de carbono',
      pieSliceText: 'none',
      pieHole: 0.7,
      width: 330,
      height: 240,
      colors: ['#ADE9FF', '#33C9FF', '#0087B8', '#004B66'],
      responsive: true
    };



    var chart = await new google.visualization.PieChart(document.getElementById('chart'));
    await chart.draw(data, options);
  }
}
