<div class="container">
    <div class="carousel">
        <input type="radio" id="carousel-1" name="carousel[]" checked>
        <input type="radio" id="carousel-2" name="carousel[]">
        <input type="radio" id="carousel-3" name="carousel[]">

        <ul class="carousel__items">
            <li class="carousel__item">
                <div style="display:flex;justify-content: center;align-items: center;">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-1-pre-cadastro.svg" alt="">
                    <span>
                      Pré-cadastro
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                  </div>
                  <img class="line" src="../../../assets/images/landing-page/line.svg">
                  <div class="card-item">
                    <img class="img-2" src="../../../assets/images/landing-page/step-2-compliance.svg" alt="">
                    <span>
                      Compliance Socioambiental
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                  </div>
                  <img class="line" src="../../../assets/images/landing-page/line.svg">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-3-inscricao.svg" alt="">
                    <span>
                      Inscrição
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                  </div>
                </div>
            </li>
            <li class="carousel__item">
                <div style="display:flex;justify-content: center;align-items: center;">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-4-inventariamento.svg" alt="">
                    <span>
                      Inventariamento das operações agrícolas
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                    <span class="span-min"> Plantio, manejo, colheita e transporte</span>
                  </div>
                  <img class="line" src="../../../assets/images/landing-page/line.svg">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-5-qualidade-dados.svg" alt="">
                    <span>
                      Qualidade de dados
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                  </div>
                  <img class="line" src="../../../assets/images/landing-page/line.svg">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-6-mediacao.svg" alt="">
                    <span>
                      Medição da pegada de carbono do grão
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                    <span class="span-min"> Pré plantio até a trader</span>
                  </div>
                </div>
            </li>
            <li class="carousel__item">
                <div style="display:flex;justify-content: center;align-items: center;">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-7-registro.svg" alt="">
                    <span>
                      Registro do volume total colhido
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                  </div>
                  <img class="line" src="../../../assets/images/landing-page/line.svg">
                  <div class="card-item">
                    <img class="img-1" src="../../../assets/images/landing-page/step-8-rastreabilidade.svg" alt="">
                    <span>
                      Rastreabilidade
                      <img class="icon" src="../../../assets/images/landing-page/icon-arrow.svg">
                    </span>
                  </div>
                </div>
            </li>
        </ul>

        <div class="carousel__prev">
            <label for="carousel-1"></label>
            <label for="carousel-2"></label>
            <label for="carousel-3"></label>
        </div>

        <div class="carousel__next">
            <label for="carousel-1"></label>
            <label for="carousel-2"></label>
            <label for="carousel-3"></label>
        </div>

        <div class="carousel__nav">
            <label for="carousel-1"></label>
            <label for="carousel-2"></label>
            <label for="carousel-3"></label>
        </div>
    </div>
</div>
