import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-landing-page',
  templateUrl: './header-landing-page.component.html',
  styleUrls: ['./header-landing-page.component.scss']
})

export class HeaderLandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
