import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-producer',
  templateUrl: './modal-producer.component.html',
  styleUrls: ['./modal-producer.component.scss']
})
export class ModalProducerComponent implements OnInit {

  formProducer: FormGroup;

  @Input() producers: any;
  @Output() close = new EventEmitter<string>();

  constructor(public fb: FormBuilder,
    private router: Router) {
    this.formProducer = fb.group({
      producer: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {

  }

  toPageProducer() {
    const document = this.formProducer.get('producer')?.value;
    const producerSelected = this.producers.filter((producer: any) => producer.document === document);
    sessionStorage.setItem("user", "userRoot");
    sessionStorage.removeItem('customerId');
    setTimeout(() => {
      this.router.navigate(
        ['/produtor'],
        { queryParams: { customerId: producerSelected[0].customerId } }
      );
      this.enviarDados('fechar');
    }, 500);
    this.router.navigate(['/produtor-inicio']);
  }

  enviarDados(event: string) {
    this.close.emit(event);
  }
}
