import { Component, OnInit, Input } from '@angular/core';
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { Movement } from 'src/app/interfaces/movement';
import { Enterprise } from 'src/app/interfaces/enterprise';
import { MovementEnterprise } from 'src/app/interfaces/movemententerprise';

interface itensForPart {
  itemBlockId: string;
  itens: TrackableItem[]
}

interface placesSubGroup {
  nameGroup: string;
  placesSub: [];
}

@Component({
  selector: 'app-accordion-talhao',
  templateUrl: './accordion-talhao.component.html',
  styleUrls: ['./accordion-talhao.component.scss']
})

export class AccordionTalhaoComponent implements OnInit {
  @Input() baixas: Movement[] = [];
  @Input() trackable: any;
  openMovements: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.openMovements = false;
    this.baixas = this.baixas.sort(function compararPorData(a, b) {
      const dataA = Date.parse(a.movementDate);
      const dataB = Date.parse(b.movementDate);

      if (dataA < dataB) {
        return -1;
      } else if (dataA > dataB) {
        return 1;
      } else {
        return 0;
      }
    });
    this.baixas = this.baixas.reverse();
  }

  ngOnChanges() {
  }

  toggleOpenMovements() {
    this.openMovements = !this.openMovements;
  }

}
