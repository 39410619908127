<div class="header-traceability-report">
  <div class="banner-traceability-report">
    <h1>Consulta Laudo de Rastreabilidade</h1>
    <p>
      Selecione no <strong>filtro abaixo</strong> o produtor que você
      <strong>deseja emitir o laudo de rastreabilidade</strong>
    </p>
  </div>
  <div class="btn-area">
    <form
      style="
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
      "
      [formGroup]="form"
    >
      <div class="input-container">
        <select
          class="input-search form-input"
          [ngClass]="form.get('producer')?.invalid && form.get('producer')?.touched ? 'input-search form-input invalid' : 'input-search form-input'"
          (change)="selectProducer($event)"
          formControlName="producer"
          required
        >
          <option value="" [selected]="true" disabled></option>
          <option *ngFor="let producer of producers" [value]="producer.id">
            {{ producer.corporateName }}
          </option>
        </select>

        <label style="color: #585858" for="producer" class="form-label">
          Selecione o Produtor:
        </label>
      </div>

      <div class="input-container">
        <select
          class="input-search form-input"
          [ngClass]="form.get('farm')?.invalid && form.get('farm')?.touched ? 'input-search form-input invalid' : 'input-search form-input'"
          (change)="selectFarm($event)"
          formControlName="farm"
          required
        >
          <option value="" [selected]="true" disabled></option>
          <option *ngFor="let fazenda of farm" [value]="fazenda.hash">
            {{ fazenda.nickname }}
          </option>
        </select>

        <label style="color: #585858" class="form-label">
          Selecione a Fazenda:
        </label>
      </div>
      <div class="input-container">
        <select
          class="input-search form-input"
          (change)="selectCulture()"
          formControlName="culture"
          [ngClass]="form.get('culture')?.invalid && form.get('culture')?.touched ? 'input-search form-input invalid' : 'input-search form-input'"
          name="culture"
          required
        >
          <option value="" [selected]="true" disabled></option>
          <option *ngFor="let culture of culturesList" [value]="culture">
            {{ culture }}
          </option>
        </select>
        <label style="color: #585858" for="culture" class="form-label">
          Selecione a Cultura:
        </label>
      </div>
      <div class="input-container">
        <select
          class="input-search form-input"
          (change)="selectHarvest()"
          formControlName="harvest"
          [ngClass]="form.get('harvest')?.invalid && form.get('harvest')?.touched ? 'input-search form-input invalid' : 'input-search form-input'"
          name="harvest"
          required
        >
          <option value="" [selected]="true" disabled></option>
          <option *ngFor="let harvest of harvestsList" [value]="harvest">
            {{ harvest }}
          </option>
        </select>

        <label style="color: #585858" for="harvest" class="form-label">
          Selecione a Safra:
        </label>
      </div>
      <button
      type="submit"
      class="print-button"
      (click)="formSubmit()"
    >
      Buscar
    </button>
    </form>
    <button
      type="button"
      class="print-button"
      (click)="exportPDF()"
      *ngIf="showReport && selectedProducer"
    >
      Imprimir
    </button>
  </div>
</div>

<div
  class="traceability-report-content"
  id="traceability-report-content"
  *ngIf="showReport && selectedProducer"
>
  <div class="report" #report id="report">
    <div class="header-area">
      <div class="header-title">
        <img
          src="../../../assets/images/logo-pro-carbono-green.png"
          alt="Logo Pro Carbono"
        />
      </div>
      <div class="logos">
        <img src="../../../assets/images/logo-embrapa.png" alt="Logo Embrapa" />
        <img src="../../../assets/images/Bayer.png" alt="Logo Bayer" />
        <img
          src="../../../assets/images/logoSafe 1.png"
          alt="Logo Safe Trace"
        />
      </div>
    </div>
    <div class="content-area">
      <h1>/// Laudo de Rastreabilidade</h1>
      <span class="emitted-date"
        >Emitido em: {{ emittedDate | date : "dd/MM/yyyy" }} às
        {{ emittedDate | date : "HH:mm" }}
      </span>

      <section class="objetivo">
        <h2>1. Objetivo</h2>
        <div class="div-content">
          <p>
            O objetivo principal é rastrear o produto produzido da fazenda
            participante dos Programas PRO Carbono até a entrega à trader. Para
            isso serão registrados os seguintes assets em blockchain:
          </p>
          <ul>
            <li>Dados do produtor</li>
            <li>Dados da fazenda</li>
            <li>Dados de compliance socioambiental</li>
            <li>
              Dados da colheita e da mensuração de pegada de carbono por talhão
              (footprint)
            </li>
            <li>
              Dados do recebimento do volume pela trader (Baixa do volume)
            </li>
          </ul>
          <p>
            Abaixo, seguem as etapas do Programa que possibilitam avaliar a
            origem da commodity e o seu caráter sustentável
          </p>
          <img
            src="../../../assets/images/laudo/image-1.png"
            alt=""
            style="max-width: 100%"
          />
          <img
            src="../../../assets/images/laudo/image-2.png"
            alt=""
            style="max-width: 100%"
          />
          <p>
            Esse método é vinculado a um código identificador (Blockchain) que
            busca garantir um processo rastreado. Além disso, o volume de
            produto entregue é controlado por balanço de massa com dados
            específicos do agricultor.
          </p>
        </div>
      </section>

      <section class="identification">
        <h2>2. Identificação</h2>
        <div class="div-content">
          <table *ngFor="let fazenda of farm">
            <tr>
              <th>Grupo</th>
              <td>{{ selectedProducer.economicGroup }}</td>
            </tr>
            <tr>
              <th>Cultura</th>
              <td>{{ form.get("culture")?.value || "-" }}</td>
            </tr>
            <tr>
              <th>Fazenda</th>
              <td>{{ fazenda.nickname }}</td>
            </tr>
            <tr>
              <th>Ano Safra</th>
              <td>{{ form.get("harvest")?.value || "-" }}</td>
            </tr>
            <tr>
              <th>Estado</th>
              <td>{{ selectedCar?.microrregiao?.mesorregiao['UF']?.nome || '-' }}</td>
            </tr>
            <tr>
              <th>Município</th>
              <td>{{ selectedCar?.nome || '-' }}</td>
            </tr>
          </table>
        </div>
      </section>

      <section class="average-footprint">
        <h2>3. Pegada Média da Fazenda</h2>
        <div class="div-content">
          <p>
            A pegada média é composta pelas pegadas individuais por talhão,
            seguindo a seguinte fórmula:
          </p>
          <div class="formula">
            <img src="../../../assets/images/formula.svg" alt="" />
            <span>
              (a emissão e a produção é de cada talhão envolvido no
              cálculo)</span
            >
          </div>
          <div class="cards">
            <div class="card">
              <p class="media-label">(kg CO2eq/ t produto)</p>
              <strong>{{ nationalAverage | number: '1.0-2': 'pt-BR' }}</strong>
            </div>
            <div class="card emitted">
              <table>
                <tr>
                  <th>Emissões de mudança de uso da terra</th>
                  <td>{{ operacao ? (operacao | number: '1.0-2': 'pt-BR') : "0" }}</td>
                </tr>
                <tr>
                  <th>Emissões do campo</th>
                  <td>{{ mut ? (mut | number: '1.0-2': 'pt-BR') : "0" }}</td>
                </tr>
                <tr>
                  <th>Pegada de carbono dos insumos</th>
                  <td>{{ insumo ? (insumo | number: '1.0-2': 'pt-BR') : "0" }}</td>
                </tr>
                <tr>
                  <th>Pegada de carbono de transporte</th>
                  <td>{{ transporte ? (transporte | number: '1.0-2': 'pt-BR') : "0" }}</td>
                </tr>
                <tr>
                  <th>Pegada de carbono de secagem</th>
                  <td>{{ secagem ? (secagem | number: '1.0-2': 'pt-BR') : "0" }}</td>
                </tr>
              </table>
            </div>
          </div>
          <p class="formula-description">
            Pegada de carbono total = Emissões de campo + Emissões de mudança de
            uso da terra + Pegada de carbono dos insumos + Pegada de carbono de
            transporte + Pegada de carbono de secagem
          </p>
          <!-- <p>// Contribuição de cada etapa</p> -->
          <!-- <div class="cards">
            <div class="card" style="width: 50%">
              <p style="font-size: 12px">
                A pegada de carbono total é composta pelos resultados de
                emissões do campo, emissões de mudança de uso da terra (MUT),
                pela pegada de carbono dos insumos e pela pegada de carbono de
                transporte.
              </p>
              <ul style="font-size: 12px">
                <li>
                  <strong>Emissões de campo:</strong> emissões da aplicação dos
                  insumos agrícolas, como corretivos e fertilizantes, e da
                  combustão do diesel pelas máquinas agrícolas.
                </li>
                <li>
                  <strong>Emissões de MUT:</strong> emissões da Mudança de Uso
                  da Terra, relacionadas à mudança de estoques dos usos atual e
                  há 20 anos.
                </li>
                <li>
                  <strong>Pegada dos insumos:</strong> emissões desde a extração
                  da matéria-prima até a fabricação.
                </li>
                <li>
                  <strong>Pegada de transporte:</strong> emissões da fase de
                  transporte da fazenda à trader.
                </li>
              </ul>
            </div>
            <div class="card" style="width: auto; padding: 0 32px">
              <app-graph
                *ngIf="flagGraph"
                [carbono]="carbono"
                [transporte]="transporte"
                [insumo]="insumo"
                [operacao]="operacao"
                [mut]="mut"
                [tooltip]="false"
                [hole]="0"
              ></app-graph>
            </div>
          </div> -->
        </div>
      </section>

      <div class="volume-register">
        <h2>4. Informações do Registro de Volume</h2>
        <div class="div-content">
          <p>
            Abaixo estão os registros referentes aos talhões do produtor
            submetidos à jornada ilustrada no item 1 acima e que possuem seu
            asset de produto registrado em blockchain (token).
          </p>

          <div class="volume-register-card">
            <div class="volume-item">
              <span>Volume total colhido</span>
              <p>(Toneladas de Produto)</p>
              <strong>{{ totalValue | number: '1.2-2': 'pt-BR' }}</strong>
            </div>
            <span>=</span>
            <div class="volume-item">
              <span>Volume total baixado</span>
              <p>(Toneladas de Produto)</p>
              <strong>{{ volumeSold | number: '1.2-2': 'pt-BR' }}</strong>
            </div>
            <span>+</span>
            <div class="volume-item">
              <span>Saldo atual de volume</span>
              <p>(Toneladas de Produto)</p>
              <strong>{{ saldoAtual | number: '1.2-2': 'pt-BR' }}</strong>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Ano/Safra</th>
                <th>Responsável pela fazenda</th>
                <th>Fazenda</th>
                <th>Talhão do programa</th>
                <th>Saldo Disponível (ton)</th>
                <th>Pegada Talhão (kg CO2 eq/t)</th>
                <th>Versão Calculadora</th>
                <th>Token (Blockchain)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let talhao of talhoes">
                <td>{{ getFeatureByName(talhao.item, 'ANO_SAFRA') }}</td>
                <td>{{ selectedProducer.enterpriseCode }}</td>
                <td>{{ farmSelected.nickname }}</td>
                <td>{{ getFeatureByName(talhao.item, 'NOME_TALHAO') }}</td>
                <td>
                  {{
                    talhao.item.amount
                      ? (talhao.item.amount | number: '1.2-2': 'pt-BR')
                      : "0"
                  }}
                </td>
                <td>{{ getPegadaTalhao(talhao) }}</td>
                <td>{{ getFeatureByName(talhao.item, 'VERSAO_CALCULADORA') }}</td>
                <td>{{ talhao.hash }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <section class="baixa-volume">
        <h2>5. Informações da Baixa de Volume</h2>
        <div class="div-content">
          <p>/// Identificação da Trader</p>
          <table>
            <tr>
              <th>Nome</th>
              <td>{{ "ADM do Brasil Ltda" }}</td>
            </tr>
            <tr>
              <th>Estado</th>
              <td>{{ "Mato Grosso" }}</td>
            </tr>
            <tr>
              <th>Município</th>
              <td>{{ "Sinop" }}</td>
            </tr>
          </table>
          <p>
            Abaixo estão os registros referentes ao volume do produto recebido
            pela trader que também tiveram seus assets registrados em blockchain
            (token). No momento de realizar uma baixa, a trader consegue
            visualizar o volume de produto disponível. Ela não conseguirá dar
            baixa em um volume superior ao que o agricultor tem.
          </p>
          <div class="talhoes" *ngFor="let talhao of talhoes">
            <app-talhao [talhao]="talhao" (somaTotalBaixado)="somaTotalBaixado($event)"> </app-talhao>
          </div>
        </div>
      </section>

      <div class="qr-area">
        <p>
          Para mais informações sobre o programa e esse produtor participante
          consulte o QR Code ao lado ou <a [href]="urlLandingPage">clique aqui.</a>:
        </p>
        <img [src]="urlQRCODE" alt="QR Code">
      </div>
    </div>
  </div>
</div>
<app-spinner *ngIf="loading"></app-spinner>
