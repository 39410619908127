import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-icons-landing',
  templateUrl: './carousel-icons-landing.component.html',
  styleUrls: ['./carousel-icons-landing.component.scss']
})
export class CarouselIconsLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
