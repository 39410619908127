<nav id="navbarMobile" class="navbar fixed-top navbar-expand-lg ">
    <div class="header-logo">
        <img class="logo-img" src="../../../assets/images/logo.svg">
    </div>


    <!--<div class="collapse navbar-collapse ml-5 mr-3" id="navbarSupportedContent">
        <ul class="nav navbar-nav ml-auto">
            <li class="nav-item">
                <a href="" class="list_nav">Rastreabilidade</a>
            </li>

            <li class="nav-item">
                <a href="" class="list_nav">Extrato de Vendas</a>
            </li>
        </ul>
    </div>
    -->
    <button style="margin-right:10px" class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" style="height:40px"></span>
    </button>
</nav>
