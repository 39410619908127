<div class="lancar-recebimento-content">
  <p class="name-page">Registro de Volume / Lançar recebimento do agricultor</p>
  <h1><span class="arrow" (click)="redirectRegister()">←</span> Lançar Recebimento </h1>

  <p class="sentence">
    Consulte abaixo os grupos/produtores participantes do programa para conferir o saldo disponível e registrar o volume de soja recebido.
  </p>

  <form class="form-search" [formGroup]="formFilter" style="display: flex">
    <div class="search-container">
      <select formControlName="groups" (change)="getProducers()" class="input-search form-input" [style]="formFilter.get('groups')?.touched && formFilter.get('groups')?.invalid ? 'borderColor:red' : ''">
        <option checked></option>
        <option *ngFor="let g of groups"> {{ g }}</option>
      </select>
      <label style="color:#585858" class="form-label">
        Grupo Econômico*
      </label>
    </div>

    <div class="search-container">
      <select class="input-search form-input" formControlName="farm" (change)="changeNameFarmToCar()" [style]="formFilter.get('farm')?.touched && formFilter.get('farm')?.invalid ? 'borderColor:red' : ''">
        <option value="" [selected]="true" disabled></option>
        <option *ngFor="let farm of farms" [value]="farm.code">{{ farm.nickname }}</option>
      </select>

      <label style="color:#585858" class="form-label">
        Fazenda*
      </label>
    </div>

    <div class="search-container">
      <select class="input-search form-input" formControlName="culture" [style]="formFilter.get('culture')?.touched && formFilter.get('culture')?.invalid ? 'borderColor:red' : ''">
        <option value="" [selected]="true" disabled></option>
        <option value="SOJA"> Soja </option>
        <option value="MILHO"> Milho </option>
      </select>

      <label style="color:#585858" class="form-label">
        Cultura*
      </label>
    </div>

    <button type="button" class="btn" (click)="submitForm()">
      Consultar
    </button>
  </form>

  <p class="obs">*Preenchimento obrigatório</p>

  <div class="main-area">
    <h6>Produtores participantes</h6>
    <div class="table-area">
      <table>
        <colgroup span="4" class="columns"></colgroup>
        <thead>
          <tr>
            <th style="width: 50px;">&nbsp;</th>
            <th>Safra</th>
            <th>Cultura</th>
            <th>CPF/CNPJ</th>
            <th>Produtor</th>
            <th>Fazenda</th>
            <th>Talhão</th>
            <th>Saldo Disponível</th>
            <th>Pegada Média</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trackable of trackableItems, index as i">
              <td style="width: 50px;"><input type="checkbox" name="select-table" (change)="selectItemTrackable($event, trackable)"></td>
              <td>{{ (trackable.item | feature:'ANO_SAFRA') }}</td>
              <td> {{ trackable.item | feature:'CULTURA' }} </td>
              <td>{{ getEnterpriseDocument(trackable.item.enterprise) | document | maskDocument }}</td>
              <td>
                {{ getEnterpriseName(trackable.item.enterprise) }}
              </td>
              <td>
                {{ getFarmName() }}
              </td>
              <td>
                {{ trackable.item | feature:'NOME_TALHAO' }}
              </td>
              <td>{{ getValue(trackable.item.amount) }} ton</td>
              <td>
                {{ getValue(trackable.item | feature:'QTD_TOTAL_EMISSÃO_CARBONO') }}
              </td>
          </tr>
          <tr *ngIf="trackableItems.length === 0">
            <td colspan="9" style="text-align: center; width: 100vw; max-width: inherit;">Nenhum Resultado Encontrado</td>
          </tr>
        </tbody>
    </table>
    </div>
    <div class="submit-area">
      <div class="total-selecionado-area">
        <span>TOTAL SELECIONADO:</span>
        <div class="total-selecionado">
          <strong> {{ getSum() }} </strong>
          ton
        </div>
      </div>
      <button type="button" class="select-button" (click)="showModalRecebimento()" *ngIf="trackableList.length > 0">
        SELECIONAR VOLUME
      </button>
    </div>
  </div>
</div>

<app-modal-enviar-recebimento
  *ngIf="isShowingModalRecebimento"
  [producers]="producers"
  [carSelected]="formFilter.get('farm')?.value"
  [cultureSelected]="formFilter.get('culture')?.value"
  [trackableList]="trackableList"
  [trader]="trader"
  (close)="closeModal($event)"
></app-modal-enviar-recebimento>

<app-modal-confirm *ngIf="modal" [hashOrigins]="hashOrigins"></app-modal-confirm>

<app-spinner *ngIf="isLoading"></app-spinner>
