<div class="content">
  <div *ngIf="!isLoading" class="content">
    <p>
      Seu acesso a essa funcionalidade ainda não está autenticado. Estamos
      trabalhando para liberar essa opção em breve. Caso deseje acessar a área
      de rastreabilidade na plataforma, entre em contato com o responsável da
      fazenda.
    </p>
    <img src="../../../assets/images/lupa.jpg" />
  </div>
  <div *ngIf="isLoading" class="content">
    <p>Carregando...</p>
  </div>
</div>
