import { Injectable } from '@angular/core';
import { TrackableItem } from '../interfaces/trackable-item';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MovementService {
  readonly urlAPI = environment.apiURL;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {}


  getTraceByHash(hash: string): Observable<TrackableItem> {
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.get<TrackableItem>(`${this.urlAPI}/movement-service/trace?hash=${hash}`, { headers: this.headers });
  }

  getMovementByToken(token: string): Observable<TrackableItem> {
    const tokenAuthorization = sessionStorage.getItem('token');
     if (tokenAuthorization) {
      this.headers = new HttpHeaders({
        Authorization: tokenAuthorization,
      });
    }
    return this.http.get<TrackableItem>(`${this.urlAPI}/movement-service/trace?token=${token}`, { headers: this.headers })
  }

  postMovement(body: any){
    const token = sessionStorage.getItem('token');
     if (token) {
      this.headers = new HttpHeaders({
        Authorization: token,
      });
    }
    return this.http.post(`${this.urlAPI}/movement-service/register`, body, { headers: this.headers }).pipe(
      res => res,
      error => error
    );
  }

}
