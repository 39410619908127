import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-agricultor',
  templateUrl: './agricultor.component.html',
  styleUrls: ['./agricultor.component.scss']
})

export class AgricultorComponent implements OnInit {

  userId: string = "";
  idValid: boolean = false;
  @Output() testChanged = new EventEmitter<string>();
  isLoading: boolean = false;

  constructor(
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private entrepriseService: EnterpriseService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.userId = params['customerId'];
      if (this.userId) {
        sessionStorage.setItem('customerId', this.userId);
      }
    });

    if (!this.userId) {
      const customerId = sessionStorage.getItem('customerId');
      if (customerId) {
        this.userId = customerId;
      }
    }


    this.isLoading = true;
    this.entrepriseService.getEnterpriseByCustomerId(this.userId).subscribe(item => {
      if (item.length > 0) {
        this.isLoading = false;
        this.idValid = true;
        localStorage.setItem('figure', "true");
        //this.sessionService.setSession("typeUser", "productor");
      }
      else {
        this.isLoading = false;
        this.idValid = false;
        localStorage.setItem('figure', "false");
      }
    }, error => {
      this.isLoading = false;
      this.idValid = false;
    });

    localStorage.setItem('userId', this.userId);
  }

}
