import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from "jspdf";
import { TrackableItem } from 'src/app/interfaces/trackable-item';
import { EnterpriseService } from 'src/app/services/enterprise.service';
import { MovementService } from 'src/app/services/movement.service';
import { TraceabilityService } from 'src/app/services/traceability.service';
import { environment, urlSite } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QrCodeService } from 'src/app/services/qr-code.service';
import { IbgeDataServiceService } from '../../services/ibge-data-service.service';

@Component({
  selector: 'app-traceability-report',
  templateUrl: './traceability-report.component.html',
  styleUrls: ['./traceability-report.component.scss']
})
export class TraceabilityReportComponent implements OnInit {

  @ViewChild('report', {static: false}) report!: ElementRef;
  emittedDate: Date = new Date();

  selectedProducer: any = null;
  producers: any[] = [];
  groups: any;
  customerId: any = null;
  carbono: number = 0;
  transporte: number = 0;
  secagem: number = 0;
  insumo: number = 0;
  operacao: number = 0;
  mut: number = 0;
  document: string = "";
  flagGraph: boolean = false;
  trackebleItensSub?: TrackableItem[] = [];
  farm: any = null;
  totalValue: number = 0;
  nationalAverage: number = 0;
  averageEstimate = 0;
  talhoes: TrackableItem[] = [];

  volumeSold = 0;
  saldoAtual = 0;

  showReport: boolean = false;

  loading: boolean = false;
  form: FormGroup;

  farmSelected: any;

  culturesList: any[] = [];
  harvestsList: any[] = [];

  selectedCar: any;

  trackableItemsList: any[] = [];

  urlLandingPage: string = "";
  urlQRCODE: string = "";

  constructor(
    private enterpriseService: EnterpriseService,
    private traceabilityService: TraceabilityService,
    private movementService: MovementService,
    private titleService: Title,
    private fb: FormBuilder,
    private qrCodeService: QrCodeService,
    private ibgeDataServiceService: IbgeDataServiceService,
  ) {
    this.form = this.fb.group({
      producer: ['', Validators.required],
      farm: ['', Validators.required],
      culture: ['', Validators.required],
      harvest: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getAllGroups();
    this.titleService.setTitle(`Laudo de Rastreabilidade`);
  }

  exportPDF() {
    window.print();
    // const pdf = new jsPDF('p', 'px', 'a4');
    // pdf.html(this.report.nativeElement, {
    //   callback: (pdf) => {
    //     pdf.save("laudo.pdf");
    //   }
    // })
  }

  getValue(value: any) {
    let newValue = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);
    return newValue;
  }

  selectProducer(event: any) {
    this.resetReport();
    this.selectedProducer = null;
    this.form.get('farm')?.setValue('');
    this.form.get('culture')?.setValue('');
    this.form.get('harvest')?.setValue('');
    this.showReport = false;
    const producer = this.producers.filter(item => item.id === event.target.value);
    this.selectedProducer = producer[0];
    this.customerId = this.selectedProducer.customerId;
    this.document = this.customerId;
    let fazendas: Array<any> = [];
    this.selectedProducer.places.map((item: any) => {
      if (item.type === "FARM") {
        if (!fazendas.some(fazenda => fazenda.code === item.code))
          fazendas.push(item);
      }
    });
    this.farm = fazendas;
    this.emittedDate = new Date();
  }

  async selectFarm(event: any) {
    this.resetReport();
    this.form.get('harvest')?.setValue('');
    this.form.get('culture')?.setValue('');

    this.culturesList = [];
    this.harvestsList = [];

    this.farmSelected = this.farm.find((fazenda: any) => fazenda.hash === event.target.value);
    const car = this.farmSelected.ruralEnvironmentalRegistry.split('-');
    this.ibgeDataServiceService.getAreaById(car[1]).subscribe(res =>{
      this.selectedCar = res;
    })
    this.titleService.setTitle(`Laudo de Rastreabilidade - ${this.farmSelected.nickname}`);

    this.loading = true;
    this.traceabilityService.getTraceByPlaceCode(this.farmSelected.code, this.selectedProducer.economicGroup).subscribe(
      res => {
        this.loading = false;
        this.trackableItemsList = res;
        this.trackableItemsList.map((item: TrackableItem) => {
          const culture = this.getFeatureByName(item.item, 'CULTURA');
          if (!this.culturesList.includes(culture)) {
            this.culturesList.push(culture);
          }
        });
      }, error => {
        this.loading = false;
      });
  }

  somaTotalBaixado(event: number) {
    this.volumeSold += event;
  }

  formSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.talhoes = this.trackableItemsList.filter((talhao: TrackableItem) => {
        const culture = this.getFeatureByName(talhao.item, 'CULTURA');
        const harvest = this.getFeatureByName(talhao.item, 'ANO_SAFRA');


        return culture === this.form.get('culture')?.value && harvest === this.form.get('harvest')?.value;
      });

      let valueTotal = 0;
      let totalBaixado = 0;
      let divider = 0;
      let dividend = 0;
      let valorSumTransporte = 0, valorSumSecagem = 0, dividendSecagem = 0, dividendCarbono = 0, valorSumInsumo = 0, valorSumOperacao = 0, valorSumMUT = 0, dividendTransporte = 0, dividendInsumo = 0, dividendOperacao = 0, dividendMut = 0;


      let verificationDate = '';
      let cultura = '';
      let harvest = ''
      let biomes: any[] = [];
      let farmList: any[] = [];

      this.talhoes[0].item.features.map(item => {
        if (item.name === 'DATA_REGISTRO') {
          verificationDate = item.value;
        }
        if (item.name === 'CULTURA') {
          cultura = item.value;
        }
        if (item.name === 'ANO_SAFRA') {
          harvest = item.value;
        }
      });

      this.talhoes.forEach(trackeableItem => {
        valueTotal = valueTotal + trackeableItem.item.receivedQuantity;
        totalBaixado = totalBaixado + trackeableItem.item.requestedQuantity;

        let emissaoCarbono = 0;
        trackeableItem.item.features.map(f => {
          if (f.name === 'QTD_C02_EQ_INSUMO') {
            valorSumInsumo = parseFloat(f.value);
          }
          if (f.name === 'BIOMA') {
            if (!biomes.some(item => item === f.value)) {
              biomes.push(f.value);
            }
          }

          if (f.name === 'QTD_C02_EQ_OPERAÇÃO') {
            valorSumOperacao = parseFloat(f.value);
          }
          if (f.name === 'QTD_C02_EQ_TRANSPORTE') {
            valorSumTransporte = parseFloat(f.value);
          }
          if (f.name === 'QTD_C02_EQ_MUT') {
            valorSumMUT = parseFloat(f.value);
          }
          if (f.name == "QTD_TOTAL_EMISSÃO_CARBONO") {
            emissaoCarbono = parseFloat(f.value);
          }
          if (f.name == "QTD_C02_SECAGEM") {
            valorSumSecagem = parseFloat(f.value);
          }

        });
        divider += trackeableItem.item.receivedQuantity;
        dividend += (emissaoCarbono * trackeableItem.item.receivedQuantity);

        dividendTransporte += valorSumTransporte * trackeableItem.item.receivedQuantity;
        dividendInsumo += valorSumInsumo * trackeableItem.item.receivedQuantity;
        dividendOperacao += valorSumOperacao * trackeableItem.item.receivedQuantity;
        dividendMut += valorSumMUT * trackeableItem.item.receivedQuantity;
        dividendCarbono += emissaoCarbono * trackeableItem.item.receivedQuantity;
        dividendSecagem += valorSumSecagem * trackeableItem.item.receivedQuantity;

      });

      this.transporte = dividendTransporte / divider;
      this.insumo = dividendInsumo / divider;
      this.operacao = dividendOperacao / divider;
      this.mut = dividendMut / divider;
      this.carbono = dividendCarbono / divider;
      this.secagem = dividendSecagem / divider;

      this.nationalAverage = dividend / divider;


      this.totalValue = 0;
      this.saldoAtual = 0;
      this.talhoes.forEach((element: TrackableItem) => {
        this.totalValue += element.item.receivedQuantity;
        this.saldoAtual += element.item.amount;
      });

      this.selectedProducer.places.map((place: any) => {
        if (place.type === 'FARM') {
          farmList.push({
            nickname: place.nickname,
            code: place.code,
            ruralEnvironmentalRegistry: place.ruralEnvironmentalRegistry,
          });
        }
      });
      this.showReport = true;

      const pageData = {
        customerId : this.customerId,
        cultura: cultura,
        harvest: harvest,
        volumeTotal: this.totalValue,
        pegadaMediaGrupo: this.carbono,
        farmList: farmList,
        biomes: biomes,
        verificationDate: verificationDate,
      }

      this.qrCodeService.postQRData({ data: pageData }).subscribe((res : any) => {
        const token = res.id;
        this.urlLandingPage = `landing-page?data=${token}`;
        this.urlQRCODE = `${environment.apiURL}/qrcode?url=${urlSite}/landing-page?data=${token}`;
      });
    }
  }

  selectCulture() {
    this.resetReport();
    this.form.get('harvest')?.setValue('');
    const filteredTrackableItems = this.trackableItemsList.filter((talhao: TrackableItem) => {
      const culture = this.getFeatureByName(talhao.item, 'CULTURA');
      return culture === this.form.get('culture')?.value;
    });
    this.harvestsList = [];
    filteredTrackableItems.map((item: TrackableItem) => {
      const harvest = this.getFeatureByName(item.item, 'ANO_SAFRA');
      if (!this.harvestsList.includes(harvest)) {
        this.harvestsList.push(harvest);
      }
    });
  }

  selectHarvest() {
    this.resetReport();
  }

  resetReport() {
    this.volumeSold = 0;
    this.totalValue = 0;
    this.saldoAtual = 0;
    this.showReport = false;
  }

  getAllGroups() {
    this.loading = true;
    this.enterpriseService.getAllGroups().subscribe(groupsReturned => {
      this.groups = groupsReturned;
      this.getProducers();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  async getProducers() {
    if (this.producers.length == 0) {
      await this.groups.forEach((group: any) => {
        this.enterpriseService.getDatasGroupByEnterpriseCode(group).subscribe(producers => {
          this.producers.push(producers);
          this.producers = this.producers.flat(1);
        })
      })
    }
  }

  getUrlApi() {
    return environment.apiURL;
  }

  getUrl() {
    return urlSite;
  }


  getTotalValue(value: any) {
    let newValue = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);

    return newValue;
  }

  getFeatureByName(item: any, name: string) {
    let returnFeature = {
      name: '',
      value: null,
      usage: ''
    };
    if (item && item.features && item.features.length > 0) {
      item.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      });
    }
    return returnFeature.value;
  }

  getTalhaoName(talhao: any): string {
    let feature = '';
    talhao.item.features.find((item: any) => {
      if (item.name === 'FIELD_NAME') {
        feature = item.value;
      }
    });
    return feature;
  }

  getPegadaTalhao(talhao: any) {
    let feature = '';
    talhao.item.features.find((item: any) => {
      if (item.name === 'QTD_TOTAL_EMISSÃO_CARBONO') {
        feature = item.value;
      }
    });
    const result = parseFloat(feature);
    const pegada = result ? result.toFixed(2).replace('.', ',') :  '0';
    return pegada;
  }

  getSafra(talhao: any) {
    let feature = '';
    talhao.item.product.features.find((item: any) => {
      if (item.name === 'ANO_SAFRA') {
        feature = item.value;
      }
    });
    return feature;
  }
}
