import { Component, Input, OnInit } from '@angular/core';
declare var google: any;

@Component({
  selector: 'app-graph-escopo',
  templateUrl: './graph-escopo.component.html',
  styleUrls: ['./graph-escopo.component.scss']
})
export class GraphEscopoComponent implements OnInit {

  @Input() escopo1: number = 0;
  @Input() escopo2: number = 0;
  @Input() escopo3: number = 0;
  @Input() tooltip: boolean = true;
  @Input() hole: number = 0.7;
  @Input() talhaoQtdMedia: number = 0;
  @Input() talhaoQtdTotal: number = 0;

  @Input() carbono: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.carbono = parseFloat(this.carbono.toFixed(2));
    let width = window.screen.width;
    let objJs = [
      ['', 'Total de Emissão de carbono'],
      [`Escopo 1`, this.escopo1],
      [`Escopo 2`, this.escopo2],
      [`Escopo 3`, this.escopo3],
    ];

    if (width > 960) {
      const hole = this.hole;
      const legend = hole === 0 ? {} : {
        position: 'bottom',
        alignment: 'center',
        maxLines: 2,
        textStyle: {
          fontSize: 12
        }
      };

      const colors = hole === 0 ? ['#0481C4', '#65991D', '#CCD232', '#858585'] : ['#ADE9FF', '#33C9FF', '#0087B8', '#004B66'];

      google.charts.load("current", { packages: ["corechart"] })
      google.charts.setOnLoadCallback(function () {
        var data = google.visualization.arrayToDataTable(objJs);
        var options = {
          pieSliceTextStyle: {
            color: "black",
          },
          pieHole: hole,
          width: "100%",
          height: 350,
          colors: colors,
          responsive: true,
          legend: legend,
          series: {
            0: {
              marker: {
                shape: 'square'
              }
            },
            // Adicione configurações para outras séries, se necessário
          },
          chartArea: {
            height: "350px",
            width: "100%",
          },
        };

        var chart = new google.visualization.PieChart(document.getElementById('chart-escopo'));
        chart.draw(data, options);
      });
    }
    else {


    }
  }

  getValue(value: any) {
    let newValue = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);

    return newValue;
  }

}
