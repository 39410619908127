import { Component, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'project-bayer';
  figure: boolean = false;
  currentUrl: string = '';
  landingPage: boolean = false;
  showLogin: boolean = true;
  login: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const token = sessionStorage.getItem('token');
    if (token) {
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = e.url;
      }

      if (this.currentUrl.includes("landing-page")) {
        this.landingPage = true;
      }
      else {
        this.landingPage = false;
      }

      if (this.currentUrl.includes("login") || this.currentUrl === '/') {
        this.login = true;
      }
      else {
        this.login = false;
      }
    });
  }

}


