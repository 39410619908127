<div class="modal-confirm">
    <p>Volume registrado com sucesso!</p>
    <p class="sentence-1">IDENTIFICADOR/TOKEN ORIGEM:</p>
    <div class="hash-area">
      <p style="display: block;" *ngFor="let hashOrigin of hashOrigins">{{ hashOrigin }}</p>
    </div>
    <hr>
    <p>Acesse a página de Registro de Volume para consultar esse volume e o número do token gerado para essa baixa.</p>
    <div style="margin-top:10px;display:flex;float:right">
        <button (click)="closeModal()" class="btn-1">
            <p>OK</p>
        </button>
    </div>
</div>
