<div class="sidebar" *ngIf="token">
  <div class="container">
    <div class="accordion">
      <div class="accordion-item active" style="width: 100%">
        <div class="title">
          <img id="logo" src="../../../assets/images/icon.png" />
          <span style="margin-left: 10px">Início</span>
          <i class="fas fa-chevron-down"></i>
        </div>
        <a routerLink="/registro-compras" routerLinkActive="active-link" *ngIf="trader" class="paragraph">
          Registro de volume
        </a>
        <a routerLink="/trader" routerLinkActive="active-link" ariaCurrentWhenActive="page" *ngIf="trader" class="paragraph">
          Rastreabilidade
        </a>
        <a routerLink="/produtor" *ngIf="productor" routerLinkActive="active-link" class="paragraph">
          Extrato de Saída de Volume
        </a>
        <a routerLink="/produtor-inicio" routerLinkActive="active-link" *ngIf="productor" class="paragraph">
          Rastreabilidade
        </a>
        <a routerLink="/laudo-rastreabilidade" routerLinkActive="active-link" *ngIf="adm" class="paragraph">
          Laudo de Rastreabilidade
        </a>
      </div>
    </div>
  </div>

  <div class="menu-bottom">
    <div class="menu-bottom-item" (click)="openModalTypeUser()" *ngIf="adm">
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.66797 2.50547C9.82797 2.50547 10.768 3.44547 10.768 4.60547C10.768 5.76547 9.82797 6.70547 8.66797 6.70547C7.50797 6.70547 6.56797 5.76547 6.56797 4.60547C6.56797 3.44547 7.50797 2.50547 8.66797 2.50547ZM8.66797 11.5055C11.638 11.5055 14.768 12.9655 14.768 13.6055V14.7055H2.56797V13.6055C2.56797 12.9655 5.69797 11.5055 8.66797 11.5055ZM8.66797 0.605469C6.45797 0.605469 4.66797 2.39547 4.66797 4.60547C4.66797 6.81547 6.45797 8.60547 8.66797 8.60547C10.878 8.60547 12.668 6.81547 12.668 4.60547C12.668 2.39547 10.878 0.605469 8.66797 0.605469ZM8.66797 9.60547C5.99797 9.60547 0.667969 10.9455 0.667969 13.6055V16.6055H16.668V13.6055C16.668 10.9455 11.338 9.60547 8.66797 9.60547Z"
          fill="black"
          fill-opacity="0.68"
        />
      </svg>
      <p class="paragraph">Trocar de usuário</p>
    </div>
    <div class="menu-bottom-item" (click)="logout()" *ngIf="adm">
      <svg
        width="21"
        height="18"
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9785 4L14.5685 5.41L17.1485 8H6.97852V10H17.1485L14.5685 12.58L15.9785 14L20.9785 9L15.9785 4ZM2.97852 2H10.9785V0H2.97852C1.87852 0 0.978516 0.9 0.978516 2V16C0.978516 17.1 1.87852 18 2.97852 18H10.9785V16H2.97852V2Z"
          fill="black"
          fill-opacity="0.6"
        />
      </svg>
      <p class="paragraph">Sair</p>
    </div>
  </div>
</div>

<div *ngIf="modalOpen" class="modal-user modal-open">
  <app-modal-type-user
    (dadosEnviados)="changeModal($event)"
  ></app-modal-type-user>
</div>

<div *ngIf="modalProducer" class="modal-producer">
  <app-modal-producer
    [producers]="producers"
    (close)="close($event)"
  ></app-modal-producer>
</div>

<div *ngIf="modalTrader">
  <app-modal-trader
    [traders]="traders"
    (closeTrader)="close($event)"
  ></app-modal-trader>
</div>

<app-spinner *ngIf="loading"></app-spinner>
