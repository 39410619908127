<div class="modal-producer">
  <div class="modal-producer-header">
    <button type="button" (click)="enviarDados('fechar')" >
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2.01547L12.59 0.605469L7 6.19547L1.41 0.605469L0 2.01547L5.59 7.60547L0 13.1955L1.41 14.6055L7 9.01547L12.59 14.6055L14 13.1955L8.41 7.60547L14 2.01547Z" fill="black" fill-opacity="0.38"/>
      </svg>
    </button>
    <span>Escolher usuário tela admin</span>
  </div>

  <div class="modal-producer-content">
    <div class="stepper">
      <div class="step-one">
        <div class="step-circle">
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.81353 7.70614L0.968732 4.90748L0 5.85379L3.81353 9.60547L12 1.55178L11.0381 0.605469L3.81353 7.70614Z" fill="black" fill-opacity="0.68"/>
          </svg>
        </div>
        <span class="span-unselected">Selecionar o tipo de visualização</span>
      </div>
      <div>
        <svg width="140" height="4" viewBox="0 0 140 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="2.10547" x2="139.5" y2="2.10546" stroke="black" stroke-opacity="0.12" stroke-width="3"/>
        </svg>
      </div>
      <div class="step-two">
        <div class="step-circle selected">
          {{ 2 }}
        </div>
        <span class="span-selected">Selecionar produtor/trader</span>
      </div>
    </div>
    <p> Deseja entrar como qual produtor? </p>
    <form [formGroup]="formProducer">
      <div class="input-container">
        <select class="input-search form-input" formControlName="producer">
          <option value="" [selected]="true" disabled></option>
          <option *ngFor="let producer of producers" [value]="producer.document">{{ producer.corporateName }}</option>
        </select>

        <label style="color:#585858" class="form-label">
          Produtor
        </label>
      </div>
    </form>
  </div>

    <div class="buttons">
      <button type="button" (click)="enviarDados('voltar')">Voltar</button>
      <button type="button" class="submit-button" (click)="toPageProducer()">Entrar</button>
    </div>


</div>
