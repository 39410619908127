<div>
    <app-header-landing-page></app-header-landing-page>
    <div class="section-3">
      <div class="image-area">
        <div class="image-item">
          <img
            src="../../assets/images/full-hd/DSCF7631.jpg"
            class="img-1"
          />
        </div>
        <div class="small-images">
          <div class="image-small">
            <img
              src="../../assets/images/full-hd/DSCF4453.jpg"
              class="img-2"
            />
          </div>
          <div class="image-small">
            <img
              src="../../assets/images/full-hd/DSCF1718.jpg"
              class="img-3"
            />
          </div>
          <div class="image-small">
            <img
              src="../../assets/images/full-hd/DSCF8275.jpg"
              class="img-4"
            />
          </div>
        </div>
      </div>

        <div class="text-area">
          <p id="sentence-1">
            Seguem os dados do produto e como ele está colaborando para a sustentabilidade coletiva:
          </p>

          <div class="boxes-desktop">
            <div class="box box-1">
              <strong>{{ cultura }}</strong>
              <span>Cultura</span>
            </div>
            <div class="box box-1">
              <strong>{{ safra }}</strong>
              <span>Ano Safra</span>
            </div>

            <div class="box box-2">
              <strong>{{ (volumeTotal | number: '1.2-2': 'pt-BR') }} t </strong>
              <span>Volume Recebido</span>
            </div>

            <div class="box box-3">
              <strong>{{ (pegada | number: '1.0-2': 'pt-BR') }}</strong>
              <span class="medida">kg CO2 eq / t</span>
              <span>Pegada Talhão</span>
            </div>
        </div>

        <p class="card-text">
          *Todas as informações dessa sessão são referente às áreas do agricultor inscritas no programa
        </p>


        <div class="col-texts">
          <div class="white-card">
              <span class="text-title">Região de Origem</span>
              <p *ngFor="let location of regionOrigin">
                {{ location.nome }}<br>{{ location.microrregiao.mesorregiao.UF.nome }}
              </p>
          </div>
          <div class="white-card">
              <span class="text-title">Nome da Fazenda</span>
              <p *ngFor="let farm of farms">{{ farm.nickname }}</p>
          </div>
          <div class="white-card">
              <span class="text-title">Bioma da Fazenda</span>
              <p *ngFor="let bd of biomesDiferents">{{ bd }}</p>
          </div>
          <div class="white-card">
            <span class="text-title">Nome do Talhão </span>
            <p>{{ nomeTalhao }}</p>
        </div>
        </div>

        <p id="sentence-2">
          O Agricultor que plantou esse produto e a área onde foi produzido passou por uma análise restrita de compliance socioambiental nos seguintes critérios:
        </p>
        <p id="sentence-3">
          Condição de trabalho análogo ao escravo, LDI para Doc Oline, Embargos SEMA, Embargos ICMBio, Embargos IBAMA Desmatamento, Comunidades Quilombolas, Unidade de Conservação, Desmatamento Prodes, Terras indígenas, conformidade com o Código Florestal.
        </p>

        <!-- <div class="row infos-columns">
            <div class="col-lg-5">
                <p id="text-1">Última Verificação Realizada em:<br><span>{{verificationDate | date: 'dd/MM/yyyy'}}</span></p>
            </div>
            <div class="col-lg-7">
                <p id="text-2">
                  Para verificar critérios e como eles foram avaliados, acesse os detalhes do Programa PRO Carbono Commodities aqui..
                </p>
            </div>
        </div> -->

        <div class="logos-area">
            <div class="logo">
                <p class="description-logo">Ferramenta codesenvolvida com EMBRAPA através de parceria técnico-científica</p>
                <img src="../../../assets/images/Embrapa.png"  style="width: 90px">
            </div>
            <div class="logo">
                <p class="description-logo">Auditorias parceiras dos programas:</p>
                <img src="../../../assets/images/Bureau Veritas.png" />
            </div>
            <!-- <div class="col">
                <p class="description-logo">Plataforma desenvolvida em parceria com:</p>
                <img src="../../../assets/images/logo_horizontal_gradiente_slogan_pt.png" class="logo-3" />
            </div>
            <div class="col">
                <p class="description-logo">Registro das informações em Blockchain:</p>

                    <img src="../../../assets/images/logoSafe 1.png" class="logo-4" />
            </div> -->
        </div>
      </div>

      <div class="carousel-agriculturist-mobile">
          <app-carousel-agriculturist></app-carousel-agriculturist>
      </div>
    </div>
    <app-footer-landing-page></app-footer-landing-page>
</div>
