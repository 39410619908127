<div class="content">
    <h1>Rastreabilidade</h1>

    <p class="sentence" id="sentence-1">Saiba de onde veio seu produto</p>
    <p class="sentence" id="sentence-2">Digite o código Identificador da soja adquirida para obter mais informações
        sobre a
        rastreabilidade do produto.</p>

    <form [formGroup]="formEnterprise">
        <input class="input-search" placeholder="CPF/CNPJ" type="text" formControlName="document" (keydown.enter)="findTraceability()" required=>
        <select class="input-search" (change)="onAnoSafraChange()">
            <option value="" [selected]="true" disabled>Ano/Safra</option>
            <option *ngFor="let anoSafra of selectAnoSafra"> {{anoSafra}}</option>
        </select>
        <select class="input-search" formControlName="token"  (change)="findByTokenAndAnoSafra()">
            <option value="" [selected]="true" disabled>Identificador/Token</option>
            <option *ngFor="let identicadorToken of selectIdentificadorToken"> {{identicadorToken}}</option>
        </select>
    </form>

    <div *ngIf="showContentFlag == true && trackableItem != undefined && enterprise != undefined">
        <p class="sentence" id="sentence-3">Soja: {{ trackableItem.item.product.code }}</p>
        <p class="sentence" id="sentence-4">Data do registro: 05/04/2023</p>
        <p class="sentence" id="sentence-5">Volume comprado: {{ trackableItem.item.amount }} Toneladas</p>

        <div class="titles-section">
            <p class="title-1">Dados</p>
            <p class="title-2">Informações de emissão de carbono</p>
        </div>

        <div class="sections-1">
            <div class="section-1">

                <div class="topic">
                    <p class="title-topic">GRUPO/PRODUTOR</p>
                    <p>Bom sucesso</p>
                </div>

                <div class="topic">
                    <p class="title-topic">FAZENDA</p>
                    <p>{{ enterprise.places[0].code }}</p>
                </div>

                <div class="topic">
                    <p class="title-topic">REGIÃO</p>
                    <p>{{enterprise.address.district}}, {{enterprise.address.stateName}}, {{enterprise.address.stateIdentifier}}</p>
                </div>

                <div class="topic">
                    <p class="title-topic">BIOMA</p>
                    <p>Amazônico</p>
                </div>

                <div class="topic">
                    <p class="title-topic">SISTEMAS</p>
                    <p>Balanço de Massa</p>
                </div>

                <div class="topic">
                    <p class="title-topic">Ano Safra</p>
                    <p>{{ trackableItem.item.product.features[1].value }}</p>
                </div>

                <div class="topic">
                    <p class="title-topic">CAR'S</p>
                    <div class="text-topic">
                        <p>(1) {{trackableItem.item.place}}</p>
                    </div>
                </div>
            </div>

            <div class="section-2">
                <div class="buttons-select">
                    <div class="button">
                        <p>Safra {{ trackableItem.item.product.features[1].value }}</p>
                    </div>
                </div>

                <div class="sentences-section">
                    <p class="sentence" id="sentence-6">Cálculo feito utilizando calculadora Pro Carbono Footprint</p>
                    <p class="sentence" id="sentence-7"><b>Última Atualização: 13/03/2023</b></p>
                </div>

                <div id="chart" style="z-index:0" height="1000px">
                </div>

                <hr>

                <p class="sentence" id="sentence-8"><b>Comparativo de emissão de carbono</b></p>

                <div class="infos">
                    <div class="info" id="info-1">
                        <p>Média Soja Brasil<br>(Kg CO2 eq/t soja)</p>
                        <span class="number-quantity">134<i class="fa fa-file-text-o"></i></span>
                    </div>
                    <div class="info" id="info-2">
                        <p>Soja do programa SBC<br>(Kg CO2 eq/t soja)</p>
                        <span class="number-quantity">100</span>
                    </div>
                    <div class="info" id="info-3">
                        <p>Emite<br>(Kg CO2 eq/t soja)</p>
                        <span class="number-quantity" style="color:#74BB25;">&darr; 34%</span>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <br>

        <div class="titles-section">
            <p class="title-3">Mais informações</p>
            <p class="title-4">Selos</p>
        </div>

        <div class="sections-2">

            <div class="section-3">
                <p>Para mais informações sobre a soja e o programa escaneie o QR CODE ou <span>clique
                        aqui.</span></p>
                <img src="http://qa.agrotransparencia.com.br:9001/enterprise-service/qrcode" alt="QR Code">
            </div>
            <div class="section-4">
                <div class="section-item" id="section-item-1">
                    <p style="margin-bottom: 40px;">Auditado por:</p>
                    <img src="../../../assets/images/bureau-veritas.png">
                </div>
                <div class="section-item" id="section-item-2">
                    <p style="margin-bottom: 40px;">Rastreado por:</p>
                    <img src="../../../assets/images/logoSafe 1.png">
                </div>
                <div class="section-item" id="section-item-3">
                    <p style="margin-bottom: 40px;">Certificado por:</p>
                    <img src="../../../assets/images/Bayer_large.png">
                </div>
                <div class="section-item" id="section-item-4" style="overflow-y:hidden;">
                    <p style="margin-bottom: 40px;">Calculadora desenvolvida pela Embrapa</p>
                    <div style="overflow-y:hidden;margin: auto;display: block;height:100%">
                        <img src="../../../assets/images/Embrapa.png">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Se nao retornar um trackble item -->

    <div *ngIf="showContentFlag == false && trackableItem == undefined && enterprise == undefined">
        <p class="sentence" id="sentence-3">Soja: N/A</p>
        <p class="sentence" id="sentence-4">Data do registro: N/A</p>
        <p class="sentence" id="sentence-5">Volume comprado: N/A</p>

        <div class="titles-section">
            <p class="title-1">Dados</p>
            <p class="title-2">Informações de emissão de carbono</p>
        </div>

        <div class="sections-1">
            <div class="section-1">

                <div class="topic">
                    <p class="title-topic">GRUPO/PRODUTOR</p>
                    <p>N/A</p>
                </div>

                <div class="topic">
                    <p class="title-topic">FAZENDA</p>
                    <p>N/A</p>
                </div>

                <div class="topic">
                    <p class="title-topic">REGIÃO</p>
                    <p>N/A</p>
                </div>

                <div class="topic">
                    <p class="title-topic">BIOMA</p>
                    <p>N/A</p>
                </div>

                <div class="topic">
                    <p class="title-topic">SISTEMAS</p>
                    <p>N/A</p>
                </div>

                <div class="topic">
                    <p class="title-topic">Ano Safra</p>
                    <p>N/A</p>
                </div>

                <div class="topic">
                    <p class="title-topic">CAR'S</p>
                    <div class="text-topic">
                        <p>N/A</p>
                    </div>
                </div>
            </div>

            <div class="section-2">
                <div class="buttons-select">
                    <div class="button">
                        <p>N/A</p>
                    </div>
                </div>

                <div class="sentences-section">
                    <p class="sentence" id="sentence-6">Cálculo feito utilizando calculadora Pro Carbono Footprint</p>
                    <p class="sentence" id="sentence-7"><b>Última Atualização: N/A</b></p>
                </div>

                <div id="chart" style="z-index:0" height="1000px">
                </div>

                <hr>

                <p class="sentence" id="sentence-8"><b>Comparativo de emissão de carbono</b></p>

                <div class="infos">
                    <div class="info" id="info-1">
                        <p>Média Soja Brasil<br>(Kg CO2 eq/t soja)</p>
                        <span class="number-quantity"><i class="fa fa-file-text-o"></i></span>
                    </div>
                    <div class="info" id="info-2">
                        <p>Soja do programa SBC<br>(Kg CO2 eq/t soja)</p>
                        <span class="number-quantity"></span>
                    </div>
                    <div class="info" id="info-3">
                        <p>Emite<br>(Kg CO2 eq/t soja)</p>
                    </div>
                </div>
            </div>
        </div>

        <br>
        <br>

        <div class="titles-section">
            <p class="title-3">Mais informações</p>
            <p class="title-4">Selos</p>
        </div>

        <div class="sections-2">

            <div class="section-3">
                <p>Para mais informações sobre a soja e o programa escaneie o QR CODE.</p>
            </div>
            <div class="section-4">
                <div class="section-item" id="section-item-1">
                    <p style="margin-bottom: 40px;">Auditado por:</p>
                    <img src="../../../assets/images/bureau-veritas.png">
                </div>
                <div class="section-item" id="section-item-2">
                    <p style="margin-bottom: 40px;">Rastreado por:</p>
                    <img src="../../../assets/images/logoSafe 1.png">
                </div>
                <div class="section-item" id="section-item-3">
                    <p style="margin-bottom: 40px;">Certificado por:</p>
                    <img src="../../../assets/images/Bayer_large.png">
                </div>
                <div class="section-item" id="section-item-4" style="overflow-y:hidden;">
                    <p style="margin-bottom: 40px;">Calculadora desenvolvida pela Embrapa</p>
                    <div style="overflow-y:hidden;margin: auto;display: block;height:100%">
                        <img src="../../../assets/images/Embrapa.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
