import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { AccordionsRegistroVolumeComponent } from './accordions-registro-volume/accordions-registro-volume.component';
import { AccordionsSaidasVolumesComponent } from './accordions-saidas-volumes/accordions-saidas-volumes.component';
import { HeaderLandingPageComponent } from './header-landing-page/header-landing-page.component';
import { FirstSectionLandingComponent } from './first-section-landing/first-section-landing.component';
import { SecondSectionLandingComponent } from './second-section-landing/second-section-landing.component';
import { ThirdSectionLandingComponent } from './third-section-landing/third-section-landing.component';
import { FouthSectionLandingComponent } from './fouth-section-landing/fouth-section-landing.component';
import { FifthSectionLandingComponent } from './fifth-section-landing/fifth-section-landing.component';
import { FooterLandingPageComponent } from './footer-landing-page/footer-landing-page.component';
import { HeaderLandingMobileComponent } from './header-landing-mobile/header-landing-mobile.component';
import { UserNotFoundComponent } from './user-not-found/user-not-found.component';
import { GraphComponent } from './graph/graph.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { CarouselIconsLandingComponent } from './carousel-icons-landing/carousel-icons-landing.component';
import { CarouselAgriculturistComponent } from './carousel-agriculturist/carousel-agriculturist.component';
import { CarouselVideosComponent } from './carousel-videos/carousel-videos.component';
import { AccordionTalhaoComponent } from './accordion-talhao/accordion-talhao.component';
import { AccordionBaixaComponent } from './accordion-baixa/accordion-baixa.component';
import { AccordionRecebimentoComponent } from './accordion-recebimento/accordion-recebimento.component';
import { ModalTypeUserComponent } from './modal-type-user/modal-type-user.component';
import { ModalTraderComponent } from './modal-trader/modal-trader.component';
import { ModalProducerComponent } from './modal-producer/modal-producer.component';
import { TraceabilityReportComponent } from './traceability-report/traceability-report.component';
import { TalhaoComponent } from './traceability-report/talhao/talhao.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ModalEnviarRecebimentoComponent } from './modal-enviar-recebimento/modal-enviar-recebimento.component';
import { SharedModule } from '../shared/shared.module';
import { GraphEscopoComponent } from './graph-escopo/graph-escopo.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    HeaderMobileComponent,
    AccordionsRegistroVolumeComponent,
    AccordionsSaidasVolumesComponent,
    HeaderLandingPageComponent,
    FirstSectionLandingComponent,
    SecondSectionLandingComponent,
    ThirdSectionLandingComponent,
    FouthSectionLandingComponent,
    FifthSectionLandingComponent,
    FooterLandingPageComponent,
    HeaderLandingMobileComponent,
    UserNotFoundComponent,
    GraphComponent,
    ModalConfirmComponent,
    CarouselIconsLandingComponent,
    CarouselAgriculturistComponent,
    CarouselVideosComponent,
    AccordionTalhaoComponent,
    AccordionBaixaComponent,
    AccordionRecebimentoComponent,
    ModalTypeUserComponent,
    ModalTraderComponent,
    ModalProducerComponent,
    TraceabilityReportComponent,
    TalhaoComponent,
    SpinnerComponent,
    ModalEnviarRecebimentoComponent,
    GraphEscopoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    HeaderMobileComponent,
    AccordionsRegistroVolumeComponent,
    AccordionsSaidasVolumesComponent,
    HeaderLandingPageComponent,
    FirstSectionLandingComponent,
    SecondSectionLandingComponent,
    ThirdSectionLandingComponent,
    FouthSectionLandingComponent,
    FifthSectionLandingComponent,
    FooterLandingPageComponent,
    HeaderLandingMobileComponent,
    UserNotFoundComponent,
    GraphComponent,
    ModalConfirmComponent,
    CarouselIconsLandingComponent,
    CarouselAgriculturistComponent,
    ModalTraderComponent,
    ModalTypeUserComponent,
    ModalProducerComponent,
    SpinnerComponent,
    ModalEnviarRecebimentoComponent,
    GraphEscopoComponent,

  ],
})

export class ComponentsModule { }
