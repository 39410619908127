<ul class="menu">
  <li class="item-baixa">
    <div class="title-accordion" (click)="toggleOpenInfo()">
      <button type="button" [ngClass]="openInfo ? 'button-arrow open' : 'button-arrow'">
        <svg width="18" height="18" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2287 0.589844L6.63867 5.16984L2.04867 0.589844L0.638672 1.99984L6.63867 7.99984L12.6387 1.99984L11.2287 0.589844Z" fill="black" fill-opacity="0.38"/>
        </svg>
      </button>
      <div class="text">
        <h3 class="text-1">Identificador/Token Baixa: {{ baixa.hash }}</h3>
        <h3 class="infos-accordion">
          Data do Recebimento: {{ baixa.movements[0].movementDate | date:'dd/MM/YYYY'  }} |
          Grupo Econômico: {{ enterprise ? enterprise.economicGroup : '-' }} |
          Volume recebido no POD: {{  baixa.item.amount }} ton
        </h3>
      </div>
    </div>

    <div class="text-open" style="padding-left: 1.25vw" *ngIf="openInfo && baixa.movements.length > 0">
      <div style="display: flex">
        <div class="column-1">
          <div class="topic">
            <p class="title-topic">Safra</p>
            <p class="text-topic">{{ (getFeatureByName(baixa.item, 'ANO_SAFRA')) || (baixa.item ? getFeatureByName(baixa.item.product, 'Ano Safra') : '-') }}</p>
          </div>

          <div class="topic">
            <p class="title-topic">Identificador/Token</p>
            <p class="text-topic">{{ baixa.hash }}</p>
          </div>

          <div class="topic">
            <p class="title-topic">Data do recebimento</p>
            <p class="text-topic">
              {{ (baixa.movements[0].movementDate | date:'dd/MM/YYYY') || '-' }}
            </p>
          </div>

          <div class="topic">
            <p class="title-topic">CPF/CNPJ</p>
            <p class="text-topic">
              {{ enterprise ? (enterprise.document | document ) : '-' }}
            </p>
          </div>

          <div class="topic">
            <p class="title-topic">Grupo Econômico</p>
            <div>
              <p class="text-topic">{{ enterprise ? enterprise.economicGroup : '-' }}</p>
            </div>
          </div>

          <div class="topic">
            <p class="title-topic">Produtor</p>
            <div>
              <p class="text-topic">
                {{ enterprise ? enterprise.corporateName : '-' }}
              </p>
            </div>
          </div>

          <div class="topic">
            <p class="title-topic">CAR:</p>
            <p class="text-topic">
              {{ getFarmCode(enterprise ? enterprise.places : [], 'car') }}
            </p>
          </div>
          <div class="topic">
            <p class="title-topic">Número da Nota Fiscal:</p>
            <p class="text-topic">
              {{  baixa.movements[0] | feature:'INVOICE_NUMBER' }}
            </p>
          </div>
        </div>

        <div class="column-2">
          <div class="topic">
            <p class="title-topic">Cultura</p>
            <p class="text-topic">{{ getFeatureByName(baixa.item, 'CULTURA') || (baixa.item ? getCultureName(baixa.item.product.code) : '-') }}</p>
          </div>

          <div class="topic">
            <p class="title-topic">Nome da Fazenda</p>
            <p class="text-topic">{{ getFarmCode(enterprise ? enterprise.places : [], 'nickname') }}</p>
          </div>

          <div class="topic">
            <p class="title-topic">Talhão</p>
            <p class="text-topic">{{ (getFeatureByName(baixa.item, 'NOME_TALHAO') || getFeatureByName(baixa.item, 'FIELD_NAME')) }}</p>
          </div>

          <div class="topic">
            <p class="title-topic">Pegada talhão</p>
            <p class="text-topic">
              {{ getFeatureByName(baixa.item, 'QTD_TOTAL_EMISSÃO_CARBONO') | number: '1.0-2': 'pt-BR' }} kg CO2 eq/t
            </p>
          </div>

          <div class="topic">
            <p class="title-topic">Volume recebido</p>
            <p class="text-topic">
              {{ baixa.item.amount | number: '1.0-2': 'pt-BR' }} ton
            </p>
          </div>

          <div class="topic" >
              <p class="title-topic">CNPJ da venda do produtor:</p>
              <p class="text-topic">
                {{ (getFeatureByName(baixa.movements[0], 'PRODUCER_DOCUMENT') || '-') | document }}
              </p>
            </div>

            <div class="topic">
              <p class="title-topic">Número do contrato de venda de {{ cultura }}:</p>
              <p class="text-topic">
                {{ baixa.movements[0] | feature: 'CONTRACT_NUMBER' }}
              </p>
            </div>
        </div>
      </div>

      <div class="box-accordion">
        <div class="image-box">
          <img src="../../../assets/images/validacao.png" />
        </div>

        <div class="column-1-box">
          <p class="text-1">
            Agricultor está em compliance nos seguintes critérios
            socioambiental!
          </p>
          <p class="text-2">
            Trabalho escravo, LDI para Doc Oline, Embargos SEMA, Ebargos
            CMBio, Embargos IBAMA Desmatamento, Comunidades Quilombolas,
            Unidade de Conservação, Desmatamento Prodes, Terras indígenas.
          </p>
        </div>

        <div class="column-2-box">
          <p>
            Para saber mais acesse o QR CODE ao lado ou <a [href]="urlLandingPage">clique aqui.</a>
          </p>
          <div class="image-qrcode">
            <img style="width: 100px; height: 100px;" [src]="urlQRCODE" alt="QR Code">
          </div>
        </div>
      </div>

    </div>
  </li>
</ul>
